export enum Role {
  Admin = 1,
  AccountManager = 2,
  Recruiter = 3,
  Interviewer = 4,
  HiringManager = 5,
  BussinessOwner = 6,
  BussinessHead = 7,
  LeadRecruiter = 8
}
