<div>
  <div class="vertical-navbar navbar-active shadow-sm d-flex flex-column">
    <div class="left-logo">
      <img src="../../../assets/IMG/g5-favicon.png" width="30" />
    </div>
    <a class="vertical-nav" [ngClass]="{'selected1':(selectedNavigationTab=='Home'),'cursor-pointer':!(selectedNavigationTab=='Dashboard')}" (click)="clearSearch();clearJobDetails(true);clearCandidateTab(true);clearDashboardTab(true);navigateTo('Home',$event)">
      <img slot="start" height="21" src="../../../assets/IMG/dashboard (2).png" class="Inactive" />
      <img slot="start" height="21" src="../../../assets/IMG/dashboard-active.png" class="Active" />
      <span class="mt-1">Dashboard</span>
    </a>
    <a class="vertical-nav" (click)="isAtsEnabled?navigateTo('Jobs/OrgJobs',$event):navigateTo('Jobs',$event)" [ngClass]="{'selected1':isJobsTabActive || (selectedNavigationTab == 'Jobs/AddJob') ,'cursor-pointer':!(isJobsTabActive)}">
      <img slot="start" height="25" src="../../../assets/IMG/jobNotification-black.png" class="Inactive" />
      <img slot="start" height="25" src="../../../assets/IMG/jobNotification.png" class="Active" />
      <span class="mt-1">Jobs</span>
    </a>
    <a class="vertical-nav" (click)="isAtsEnabled?navigateTo('Candidates/CandidatePool',$event):navigateTo('Candidates/Grid',$event)" [ngClass]="{'selected1':isCandidateTabActive || (selectedNavigationTab == 'Candidates/AddCandidate') ||(selectedNavigationTab == 'Candidates/PotentialCandidates'),'cursor-pointer':!(isCandidateTabActive)}">
      <img slot="start" height="21" src="../../../assets/IMG/candidates.png" class="Inactive" />
      <img slot="start" height="21" src="../../../assets/IMG/candidates-active.png" class="Active" />
      <span class="mt-1">Candidates</span>
    </a>
    <a class="vertical-nav" (click)="navigateTo('Network',$event)" [ngClass]="{'selected1':(selectedNavigationTab=='Network'),'cursor-pointer':!(selectedNavigationTab=='Network')}" (click)="clearSearch();clearJobDetails(true);clearCandidateTab(true);clearDashboardTab(true);navigateTo('Network',$event)">
      <img slot="start" height="21" src="../../../assets/IMG/network.png" class="Inactive" />
      <img slot="start" height="21" src="../../../assets/IMG/network-active.png" class="Active" />
      <span class="mt-1">Network</span>
    </a>
    <a class="vertical-nav" (click)="navigateTo('Posts',$event)" [ngClass]="{'selected1':(selectedNavigationTab=='Posts'),'cursor-pointer':!(selectedNavigationTab=='Posts')}" (click)="clearSearch();clearJobDetails(true);clearCandidateTab(true);clearDashboardTab(true);navigateTo('Posts',$event)">
      <img slot="start" height="21" src="../../../assets/IMG/post.png" class="Inactive" />
      <img slot="start" height="21" src="../../../assets/IMG/post-active.png" class="Active" />
      <span class="mt-1">Posts</span>
    </a>

  </div>
</div>
<div class="psjh-nav-top-bar bg-white">
  <div class="header-search col-6 mx-auto">
    <div class="">
      <form class="navbar-form navbar-right animation-search margin-bottom-0" [ngClass]="showSearchBar? 'searchshow':'searchhide'">
        <app-searchconnection></app-searchconnection>
      </form>
    </div>
  </div>
  <div class="ml-auto">
    <ul class="displayFlex align-items-center menu-right">

      <li>
        <div class="dropdown">
          <button class="dropdown-toggle message-icon" data-toggle="dropdown" data-bs-toggle="dropdown" aria-expanded="false" (click)="hideMessageCount()">
            <i class="material-icons" title="Messages" [ngClass]="chatNotifications.length>0 && showChatNotificationBadge ? 'faa-ring animated' : ''">
              message
            </i>
            <span class="notification-value" *ngIf="chatNotifications.length>0 && showChatNotificationBadge">{{chatNotifications.length}}</span>
          </button>
          <div class="dropdown-menu position-dropdown padding-0 animate slideIn msg-dd">
            <div class="notificationHeading MSG-bg">
              <span>Messages</span>
            </div>
            <div class="float-width dropdown-width msg-width">
              <table class="borderless table float-width">
                <tr *ngFor="let chatNotification of chatNotifications;let messageIndex = index" [ngStyle]="{'background-color': chatNotification.isRead==true?'white':'rgb(243, 243, 243)','margin': chatNotification.isRead==false?'25px':''}">
                  <td width="50" class="padding-top-10" (click)="openChatWithUser(chatNotification)">
                    <img class="margin-left-10 margin-right-5 img-circle" style="min-width:30px; height:30px" src="{{blobUrl}}{{chatNotification.fromUserBlobImage}}" *ngIf="chatNotification.fromUserBlobImage !=='' && chatNotification.fromUserBlobImage!==null">
                    <img class="margin-left-10 margin-right-5 img-circle" style="min-width:30px; height:30px" src="../../../assets/IMG/avatar.png" *ngIf="chatNotification.fromUserBlobImage==''|| chatNotification.fromUserBlobImage==null">
                  </td>
                  <td class="vertical-middle cursor-pointer padding-top-10 padding-right-10" (click)="openChatWithUser(chatNotification)">
                    <span class="float-width">
                      <span class="label-title pull-left padding-left-0" title="{{chatNotification.fromName}}">
                        {{chatNotification.fromName|slice:0:18}}{{chatNotification.fromName?.length>18?'...':''}}
                      </span>
                      <small class="gray-font pull-right">
                        {{chatNotification.notificationDate | date : 'M/d/yy h:mm a '}}
                      </small>

                      </span>
                      <small class="float-width gray-font margin-top-2" style="width: 315px; word-break: break-word;">You have {{chatNotification.unreadMessageCount}} unread messages</small>
                    </td>
                  </tr>
                </table>
                <div class="noDataActivity" *ngIf="chatNotifications?.length ===0">
                  <div class="noData">
                    <img src="../../../assets/IMG/nodiscussions.png" width="100" class="margin-bottom-15 margin-top-n8" /><br />
                    <b class="nodatatext">No messages, yet.</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="dropdown">
          <a class="dropdown-toggle right-icons-header searchBox-right" data-bs-toggle="dropdown" data-toggle="dropdown" role="button" title="Notifications" aria-haspopup="true" (click)="popUnReadCount()" aria-expanded="false">
            <div [ngClass]="notificationUnReadCount>0 && showNotificationBadge ? '' : 'hide-span'">
              <i class="material-icons" [ngClass]="notificationUnReadCount>0 && showNotificationBadge ? 'faa-ring animated' : ''">notifications</i>
              <span class="notification-value">
                {{notificationUnReadCount>99?'99+':notificationUnReadCount}}
              </span>
            </div>
          </a>
          <div class="dropdown-menu position-dropdown padding-0 animate slideIn">
            <div class="notificationHeading MSG-bg" (click)="$event.stopPropagation()">
              <span>Notifications</span>
              <span class="clearAllMSG pull-right margin-right-5" title="Clear all notifications" (click)="isClearAllEnable?clearAllNotifications():false">
                <img class="cursor-pointer" height="20" width="20" src="../../../assets/IMG/clear-black.png" [ngClass]="{'disabled-style-icon ':!isClearAllEnable}" />
              </span>
              <span title="Mark all as read" class="pull-right margin-right-15"><img class="cursor-pointer" height="20" width="20" (click)="isReadAllEnable?markAllasRead(false):false" [ngClass]="{'disabled-style-icon ':!isReadAllEnable}" src="../../../assets/IMG/book.png" /></span>
            </div>
            <div class="float-width  dropdown-width width-730" [ngClass]="{'hiringManager-height':isHiringManager==true}">
              <div class="position-absolute list-on-hover cursor-pointer" [ngClass]="{'hiringManager-height':isHiringManager==true}">
                <div class="icon-list displayFlex">
                  <ul [ngClass]="{'filter-lastchild':isHiringManager==true}">
                    <li *ngFor="let filter of filterOptions" class="notification-list displayFlex" [ngClass]="{'filter-checked-img':filter.name!==selectedFilter, 'filter-checked':filter.name==selectedFilter}" (click)="filterNotifications(filter.name);$event.stopPropagation()">
                      <span class="width100">
                        <img [src]="filter.image" class="filter-img">
                        <span class="filter-text">
                          <span class="padding-left-15">{{filter.name}}</span>
                          <span *ngIf="filter.notificationCount>0" class="notification-filter-count">{{filter.notificationCount}}</span>
                        </span>
                      </span>

                    </li>
                  </ul>
                </div>
              </div>
              <div class="dropdown-width notification-padding" [ngClass]="{'hiringManager-height':isHiringManager==true}">
                <table id="scrollTop">
                  <tr *ngFor="let notification of notifications;let noteIndex = index" [ngStyle]="{'background-color': notification.isRead==true?'white':'rgb(243, 243, 243)','margin':notification.isRead==false?'2px':''}">
                    <td width="50" class="padding-top-10 cursor-pointer" (click)="navigateFromNotification(notification, noteIndex,$event)">
                      <img style="min-width:30px; height:30px" class="margin-left-10 margin-right-5 img-circle" src="{{blobUrl}}{{notification.performedByImageBlobName}}" *ngIf="notification.performedByImageBlobName!=='' && notification.performedByImageBlobName!==null">
                      <img style="min-width:30px; height:30px" class="margin-left-10 margin-right-5 img-circle" src="../../../assets/IMG/avatar.png" *ngIf="notification.performedByImageBlobName==''|| notification.performedByImageBlobName==null">
                    </td>
                    <td class="vertical-middle cursor-pointer padding-bottom-10 padding-top-10 padding-right-10" (click)="closeDropDown()">
                      <span class="float-width" (click)="navigateFromNotification(notification, noteIndex,$event)">
                        <span class="label-title padding-0  pull-left padding-left-0" title="{{notification.performedByName}}">
                          {{notification.performedByName|slice:0:9}}{{notification.performedByName?.length>9?'...':''}}
                        </span>
                        <span class="gray-font pull-right padding-right-15 date-width">
                          <small>{{notification.performedDate | date : 'M/d/yy h:mm a '}}</small>
                        </span>
                        <span class="pull-right">
                          <img src="../../../assets/IMG/jobNotification.png" width="16" *ngIf="notification.notificationTypeId == notificationType.Job|| notification.notificationTypeId == notificationType.NetworkJob || notification.notificationTypeId == notificationType.JobDiscussion || notification.notificationTypeId == notificationType.NetworkJobDiscussion" class="margin-right-10" />
                          <img src="../../../assets/IMG/userNotification.png" width="16" *ngIf="notification.notificationTypeId == notificationType.Candidate|| notification.notificationTypeId == notificationType.NetworkCandidate || notification.notificationTypeId == notificationType.CandidateDiscussion || notification.notificationTypeId == notificationType.NetworkCandidateDiscussion" class="margin-right-10" />
                        </span>
                        <small>
                          <i *ngIf="!notification.isRead" class="fa fa-eye margin-right-10 trashRed margin-left-10 markAsRead" title="Mark as read" width="16" (click)="markNotificationAsRead(notification, noteIndex);$event.stopPropagation()" aria-hidden="true"></i>
                          <img src="../../../assets/IMG/clear-black.png" class="margin-right-10 margin-left-10 markAsRead" title="Clear notification" width="16" (click)="clearNotification(notification, noteIndex);$event.stopPropagation()" aria-hidden="true">
                        </small>
                      </span>
                      <small (click)="navigateFromNotification(notification, noteIndex,$event)" style="width: 315px; word-break: break-word;" class="float-width gray-font margin-top-2">{{notification.notificationMessage}}</small>
                    </td>
                  </tr>
                </table>
                <div class="noDatainDashBord " *ngIf="(notifications?.length==0)">
                  <div class="noData">
                    <img src="../../../assets/IMG/no-notification.png" width="80" class="margin-top-20" /><br />
                    <b class="nodatatext">No notifications, yet.</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="dropdown">
            <div class="dropdown-toggle navbar-item item-bg padding-0 margin-0 profile-icon" data-bs-toggle="dropdown" title="Profile & Settings" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
              <img height="30" style="min-width:30px !important; height:30px !important;" class="margin-left-10 img-circle " width="30" src="{{userImage}}" *ngIf="userProfileImage !=='' && userProfileImage!==null">
              <img height="30" style="min-width:30px !important; height:30px !important;" class="margin-left-10 img-circle " width="30" src="../../../assets/IMG/avatar.png" *ngIf="userProfileImage==''||userProfileImage==null">
              <!--<img src="../../../assets/IMG/alert.png" *ngIf="(hasAdminPrivilege && !isPersonalAccount && (loggedInCompanyUser?.company.companyProfileCompletionPercentage!=100 || loggedInCompanyUser?.user.userProfileCompletionPercentage!=100)) || (loggedInCompanyUser?.user.userProfileCompletionPercentage!=100)" title="Profile incomplete" class="exclamation" />-->
            </div>
            <div class="dropdown-menu profile-dropdown myprofile-dropdown animate slideIn msg-dd" style="top: 41px !important; right:0px !important;">
              <ul class="description-icon">
                <li style="background-color:#f1f1f1 !important;">
                  <a style="background-color:#f1f1f1 !important; display:flex; padding:2px;">
                    <span class="item-bg padding-0 margin-0 pull-left">
                      <img class="profile-image" width="30" src="{{userImage}}" *ngIf="userProfileImage !=='' && userProfileImage!==null">
                      <img class="profile-image" width="30" src="../../../assets/IMG/avatar.png" *ngIf="userProfileImage==''||userProfileImage==null">
                    </span>
                    <span style="margin:auto 0;text-overflow: ellipsis;overflow: hidden;width: 175px;">
                      <b>{{loggedInUserFullName}}</b>
                      <br />
                      <small class="wordbrk">{{loggedInCompany}}</small>
                    </span>
                  </a>
                </li>
                <li>
                  <a class="navbar-item displayFlex margin-bottom-5 margin-right-10" (click)="getUserProfile()">
                    <img src="../../../assets/IMG/profile.png" />
                    My Profile
                  </a>
                </li>
                <li *ngIf="isAtsEnabled">
                  <a class="navbar-item displayFlex margin-right-10" (click)="navigateTo('Management/ContactManagement',$event)">
                    <img src="../../../assets/IMG/contact-management.png" />Contact Management
                  </a>
                </li>
                <li class="padding-0 margin-0"> <hr class="navbar-divider"></li>
                <li *ngIf="!isHiringManager">
                  <a class="navbar-item  displayFlex margin-bottom-5 margin-right-10" (click)="getCompanyProfile()" *ngIf="hasAdminPrivilege && !isPersonalAccount">
                    <img src="../../../assets/IMG/company-profile.png" />
                    Company Profile
                  </a>
                </li>
                <li *ngIf="!isHiringManager">
                  <a class="navbar-item" *ngIf="hasAdminPrivilege && !isPersonalAccount" (click)="clearSearch();clearJobDetails(false);clearCandidateTab(true);clearDashboardTab(true);navigateTo('Management/UserManagement',$event);">
                    <img src="../../../assets/IMG/user-management.png" />
                    User Management
                  </a>
                </li>
                <li *ngIf="isTeamManagementVisible && isAtsEnabled">
                  <a class="navbar-item" (click)="navigateTo('Management/Team',$event);">
                    <img src="../../../assets/IMG/Team-Hierarchy.png" />
                    Team Management
                  </a>
                </li>
                <li class="padding-0 margin-0"> <hr class="navbar-divider"></li>
                <li>
                  <a class="navbar-item  displayFlex margin-bottom-5 margin-right-10" (click)="viewCompanySettings()">
                    <img src="../../../assets/IMG/settings.png" />
                    Settings
                  </a>
                </li>
                <li *ngIf="companyId==1 && (hasAdminPrivilege || isAccountManager)">
                  <a class="navbar-item displayFlex margin-right-10" (click)="clearSearch();clearJobDetails(false);clearCandidateTab(true);clearDashboardTab(true);navigateTo('Management/invitationsManagement',$event)">
                    <img src="../../../../assets/IMG/invitation.png" />
                    Invitations
                  </a>
                </li>
                <li>
                <li>
                  <a class="navbar-item displayFlex margin-bottom-5 margin-right-10" (click)="getFeedback()">
                    <img src="../../../assets/IMG/feedback.png" />
                    Feedback
                  </a>
                </li>
                <li>
                  <a class="navbar-item" (click)="clearSearch();clearJobDetails(false);clearCandidateTab(true);clearDashboardTab(true);navigateTo('Help',$event);">
                    <img src="../../../assets/IMG/help.png" />
                    Help
                  </a>
                </li>
                <li>
                  <a class="navbar-item  displayFlex margin-right-10 margin-bottom-10" (click)="logOut()">
                    <img src="../../../assets/IMG/logout.png" />
                    Logout
                  </a>

              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="overRite" *ngIf="viewProfile"></div>
  <div class="panel panel-primary drawer-Right" [ngClass]="viewProfile? 'rightValuenone' : ''">
    <div class="panel-heading headerColorDark padding-top-0 padding-right-0">
      <span class=" font-16 profleHeading" style="line-height:41px !important;">
        My Profile
      </span>
      <span class="pull-right display-grid hover-effect-icons width50px" style="height: 40px" (click)="closeUserProfile()">
        <a class="fa fa-times animationEffect font-20" style="color: #5c5c5c;line-height: 25px;margin-left: 8px;"></a>
        <span style="line-height: 13px !important;text-align: center;margin-left: -4px; font-size:12px !important;">Close</span>
      </span>
      <span class="pull-right display-grid hover-effect-icons width50px" style="height: 40px" (click)="!isPersonalAccount?validateUser(user):validatePersonalProfile(user)" *ngIf="isProfileEditable">
        <i class="fa fa-floppy-o pull-right font-22  margin-right-40 cursor-pointer  edit-profile" aria-hidden="true" style="line-height: 23px !important;margin-left: 5px;margin-top: 1px !important;"></i>  <!--PR 3354 Added condition for the freelancer-->
        <span style="line-height:1px !important;margin-left: -2px; font-size:12px !important;">Save</span>
      </span>
      <span class="pull-right display-grid hover-effect-icons width50px" style="height: 40px" (click)="editProfile()" *ngIf="!isProfileEditable">
        <i class="fa fa-pencil-square-o font-22 cursor-pointer edit-profile" aria-hidden="true" style="line-height: 24px !important;margin-left: 4px;margin-top: 1px;"></i>
        <span style="line-height:1px !important; font-size:12px !important;">Edit</span>
      </span>
    </div>
    <div class="panel-body padding-0 sidebar-fullScroll-profile">
      <div class="wallImage headerProfile">
        <label class="profileName-Slide">
          <span class="margin-right-5" title="{{user.firstName}}">{{user.firstName |slice:0:10}}{{user.firstName?.length>10?'...':''}}</span>
          <span title="{{user.lastName}}">{{user.lastName | slice:0:10}}{{user.lastName?.length>10?'...':''}}</span>
        </label>
        <label *ngIf="!isPersonalAccount" class="ProfileDes">
          <span>
            <small>{{user.userRole.role}}</small>
          </span>
        </label>
        <label>
          <span *ngIf="isPersonalAccount">
            <small class="Profile-personal">Personal Account</small>
          </span>
        </label>
        <progress-bar [progress]="user.userProfileCompletionPercentage" [color-degraded]="{'0': '#ff3300',  '51': '#f37a10', '99': '#46c34c'}" *ngIf="loggedInCompanyUser?.user.userProfileCompletionPercentage!=100" style="border-radius: 0 !important; width:100% !important;" title="Profile Completeness"></progress-bar>

      </div>

      <span class="candidateImg-slide user-profile margin-left-10" style="position: relative;top: -33px;">
        <app-fileupload [options]="profilePhotoOptions" (refreshAttachment)="refreshAttachment($event)" [isUpdatePage]="true" [isImageCropping]="true"></app-fileupload>
        <img src="{{srcOfProfileImage}}" class="img-circle" style="min-width:85px; height:85px;" *ngIf="profilePhotoOptions.blobName!=null && profilePhotoOptions.blobName!=''" />
        <img src="../../../assets/IMG/avatar.png" style="min-width:85px; height:85px;" class="img-circle" *ngIf="profilePhotoOptions.blobName==null || profilePhotoOptions.blobName==''" />
      </span>

      <div class="myProfileTable float-width">
        <div class="profile-maxHeight width100">
          <div class="displayFlex margin-bottom-20 margin-right-10">
            <div class="form-group padding-right-0">
              <span class="text-color-grey font-bold">First Name</span><span class="red-color-fa" *ngIf="isProfileEditable">*</span>
            </div>
            <div id="scroll">
              <label *ngIf="!isProfileEditable" class="candidate-value margin-bottom-0" style="white-space: normal;text-overflow: ellipsis;overflow: hidden;width: 300px;">{{user.firstName}}</label>
              <input *ngIf="isProfileEditable" type="text" [(ngModel)]="user.firstName" trim="blur" autocomplete="off" #firstName="ngModel" placeholder="First Name" name="firstName" class="form-control" maxlength="30" required [ngClass]="(firstName.invalid && (firstName.dirty || formSubmitted )) ? 'validateInput':''">
            </div>
          </div>
          <div class="displayFlex margin-bottom-20 margin-right-10">
            <div class="form-group">
              <span class="text-color-grey font-bold">Last Name</span><span class="red-color-fa" *ngIf="isProfileEditable">*</span>
            </div>
            <div>
              <label *ngIf="!isProfileEditable" class="candidate-value margin-bottom-0" style="white-space: normal;text-overflow: ellipsis;overflow: hidden;width: 300px;">{{user.lastName}}</label>
              <input *ngIf="isProfileEditable" type="text" [(ngModel)]="user.lastName" trim="blur" autocomplete="off" #lastName="ngModel" placeholder="Last Name" name="lastName" class="form-control" maxlength="30" required [ngClass]="(lastName.invalid && (lastName.dirty || formSubmitted )) ? 'validateInput':''">
            </div>
          </div>
          <div class="displayFlex margin-bottom-20 margin-right-10">
            <div class="form-group ">
              <span class="text-color-grey font-bold">Address </span>
            </div>
            <div class="read-width">
              <label *ngIf="!isProfileEditable" class="candidate-value margin-bottom-0 worbrk-keepall" style="white-space: normal;text-overflow: ellipsis;overflow: hidden;width: 300px;">{{user.address}}</label>
              <textarea [(ngModel)]="user.address" class="form-control resize-none worbrk-keepall" autocomplete="off" trim="blur" rows="10" *ngIf="isProfileEditable" name="userAddress" required #userAddress="ngModel" placeholder="Address" maxlength="500" style="height:50px; max-width:416px;"></textarea>
            </div>
          </div>
          <div class="displayFlex margin-bottom-20 margin-right-10">
            <div class="form-group">
              <span class="text-color-grey font-bold"> Zip Code</span><span class="red-color-fa" *ngIf="isProfileEditable">*</span>
            </div>
            <div>
              <label *ngIf="!isProfileEditable" class="candidate-value margin-bottom-0">{{user.zipCode}}</label>
              <input type="text" [(ngModel)]="user.zipCode" name="userZipCode" autocomplete="off" #userZipCode="ngModel" *ngIf="isProfileEditable" placeholder="Zip Code" [ngClass]="!isValidZipcode || (formSubmitted && userZipCode.invalid) ? 'validateInput':''" class="form-control" required maxlength="10" trim="blur" (blur)="validateZipCode(user.zipCode)" /><!--PR 3389 : To allow alpha numeric.-->
            </div>
          </div>
          <div class="displayFlex margin-bottom-20 margin-right-10">
            <div class="form-group">
              <span class="text-color-grey font-bold"> Location</span><span class="red-color-fa" *ngIf="isProfileEditable">*</span>
            </div>
            <div class="width-220">
              <p-autoComplete *ngIf="isProfileEditable" name="userLocation" [(ngModel)]="user.userLocation" [suggestions]="locations" (completeMethod)="searchLocations($event)" [minLength]="3" placeholder="Search locations" #userLocation="ngModel" field="name"
                              [ngClass]="!isLocationValid && formSubmitted?'validateInput-location':''"
                              [forceSelection]="true" emptyMessage="No results found" [maxlength]="60" required>
              </p-autoComplete>
              <label *ngIf="!isProfileEditable" class="candidate-value margin-bottom-0">{{user.userLocation?.name}}</label>
            </div>
          </div>
          <div class="displayFlex margin-bottom-20 margin-right-10">
            <div class="form-group">
              <span class="text-color-grey font-bold">Login Email Id</span> <span class="red-color-fa" *ngIf="isProfileEditable">*</span>
            </div>
            <div>
              <label *ngIf="!isProfileEditable" title="{{user.loginEmailId}}" class="candidate-value max-text-160 margin-bottom-0">{{user.loginEmailId}}</label>
              <input *ngIf="isProfileEditable" type="email" [(ngModel)]="user.loginEmailId" trim="blur" autocomplete="off" [attr.readonly]="true" name="loginEmail" placeholder="Login Email" class="form-control" required>
            </div>
          </div>

          <div class="displayFlex margin-bottom-20 margin-right-10">
            <div class="form-group">
              <span class="text-color-grey font-bold">Contact Number</span><span class="red-color-fa" *ngIf="isProfileEditable">*</span>
            </div>
            <div>
              <label *ngIf="!isProfileEditable" class="candidate-value margin-bottom-0">{{user.personalContactNumber}}</label>
              <input type="text" *ngIf="isProfileEditable" [(ngModel)]="user.personalContactNumber" appPhoneMask autocomplete="off" trim="blur" name="contactNumber" placeholder="Contact Number" class="form-control" #contactNumber="ngModel" #userContact [ngClass]=" !this.validContactNumber || (contactNumber.invalid && (contactNumber.dirty || formSubmitted )) ? 'validateInput':''" (blur)="validateContactNumber(user.personalContactNumber,0)" required maxlength="14">
            </div>
          </div>
          <div class="displayFlex margin-bottom-20 margin-right-10">
            <div class="form-group">
              <span class="text-color-grey font-bold">About Me</span>
            </div>
            <div class="read-width">
              <label *ngIf="!isProfileEditable" class="candidate-value margin-bottom-0 worbrk-keepall">{{user.aboutMe}}</label>
              <textarea [(ngModel)]="user.aboutMe" *ngIf="isProfileEditable" class="form-control resize-none height-45 worbrk-keepall" autocomplete="off" trim="blur" rows="10" name="about" placeholder="About yourself" maxlength="1000"></textarea>
            </div>
          </div>
          <div class="displayFlex margin-bottom-20 margin-right-10">
            <div class="form-group">
              <span class="text-color-grey font-bold">Work Title</span><span class="red-color-fa" *ngIf="isProfileEditable">*</span>
            </div>
            <div class="read-width">
              <label *ngIf="!isProfileEditable" class="candidate-value margin-bottom-0">{{user.workTitle}}</label>
              <input type="text" [(ngModel)]="user.workTitle" *ngIf="isProfileEditable" autocomplete="off" trim="blur" maxlength="50" name="workTitle" #workTitle="ngModel" placeholder="Work Title" required class="form-control" [ngClass]="(workTitle.invalid && (workTitle.dirty || formSubmitted )) ? 'validateInput':''">
            </div>
          </div>
          <div *ngIf="!isPersonalAccount" class="displayFlex margin-bottom-20 margin-right-10">
            <div class="form-group">
              <span class="text-color-grey font-bold">Work Company</span><span class="red-color-fa" *ngIf="isProfileEditable">*</span>
            </div>
            <div class="read-width">
              <label *ngIf="!isProfileEditable" class="candidate-value margin-bottom-0">{{user.workCompany}}</label>
              <input type="text" [(ngModel)]="user.workCompany" *ngIf="isProfileEditable" autocomplete="off" trim="blur" maxlength="100" name="workCompany" #workCompany="ngModel" placeholder="Work Company" required class="form-control" [ngClass]="(workCompany.invalid && (workCompany.dirty || formSubmitted )) ? 'validateInput':''">
            </div>
          </div>
          <div class="displayFlex margin-bottom-20 margin-right-10" *ngIf="isPersonalAccount">
            <div class="form-group">

              <span class="text-color-grey font-bold">Time Zone</span><span class="red-color-fa" *ngIf="isProfileEditable">*</span>
            </div>
            <div>
              <label *ngIf="!isProfileEditable" class="candidate-value margin-bottom-0">{{user.timeZone}}</label>
              <select class="form-control" name="companysTimeZone" #userTimeZone="ngModel" *ngIf="isProfileEditable" [(ngModel)]="user.timeZone" required [ngClass]="{'validateInput': (formSubmitted ) && userTimeZone.invalid }">
                <option value=null selected disabled>Select Time Zone</option>
                <option *ngFor="let zone of timeZonesList" [value]="zone.timeZone">{{zone.timeZoneWithOffSet}}</option>
              </select>
            </div>
          </div>
          <div *ngIf="!isPersonalAccount" class="displayFlex margin-bottom-20 margin-right-10">
            <div class="form-group">
              <span class="text-color-grey font-bold">Application Time Zone</span>
            </div>
            <div>
              <label *ngIf="!isProfileEditable" class="candidate-value margin-bottom-0">{{user.applicationTimeZone}}</label>
              <input type="text" [(ngModel)]="user.applicationTimeZone" *ngIf="isProfileEditable" readonly autocomplete="off" trim="blur" name="applicationTimezone" placeholder="Application Time Zone" class="form-control">
            </div>
          </div>

          <div *ngIf="!isPersonalAccount" class="userCompanyDetailsHeading">

            <div class="new-style-heading">
              Company User Details
            </div>
          </div>

          <div *ngIf="!isPersonalAccount" class="displayFlex margin-bottom-10 margin-right-10">
            <div class="form-group">
              <span class="text-color-grey font-bold padding-top-5">User Email Id</span> <span class="red-color-fa" *ngIf="isProfileEditable">*</span>
            </div>
            <div class="read-width margin-bottom-15">
              <label *ngIf="!isProfileEditable" class="candidate-value max-text-200 margin-bottom-0">{{user.companyUserEmailId}}</label>
              <input *ngIf="isProfileEditable" type="text" [(ngModel)]="user.companyUserEmailId" autocomplete="off" trim="blur" placeholder="Company User Email" name="UserEmailId" class="form-control" required maxlength="100" #UserEmailId="ngModel" (blur)="validateEmail(user.companyUserEmailId)" [ngClass]="!this.isEmailValid ||(( formSubmitted  || UserEmailId.dirty ) && UserEmailId.invalid)? 'validateInput':''">
            </div>
          </div>
          <div *ngIf="!isPersonalAccount" class="displayFlex margin-bottom-10 margin-right-10 padding-bottom-30">
            <div class="form-group">
              <span class="text-color-grey font-bold">Contact Number</span><span class="red-color-fa" *ngIf="isProfileEditable">*</span>
            </div>
            <div>
              <label *ngIf="!isProfileEditable" class="candidate-value margin-bottom-0">{{user.companyUserContactNumber}}</label>
              <input type="text" *ngIf="isProfileEditable" [(ngModel)]="user.companyUserContactNumber" appPhoneMask autocomplete="off" trim="blur" name="companyUserContact" placeholder="Company User Contact Number" class="form-control" #companyUserContact="ngModel" #companyContact [ngClass]="!this.isValidCompanyUserContact || (companyUserContact.invalid && (companyUserContact.dirty || formSubmitted )) ? 'validateInput':''" (blur)="validateContactNumber(user.companyUserContactNumber,2)" required maxlength="14">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="overRite" *ngIf="viewCompanyProfile"></div>
  <div class="panel panel-primary drawer-Right" [ngClass]="viewCompanyProfile ? 'rightValuenone' : ''">
    <div class="panel-heading headerColorDark padding-right-0 padding-top-0">
      <span class=" font-16" style="line-height:41px !important;">
        Company Profile
      </span>
      <span class="pull-right display-grid hover-effect-icons width50px" style="height: 40px" (click)="closeCompanyProfileUpdate()">
        <a class="fa fa-times animationEffect font-20" style="color: #5c5c5c;line-height: 25px;margin-left: 8px;"></a>
        <span style="line-height: 13px !important;text-align: center;margin-left: -4px; font-size:12px !important;">Close</span>
      </span>
      <span class="pull-right display-grid hover-effect-icons width50px" style="height: 40px" (click)="validateCompanyProfile(company)" *ngIf="isCompanyProfileEditable">
        <i class="fa fa-floppy-o pull-right font-22  margin-right-40 cursor-pointer  edit-profile" aria-hidden="true" style="line-height: 23px !important;margin-left: 5px;margin-top: 1px !important;"></i>  <!--PR 3354 Added condition for the freelancer-->
        <span style="line-height:1px !important;margin-left: -2px; font-size:12px !important;">Save</span>
      </span>
      <span class="pull-right display-grid hover-effect-icons width50px" style="height: 40px" (click)="editCompanyProfile()" *ngIf="!isCompanyProfileEditable">
        <i class="fa fa-pencil-square-o font-22 cursor-pointer edit-profile" aria-hidden="true" style="line-height: 24px !important;margin-left: 4px;margin-top: 1px;"></i>
        <span style="line-height:1px !important; font-size:12px !important;">Edit</span>
      </span>
    </div>
    <div class="panel-body padding-0 sidebar-fullScroll-profile">
      <div class="wallImage headerProfile">
        <label class="profileName-Slide">
          <span class="margin-right-5" title="{{company.company}}">{{company.company | slice:0:20}}{{company.company?.length>20?'...':''}}</span>
        </label>
        <progress-bar [progress]="company.companyProfileCompletionPercentage" [color-degraded]="{'0': '#ff3300',  '51': '#f37a10', '99': '#46c34c'}" *ngIf="loggedInCompanyUser?.company.companyProfileCompletionPercentage!=100" style="border-radius: 0 !important; width:100% !important;" title="Profile Completeness"></progress-bar>
      </div>
      <span class="candidateImg-slide user-profile margin-left-10" style="position: top; position: relative; top: -33px;">
        <app-fileupload [options]="companyPhotoOptions" (refreshAttachment)="refreshAttachment($event)" [isUpdatePage]="true" [isImageCropping]="true"></app-fileupload>
        <img class="img-circle img-size" *ngIf="companyPhotoOptions.blobName == null || companyPhotoOptions.blobName == ''" src="../../../assets/IMG/default-company.png" />

        <img src="{{srcOfCompanyImage}}" style="min-width:85px; height:85px;" class="img-circle" *ngIf="companyPhotoOptions.blobName!==null && companyPhotoOptions.blobName!=''" />
      </span>
      <div class="companyProfileTable float-width">
        <div class="profile-maxHeight width100">
          <div class="displayFlex margin-bottom-10 margin-right-10">
            <div>
              <span class="text-color-grey padding-0">Name</span><span class="red-color-fa" *ngIf="isCompanyProfileEditable">*</span>
            </div><div class="read-width" id="scrolls">
              <label *ngIf="!isCompanyProfileEditable" class="candidate-value">{{company.company}}</label>
              <input type="text" autocomplete="off" [(ngModel)]="company.company" trim="blur" *ngIf="isCompanyProfileEditable" placeholder="Name" class="form-control margin-top-5" maxlength="100" name="companyName" #companyName="ngModel" required [ngClass]="(companyName.invalid && (companyName.dirty || formSubmitted )) ? 'validateInput':''">
            </div>
          </div>
          <div class="displayFlex margin-bottom-10 margin-right-10">
            <div>
              <span class="text-color-grey padding-0">Employer Identification Number (EIN)</span>
            </div><div>
              <label *ngIf="!isCompanyProfileEditable" class="candidate-value">{{company.employerIdentificationNumber}}</label>
              <input type="text" autocomplete="off" [(ngModel)]="company.employerIdentificationNumber" trim="blur" *ngIf="isCompanyProfileEditable" placeholder="Employer Identification Number" class="form-control margin-top-5" maxlength="10" name="einNumber" #einNumber="ngModel" (blur)="validateEIN($event)" appEIN (keypress)="numberOnly($event)" [ngClass]="(!isEINValid) ? 'validateInput':''">
            </div>
          </div>
          <div class="displayFlex margin-bottom-10 margin-right-10">
            <div>
              <span class="text-color-grey padding-0">Address</span> <span class="red-color-fa" *ngIf="isCompanyProfileEditable">*</span>
            </div><div class="read-width">
              <label *ngIf="!isCompanyProfileEditable" class="candidate-value worbrk-keepall">{{company.address}}</label>
              <textarea [(ngModel)]="company.address" class="form-control resize-none" autocomplete="off" trim="blur" rows="10" *ngIf="isCompanyProfileEditable" name="address" required #address="ngModel" placeholder="Address" maxlength="500" [ngClass]="(address.invalid && (address.dirty || formSubmitted )) ? 'validateInput':''" style="height:100px; max-width:416px;"></textarea>
            </div>
          </div>
          <div class="displayFlex margin-bottom-10 margin-right-10">
            <div>
              <span class="text-color-grey padding-0">Zip Code</span><span class="red-color-fa" *ngIf="isCompanyProfileEditable">*</span>
            </div>
            <div>
              <label *ngIf="!isCompanyProfileEditable" class="candidate-value">{{company.zipCode}}</label>
              <input type="text" [(ngModel)]="company.zipCode" name="companysZipCode" autocomplete="off" #companysZipCode="ngModel" *ngIf="isCompanyProfileEditable" placeholder="Zip Code" [ngClass]="!isValidZipcode || (formSubmitted && companysZipCode.invalid) ? 'validateInput':''" class="form-control worbrk-keepall" required maxlength="10" trim="blur" (blur)="validateZipCode(company.zipCode)" />
            </div>
          </div>
          <div class="displayFlex margin-bottom-20 margin-right-10">
            <div>
              <span class="text-color-grey font-bold"> Location</span><span class="red-color-fa" *ngIf="isCompanyProfileEditable">*</span>
            </div>
            <div class="width-202">
              <p-autoComplete *ngIf="isCompanyProfileEditable" name="companyLocation" [(ngModel)]="company.companyLocation" [suggestions]="locations" (completeMethod)="searchLocations($event)" [minLength]="3" placeholder="Search locations" #companyLocation="ngModel" field="name"
                              [ngClass]="!isLocationValid && formSubmitted?'validateInput-location':''"
                              [forceSelection]="true" emptyMessage="No results found" [maxlength]="60" required>
              </p-autoComplete>
              <label *ngIf="!isCompanyProfileEditable" class="candidate-value">{{company.companyLocation?.name}}</label>
            </div>
          </div>
          <div class="displayFlex margin-bottom-10 margin-right-10">
            <div>
              <span class="text-color-grey padding-0">Website</span><span class="red-color-fa" *ngIf="isCompanyProfileEditable">*</span>
            </div><div>
              <label *ngIf="!isCompanyProfileEditable" class="candidate-value read-width">{{company.webSite}}</label>
              <input type="text" name="companyWebsite" *ngIf="isCompanyProfileEditable" autocomplete="off" trim="blur" [(ngModel)]="company.webSite" class="form-control" placeholder="WebSite" #companyWebsite="ngModel" (blur)="onCompanyWebsite(company.webSite)" maxlength="250" [ngClass]=" !this.isCompanyWebsite || (( formSubmitted  || companyWebsite.dirty ) && companyWebsite.invalid) ? 'validateInput':''" required /><!--PR 3428 : to validate field.-->
            </div>
          </div>
          <div class="displayFlex margin-bottom-10 margin-right-10">
            <div>
              <span class="text-color-grey padding-0">Email</span><span class="red-color-fa" *ngIf="isCompanyProfileEditable">*</span>
            </div><div class="read-width">
              <label *ngIf="!isCompanyProfileEditable" class="candidate-value">{{company.companyEmailId}}</label>
              <input type="text" name="companyEmail" *ngIf="isCompanyProfileEditable" [(ngModel)]="company.companyEmailId" autocomplete="off" trim="blur" class="form-control" #companyEmail="ngModel" placeholder="Email" (blur)="validateEmail(company.companyEmailId)" [ngClass]=" !this.isEmailValid || (( formSubmitted  || companyEmail.dirty ) && companyEmail.invalid) ? 'validateInput':''" required maxlength="100" />
            </div>
          </div>
          <div class="displayFlex margin-bottom-10 margin-right-10">
            <div>
              <span class="text-color-grey padding-0">Contact Number</span><span class="red-color-fa" *ngIf="isCompanyProfileEditable">*</span>
            </div><div>
              <label *ngIf="!isCompanyProfileEditable" class="candidate-value">{{company.companyContactNumber}}</label>
              <input type="text" name="CompanyUserContactNumber" *ngIf="isCompanyProfileEditable" #CompanyUserContactNumber="ngModel" #companyContactNumber autocomplete="off" [(ngModel)]="company.companyContactNumber" placeholder="Contact Number" class="form-control" appPhoneMask [ngClass]=" !this.isValidCompanyContact || (CompanyUserContactNumber.invalid && (CompanyUserContactNumber.dirty || formSubmitted )) ? 'validateInput':''" (blur)="validateContactNumber(company.companyContactNumber,1)" maxlength="14" required />
            </div>
          </div>
          <div class="displayFlex margin-bottom-10 margin-right-10 width100">
            <div class="width50">
              <span class="text-color-grey padding-0">About</span><span class="red-color-fa" *ngIf="isCompanyProfileEditable">*</span>
            </div>
            <div class="width50 read-width">
              <label *ngIf="!isCompanyProfileEditable" class="candidate-value worbrk-keepall">{{company.aboutCompany}}</label>
              <textarea *ngIf="isCompanyProfileEditable" name="aboutCompany" autocomplete="off" #aboutCompany="ngModel" [(ngModel)]="company.aboutCompany" placeholder="About Company" class="form-control resize-none worbrk-keepall" required [ngClass]="(aboutCompany.invalid && (aboutCompany.dirty || formSubmitted )) ? 'validateInput':''" trim="blur" maxlength="1000" style="height:100px; max-width:416px;"></textarea>
            </div>
          </div>
          <div class="displayFlex margin-bottom-10 margin-right-10">
            <div style="padding-bottom:30px !important;">
              <span class="text-color-grey padding-0">Time Zone</span> <span class="red-color-fa" *ngIf="isCompanyProfileEditable">*</span>
            </div>
            <div>
              <label *ngIf="!isCompanyProfileEditable" class="candidate-value">{{company.timeZone}}</label><!--PR 3387: updated model-->
              <select class="form-control" name="companysTimeZone" #companysTimeZone="ngModel" *ngIf="isCompanyProfileEditable" [(ngModel)]="company.timeZone" required [ngClass]="{'validateInput': (formSubmitted ) && companysTimeZone.invalid }">
                <option value=null selected disabled>Select Time Zone</option>
                <option *ngFor="let zone of timeZonesList" [value]="zone.timeZone">{{zone.timeZoneWithOffSet}}</option>
              </select>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="overRite" *ngIf="viewFeedback"></div>
  <div class="popupContentBlock" *ngIf="viewFeedback" style="height:327px;">
    <app-application-feedback [viewFeedback]="viewFeedback" (closeFeedback)="closeFeedback($event)"></app-application-feedback>
  </div>

  <div class="overRite" *ngIf="viewMigrate"></div>
  <div class="popupContentBlock" *ngIf="viewMigrate">
    <div class="pop-up-header">
      <span class="m-l-15 font-bold">Migrate to {{isAtsEnabled? "Non-ATS": "ATS"}}</span>
    </div>
    <div class="padding-popup" style="height:222px;">
      <span>
        This feature is not enabled yet and will be available in subsequent releases.
        <!--Click <a>here</a> to know how migration works.-->
      </span>
    </div>
    <footer class="float-width">
      <small style="margin-top: 6px; padding: 0;"><span style="color:red">Note: You will be logged out on clicking Migrate.</span> </small>
      <button class="btn ignorBTN pull-right primary-btn" style="padding:0 15px;" (click)="closeMigrate()">Cancel</button>

      <button class="pull-right secondary-btn margin-right-10" (click)="migrate()" [disabled]="!enableMigration">Migrate</button>
    </footer>
  </div>
