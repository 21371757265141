export class NotificationSetting {
  notificationSettingId: number
  isJobSubscribed: boolean;
  isCandidateSubscribed: boolean;
  isConnectionSubscribed: boolean;
  isDiscussionSubscribed: boolean;
  isNetworkJobSubscribed: boolean;
  isNetworkCandidateSubscribed: boolean;
  isApplicationSubscribed: boolean;
}
