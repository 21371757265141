<div class="col-lg-12 col-sm-12 col-xs-12 col-md-12 margin-top-13">

  <div class="addCNDcontrols float-width margin-bottom-10">
    <div class="text-color-blue page-image">
      <img src="../../../assets/IMG/settings.png" />
      <span class="page-title">Settings</span>
    </div>
  </div>

  <div class="custom-card min-hight-grid  width100">
    <div style="padding-bottom:5px;max-width: 1200px;" *ngIf="hasAdminPrivilege && (!isPersonalAccount)">
      <div>
        <div class="displayFlex">
          <span class="form-group margin-bottom-0 no-wrap" [ngClass]="isCompanySettingEditable? 'margin-top-12':'margin-top-13' ">
            <span class="text-color-grey font-bold">Email Distribution List </span><span class="red-color-fa" *ngIf="isCompanySettingEditable">*</span>
          </span>
          <span>
            <label *ngIf="!isCompanySettingEditable" class="candidate-value margin-bottom-0 font-weight-normal margin-top-13" style=" margin-left:20px; white-space: nowrap;min-width:260px;" title="{{companySettings.emailDistributionList}}">{{companySettings.emailDistributionList}}</label>
            <input *ngIf="isCompanySettingEditable" style="min-width:200px; margin-left:20px;" type="text" [(ngModel)]="companySettings.emailDistributionList" trim="blur" (blur)="validateEmailDistributionList(companySettings.emailDistributionList)" autocomplete="off" #emailDistributionList="ngModel" placeholder="Email Distribution List" name="emailDistributionList" class="form-control margin-top-7" maxlength="100" required [ngClass]="!isEmailDistributionListValid || (emailDistributionList.invalid && isSettingsSubmitted ) ? 'validateInput':''">
          </span>
          <span (click)="cancelEmailDistributionEdit()" *ngIf="isCompanySettingEditable">
            <i class="fa fa-window-close pull-right font-18 cursor-pointer  edit-profile margin-top-7" aria-hidden="true" title="Cancel" style="line-height: 35px !important;margin-left: 30px"></i>
          </span>
          <span (click)="validateCompanySettings()" *ngIf="isCompanySettingEditable">
            <i class="fa fa-floppy-o pull-right font-18 cursor-pointer  edit-profile margin-top-7" aria-hidden="true" title="Save" style="line-height: 35px !important;margin-left: 10px"></i>
          </span>
          <span (click)="editCompanysettings()" *ngIf="!isCompanySettingEditable" title="Edit">
            <i class="fa fa-pencil font-18 cursor-pointer edit-profile margin-top-13" aria-hidden="true" style="line-height: 23px !important;margin-left: 10px"></i>
          </span>
        </div>
      </div>
    </div>
    <hr *ngIf="!isPersonalAccount && hasAdminPrivilege" />
    <div *ngIf="isAtsEnabled" class="margin-top-20 notification-table">
      <div style=" padding-bottom:25px;">
        <span colspan="2" style="border-bottom:2px solid #32aac2; padding-bottom:15px;" class="text-color-grey">
          Email Notification Settings
        </span>
        <span class="" title="As it is read only account email notification settings are disabled." *ngIf="isPersonalAccountReadOnly">
          <i class="fa fa-info fa-help-text info-small"></i>
        </span>
      </div>
      <div class="displayFlex align-items-center" *ngIf="loggedInCompanyUser !== null && !isOnlyInterviewer && loggedInCompanyUser.user.isAtsEnabled">
        <span class="form-group margin-bottom-0 no-wrap text-color-grey">Add Job</span>
        <span class="form-group margin-bottom-0 no-wrap padding-left-10">
          <span class="toggle">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="emailNotificationSettings.isCreateJobSubscribed" name="isCreateJobSubscribed" (ngModelChange)="updateEmailNotificationSettings()" [disabled]="isPersonalAccountReadOnly">
              <span class="slider round" [ngClass]="isPersonalAccountReadOnly? 'cursor-not-allowed':'cursor-pointer'"></span>
            </label>
          </span>
        </span>
      </div>
      <div class="displayFlex" *ngIf="loggedInCompanyUser !== null && !isOnlyInterviewer && loggedInCompanyUser.user.isAtsEnabled">
        <span class="text-color-grey">Accept Network Job</span>
        <div class="form-group margin-bottom-0 no-wrap padding-left-10">
          <span class="toggle">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="emailNotificationSettings.isNetworkJobAcceptSubscribed" name="isNetworkJobAcceptSubscribed" id="checkbox" (ngModelChange)="updateEmailNotificationSettings()" [disabled]="isPersonalAccountReadOnly">
              <span class="slider round" [ngClass]="isPersonalAccountReadOnly? 'cursor-not-allowed':'cursor-pointer'"></span>
            </label>
          </span>
        </div>
      </div>
      <div class="displayFlex" *ngIf="loggedInCompanyUser !== null && !isOnlyInterviewer && loggedInCompanyUser.user.isAtsEnabled">
        <span class="text-color-grey">Clone Job</span>
        <span class="form-group margin-bottom-0 no-wrap padding-left-10">
          <span class="toggle">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="emailNotificationSettings.isCloneJobSubscribed" name="isCloneJobSubscribed" id="checkbox" (ngModelChange)="updateEmailNotificationSettings()" [disabled]="isPersonalAccountReadOnly">
              <span class="slider round" [ngClass]="isPersonalAccountReadOnly? 'cursor-not-allowed':'cursor-pointer'"></span>
            </label>
          </span>
        </span>
      </div>
      <div class="displayFlex">
        <span class="text-color-grey">Daily Submission Report</span>
        <span class="form-group margin-bottom-0 no-wrap padding-left-10">
          <span class="toggle">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="emailNotificationSettings.isDailySubmissionReportSubscribed" name="isDailySubmissionReportSubscribed" id="checkbox" (ngModelChange)="updateEmailNotificationSettings()" [disabled]="isPersonalAccountReadOnly">
              <span class="slider round" [ngClass]="isPersonalAccountReadOnly? 'cursor-not-allowed':'cursor-pointer'"></span>
            </label>
          </span>
        </span>
      </div>
      <div class="displayFlex">
        <span class="text-color-grey">Weekly Submission Report</span>
        <span class="form-group margin-bottom-0 no-wrap padding-left-10">
          <span class="toggle">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="emailNotificationSettings.isWeeklySubmissionReportSubscribed" name="isWeeklySubmissionReportSubscribed" id="checkbox" (ngModelChange)="updateEmailNotificationSettings()" [disabled]="isPersonalAccountReadOnly">
              <span class="slider round" [ngClass]="isPersonalAccountReadOnly? 'cursor-not-allowed':'cursor-pointer'"></span>
            </label>
          </span>
        </span>
      </div>
    </div>
    <hr *ngIf="isAtsEnabled" />
    <div class="margin-top-20 notification-table">
      <div style=" padding-bottom:25px;">
        <span colspan="2" style="border-bottom:2px solid #32aac2; padding-bottom:15px;" class="text-color-grey">
          Notification Settings
        </span>
        <span class="" title="As it is read only account email notification settings are disabled." *ngIf="isPersonalAccountReadOnly">
          <i class="fa fa-info fa-help-text info-small"></i>
        </span>
      </div>
      <div class="displayFlex align-items-center" *ngIf="loggedInCompanyUser !== null">
        <span class="form-group margin-bottom-0 no-wrap text-color-grey">Jobs</span>
        <span class="form-group margin-bottom-0 no-wrap padding-left-10">
          <span class="toggle">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="notificationSettings.isJobSubscribed" name="isJobSubscribed" (ngModelChange)="updateNotificationSettings()" [disabled]="isPersonalAccountReadOnly">
              <span class="slider round" [ngClass]="isPersonalAccountReadOnly? 'cursor-not-allowed':'cursor-pointer'"></span>
            </label>
          </span>
        </span>
      </div>
      <div class="displayFlex" *ngIf="loggedInCompanyUser !== null">
        <span class="text-color-grey">Candidates</span>
        <div class="form-group margin-bottom-0 no-wrap padding-left-10">
          <span class="toggle">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="notificationSettings.isCandidateSubscribed" name="isCandidateSubscribed" id="checkbox" (ngModelChange)="updateNotificationSettings()" [disabled]="isPersonalAccountReadOnly">
              <span class="slider round" [ngClass]="isPersonalAccountReadOnly? 'cursor-not-allowed':'cursor-pointer'"></span>
            </label>
          </span>
        </div>
      </div>
      <div class="displayFlex" *ngIf="loggedInCompanyUser !== null">
        <span class="text-color-grey">Connections</span>
        <span class="form-group margin-bottom-0 no-wrap padding-left-10">
          <span class="toggle">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="notificationSettings.isConnectionSubscribed" name="isConnectionSubscribed" id="checkbox" (ngModelChange)="updateNotificationSettings()" [disabled]="isPersonalAccountReadOnly">
              <span class="slider round" [ngClass]="isPersonalAccountReadOnly? 'cursor-not-allowed':'cursor-pointer'"></span>
            </label>
          </span>
        </span>
      </div>
      <div class="displayFlex">
        <span class="text-color-grey">Discussions</span>
        <span class="form-group margin-bottom-0 no-wrap padding-left-10">
          <span class="toggle">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="notificationSettings.isDiscussionSubscribed" name="isDiscussionSubscribed" id="checkbox" (ngModelChange)="updateNotificationSettings()" [disabled]="isPersonalAccountReadOnly">
              <span class="slider round" [ngClass]="isPersonalAccountReadOnly? 'cursor-not-allowed':'cursor-pointer'"></span>
            </label>
          </span>
        </span>
      </div>
      <div class="displayFlex">
        <span class="text-color-grey">Network Jobs</span>
        <span class="form-group margin-bottom-0 no-wrap padding-left-10">
          <span class="toggle">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="notificationSettings.isNetworkJobSubscribed" name="isNetworkJobSubscribed" id="checkbox" (ngModelChange)="updateNotificationSettings()" [disabled]="isPersonalAccountReadOnly">
              <span class="slider round" [ngClass]="isPersonalAccountReadOnly? 'cursor-not-allowed':'cursor-pointer'"></span>
            </label>
          </span>
        </span>
      </div>
      <div class="displayFlex">
        <span class="text-color-grey">Network Candidates</span>
        <span class="form-group margin-bottom-0 no-wrap padding-left-10">
          <span class="toggle">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="notificationSettings.isNetworkCandidateSubscribed" name="isNetworkCandidateSubscribed" id="checkbox" (ngModelChange)="updateNotificationSettings()" [disabled]="isPersonalAccountReadOnly">
              <span class="slider round" [ngClass]="isPersonalAccountReadOnly? 'cursor-not-allowed':'cursor-pointer'"></span>
            </label>
          </span>
        </span>
      </div>
    </div>  
  </div>
</div>
