import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Linkedinparams } from 'src/app/models/linkedinparams';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiURL: string = environment.apiURL;
  private apiController: string = "v1/login";
  constructor(private httpClient: HttpClient) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  //Todo Send this in body than in query
  public linkedInLogin(linkedInParameters: Linkedinparams) {
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/linkedIn/`, linkedInParameters);
  }

  public getUserProfile(url: string) {
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/linkedinAccessCode/${url}`, url);
  }

  public getUserLoginAccounts(loggedInEmailId: string) {
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/getUserLoginAccounts/`, { loggedInEmailId }).pipe(map(this.extractData));
  }

  public getLoggedInUserByCompanyId(companyUserId: number) {
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/getLoggedInUserByCompanyId/${companyUserId}`, companyUserId);
  }

  public unSubscribeUserFromSignalR(userId: number) {
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/disconnectUserSubscription/${userId}`, userId);
  }
  //#endregion
}
