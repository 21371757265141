export enum AccountType {
  CompanyUser = 1,
  FreeLancer = 2,
  HiringManager = 3,
  Client = 4
}

export enum Tabs {
  Details = 1,
  SubmittedCandidates = 2,
  Discussions = 3,
  Activity = 4
}

export enum Tabs {
  CandidateDetails = 1,
  AssignedJobs = 2,
  CandidateDiscussions = 3,
  CandidateActivity = 4, 
  CandidateNotes = 5
}
