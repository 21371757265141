import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NonAtsJobsMatcher } from './matchers/nonAtsJobs.Matcher';
import { AtsJobsMatcher } from './matchers/atsJobs.Matcher';
import { AtsCandidatesMatcher } from './matchers/atsCandidates.matcher';
import { NonAtsCandidatesMatcher } from './matchers/nonAtsCandidates.matcher';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SocialloginComponent } from './components/sociallogin/sociallogin.component';
import { CustomPreloader } from './helpers/custom-preloader';
import { HelpComponent } from './components/help/help.component';
import { SettingsComponent } from './components/header/settings/settings.component';

const routes: Routes = [
  { path: "", loadChildren: "src/app/components/landing-page/landing-page.module#LandingPageModule" },
  { path: "login", component: SocialloginComponent },
  { path: "AccountActivation", loadChildren: "src/app/components/registration/registration.module#RegistrationModule" },
  { path: "CompanyUserActivation", loadChildren: "src/app/components/management/management.module#ManagementModule" },
  { path: "Invitations", loadChildren: "src/app/components/management/management.module#ManagementModule" },
  { path: "Registration", loadChildren: "src/app/components/registration/registration.module#RegistrationModule" },
  { path: "ChooseAccount", loadChildren: "src/app/components/chooseaccount/choose-account.module#ChooseAccountModule" },
  { path: "Home", loadChildren: "src/app/components/dashboard/dashboard.module#DashboardModule" },
  { matcher: AtsJobsMatcher, loadChildren: "src/app/components/jobs/jobs.module#JobsModule", data: { preload: true } },
  { matcher: NonAtsJobsMatcher, loadChildren: "src/app/components/non-ats-jobs/non-ats-jobs.module#NonAtsJobsModule", data: { preload: true } },
  { matcher: AtsCandidatesMatcher, loadChildren: "src/app/components/candidates/candidates.module#CandidatesModule", data: { preload: true } },
  { matcher: NonAtsCandidatesMatcher, loadChildren: "src/app/components/non-ats-candidates/non-ats-candidates.module#NonAtsCandidatesModule", data: { preload: true } },
  { path: "Reports", loadChildren: "src/app/components/reports/reports.module#ReportsModule" },
  { path: "Management", loadChildren: "src/app/components/management/management.module#ManagementModule" },
  { path: "Network", loadChildren: "src/app/components/network/network.module#NetworkModule" },
  { path: "SearchResult", loadChildren: "src/app/components/searchresult/search-result.module#SearchResultModule" },
  { path: "ProfilePage", loadChildren: "src/app/components/network/profile-page/profile-page.module#ProfilePageModule" },
  { path: "PageNotFound", component: PageNotFoundComponent },
  { path: "Help", component: HelpComponent },
  { path: "Settings", component: SettingsComponent },
  { path: "Posts", loadChildren: "src/app/components/posting-article/posting-article.module#PostingArticleModule" },
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top', preloadingStrategy: CustomPreloader })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
