<div class="overRite" *ngIf="viewFeedback"></div>
<div class="popupContentBlock feedbackpopup" *ngIf="viewFeedback" style="height:500px; top:12%; width:800px;">
  <div class="pop-up-header">
    <span class="m-l-15 font-bold">Feedback</span>
  </div>
  <div class="padding-popup height390">
    <div class="margin-bottom-15 col-lg-12 padding-0">
      <span class="pull-left margin-top-7 label-title">Select category</span>
      <select class="form-control width20 pull-left margin-left-50" name="feedbackCategory" [(ngModel)]="feedback.feedbackCategoryId" #feedbackCategory="ngModel" required [ngClass]="{'validateInput': (feedbackSubmitted) && feedbackCategory.invalid }">
        <option value=0 selected disabled>Select category</option>
        <option *ngFor="let category of feedbackCategories" [ngValue]="category.categoryId">{{category.category}}</option>
      </select>
    </div>

    <div class="margin-bottom-15 col-lg-12 padding-0 display-inline-flex" *ngIf="feedback.feedbackCategoryId>0">
      <span class="pull-left margin-top-10  label-title">Rate your experience</span>
      <star-rating id="startRating" value="{{feedbackRating}}" class="margin-left-15" checkedcolor="gold" uncheckedcolor="gray" size="24px" (rate)="onFeedbackRating($event)" readonly="false"></star-rating>
    </div>
    <div class="upload-btn-wrapper">
      <button _ngcontent-luq-c18="" class="btn ignorBTN pull-right margin-left-10 themeColorlight text-color-white withInORGbtn cursor-pointer">Upload file</button>
      <input (click)="document.value = null" value="" title="   " multiple accept={{permittedFiles}} type="file" #document (change)="uploadfiles($event,document.files)" style="margin-left: 10px;width: 94px; padding: 6px 8px;cursor: pointer;" />
    </div>
    <ul class="float-width margin-bottom-10">
      <li *ngFor="let file of filesToUpload;let i=index">
        <i class="fa fa-image margin-left-10 margin-right-10" aria-hidden="true"></i>
        <span>{{file.name}}</span>
        <i class="fa fa-times-circle pull-right cancel-btn-list cursor-pointer" title="Remove" (click)="removeFile(i)" aria-hidden="true"></i>
      </li>
    </ul>
    <quill-editor placeholder='Describe your experience' [(ngModel)]="feedbackComment" class=""   (onContentChanged)="commonFunction.textChanged($event,8000)" style="margin-bottom: 10px;" #textArea></quill-editor>
    
  </div>
  <button class="secondary-btn pull-right margin-right-15 margin-top-10" (click)="insertFeedback()">Submit</button>
  <button class="btn ignorBTN pull-right margin-right-10 primary-btn margin-top-10" style="padding:0 15px;" (click)="closeTab()">Cancel</button>


</div>
