export enum NotificationType {
  Job = 1,
  Candidate = 2,
  Connection = 3,
  NetworkJob = 4,
  NetworkCandidate = 5,
  JobDiscussion = 6,
  NetworkJobDiscussion = 7,
  CandidateDiscussion = 8,
  NetworkCandidateDiscussion = 9,
}
