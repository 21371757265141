<link *ngIf="customTheme" rel="stylesheet" [href]='customTheme | sanitize'>
<div id="ng-chat" *ngIf="!isDisabled && isBootstrapped && !unsupportedViewport" [ngClass]="theme">
  <div *ngIf="!hideFriendsList" id="ng-chat-people" [ngClass]="{'primary-outline-color': true, 'primary-background': true, 'ng-chat-people-collapsed': isCollapsed}">
    <a href="javascript:void(0);" class="ng-chat-title secondary-background shadowed" (click)="onChatTitleClicked($event)">
      <span>
        {{localization.title}}
      </span>
    </a>
    <div *ngIf="currentActiveOption" class="ng-chat-people-actions" (click)="onFriendsListActionCancelClicked()">
      <a href="javascript:void(0);" class="ng-chat-people-action">
        <i class="remove-icon"></i>
      </a>
      <a href="javascript:void(0);" class="ng-chat-people-action" (click)="onFriendsListActionConfirmClicked()">
        <i class="check-icon"></i>
      </a>
    </div>
    <input *ngIf="searchEnabled" id="ng-chat-search_friend" class="friends-search-bar" type="search" [placeholder]="localization.searchPlaceholder" [(ngModel)]="searchInput" (keyup)="onSearchChanged()" />
    <ul id="ng-chat-users" *ngIf="!isCollapsed" [ngClass]="{'offset-search': searchEnabled}">
      <li *ngFor="let user of filteredParticipants">
        <input *ngIf="currentActiveOption && currentActiveOption.validateContext(user)"
               type="checkbox"
               class="ng-chat-users-checkbox"
               (change)="onFriendsListCheckboxChange(user, $event.target.checked)"
               [checked]="isUserSelectedFromFriendsList(user)" />
        <div [ngClass]="{'ng-chat-friends-list-selectable-offset': currentActiveOption, 'ng-chat-friends-list-container': true}" (click)="openChatWindow(user, true, true)">
          <img *ngIf="!user.avatar" alt="" class="avatar" height="30" width="30" src="../../../../assets/IMG/avatar.png" />
          <img *ngIf="user.avatar" alt="" class="avatar" height="30" width="30" src="{{blobUrl}}{{user.avatar}}" />
          <strong title="{{user.displayName}}">{{user.displayName}}</strong>
          <span [ngClass]="{'ng-chat-participant-status': true, 'online': user.status == ChatParticipantStatus.Online, 'busy': user.status == ChatParticipantStatus.Busy, 'away': user.status == ChatParticipantStatus.Away, 'offline': user.status == ChatParticipantStatus.Offline}" title="{{getStatusTitle(user.status)}}"></span>
          <span *ngIf="user.totalUnReadMessages!=''" class="ng-chat-unread-messages-count unread-messages-counter-container primary-text">{{user.totalUnReadMessages}}</span>
        </div>
      </li>
    </ul>

    <div class="nodatanetwork" *ngIf="filteredParticipants?.length==0" style="margin-top:1pc; height:254px !important;">
      <div class="noData">
        <img src="../../../../assets/IMG/nouserconnections.png" width="70" /><br />
        <b class="font-14 nodatatext">No connection(s) found</b>
      </div>
    </div>
  </div>
  <div *ngFor="let window of windows; let i = index" [ngClass]="{'ng-chat-window': true, 'primary-outline-color': true, 'ng-chat-window-collapsed': window.isCollapsed}" [ngStyle]="{'right': (!hideFriendsList ? friendsListWidth : 0) + 20 + windowSizeFactor * i + 'px'}">
    <ng-container *ngIf="window.isCollapsed">
      <div class="ng-chat-title secondary-background">
        <div class="ng-chat-title-visibility-toggle-area" (click)="onChatWindowClicked(window)">
          <strong title="{{window.participant.displayName}}">
            {{window.participant.displayName}}
          </strong>
          <span [ngClass]="{'ng-chat-participant-status': true, 'online': participantStatus(window.participant) == ChatParticipantStatus.Online, 'busy': participantStatus(window.participant) == ChatParticipantStatus.Busy, 'away': participantStatus(window.participant) == ChatParticipantStatus.Away, 'offline': participantStatus(window.participant) == ChatParticipantStatus.Offline}" title="{{getStatusTitle(window.participant.status)}}"></span>
          <span *ngIf="unreadMessagesTotal(window).length > 0" class="ng-chat-unread-messages-count unread-messages-counter-container primary-text">{{unreadMessagesTotal(window)}}</span>
        </div>
        <a href="javascript:void(0);" class="ng-chat-close primary-text" title="Close" (click)="onCloseChatWindow(window)">X</a>
      </div>
    </ng-container>
    <ng-container *ngIf="!window.isCollapsed">
      <div class="ng-chat-title secondary-background">
        <div class="ng-chat-title-visibility-toggle-area" (click)="onChatWindowClicked(window)">
          <strong title="{{window.participant.displayName}}">
            {{window.participant.displayName}}
          </strong>
          <span [ngClass]="{'ng-chat-participant-status': true, 'online': participantStatus(window.participant) == ChatParticipantStatus.Online, 'busy': participantStatus(window.participant) == ChatParticipantStatus.Busy, 'away': participantStatus(window.participant) == ChatParticipantStatus.Away, 'offline': participantStatus(window.participant) == ChatParticipantStatus.Offline}" title="{{getStatusTitle(window.participant.status)}}"></span>
          <span *ngIf="unreadMessagesTotal(window).length > 0" class="ng-chat-unread-messages-count unread-messages-counter-container primary-text">{{unreadMessagesTotal(window)}}</span>
        </div>
        <a href="javascript:void(0);" class="ng-chat-close primary-text" (click)="onCloseChatWindow(window)" title="Close">X</a>
        <ng-chat-options [ngClass]="'ng-chat-options-container'" [options]="defaultWindowOptions(window)" [chattingTo]="window" [(activeOptionTracker)]="currentActiveOption"></ng-chat-options>
      </div>
      <div #chatMessages class="ng-chat-messages primary-background">
        <div *ngIf="window.isLoadingHistory" class="ng-chat-loading-wrapper">
          <div class="loader">Loading history...</div>
        </div>
        <div *ngIf="hasPagedHistory && window.hasMoreMessages && !window.isLoadingHistory" class="ng-chat-load-history">
          <a class="load-history-action" (click)="fetchMessageHistory(window)">{{localization.loadMessageHistoryPlaceholder}}</a>
        </div>
        <div *ngFor="let message of window.messages; let i = index" [ngClass]="{'ng-chat-message': true, 'ng-chat-message-received': message.fromId != userId}">
          <ng-container *ngIf="isAvatarVisible(window, message, i)">

            <img *ngIf="!getChatWindowAvatar(window.participant, message)" alt="" class="avatar" height="30" width="30" src="../../../../assets/IMG/avatar.png" />

            <img *ngIf="getChatWindowAvatar(window.participant, message)" alt="" class="avatar" height="30" width="30" [src]="getChatWindowAvatar(window.participant, message) | sanitize" />
            <span *ngIf="window.participant.participantType == ChatParticipantType.Group" class="ng-chat-participant-name">{{window.participant | groupMessageDisplayName:message}}</span>
          </ng-container>
          <ng-container [ngSwitch]="message.type">
            <div *ngSwitchCase="MessageType.Text" [ngClass]="{'sent-chat-message-container': message.fromId == userId, 'received-chat-message-container': message.fromId != userId}">
              <span [innerHtml]="message.message | emojify:emojisEnabled | linkfy:linkfyEnabled"></span>
              <span *ngIf="showMessageDate && message.dateSent" class="message-sent-date">{{message.dateSent | date:messageDatePipeFormat}}</span>
            </div>
            <div *ngSwitchCase="MessageType.File" [ngClass]="{'file-message-container': true, 'received': message.fromId != userId}">
              <div class="file-message-icon-container">
                <i class="paperclip-icon"></i>
              </div>
              <a class="file-details" [attr.href]="message.downloadUrl" target="_blank" rel="noopener noreferrer" (click)="this.markMessagesAsRead([message])" download>
                <span class="file-message-title" [attr.title]="message.message">{{message.message}}</span>
                <span *ngIf="message.fileSizeInBytes" class="file-message-size">{{message.fileSizeInBytes}} Bytes</span>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="ng-chat-footer primary-outline-color primary-background">
        <input #chatWindowInput
               type="text"
               [ngModel]="window.newMessage | emojify:emojisEnabled"
               (ngModelChange)="window.newMessage=$event"
               [placeholder]="localization.messagePlaceholder"
               [ngClass]="{'chat-window-input': true, 'has-side-action': fileUploadAdapter}"
               (keydown)="onChatInputTyped($event, window)"
               (blur)="toggleWindowFocus(window)"
               (focus)="toggleWindowFocus(window)" />
        <!-- File Upload -->
        <ng-container *ngIf="fileUploadAdapter">
          <a *ngIf="!isUploadingFile(window)" class="btn-add-file" (click)="triggerNativeFileUpload(window)">
            <i class="upload-icon"></i>
          </a>
          <input type="file"
                 #nativeFileInput
                 style="display: none;"
                 [attr.id]="getUniqueFileUploadInstanceId(window)"
                 (change)="onFileChosen(window)" />
          <div *ngIf="isUploadingFile(window)" class="loader"></div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
