import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ProfitRateCalculator } from 'src/app/models/ProfitRateCalculator/profit-rate-calculator';
import { Contactperson } from 'src/app/models/contactpersonmodel/contactperson';
import { Client } from 'src/app/models/clientmodel/client';
import { CompanyUserInvite } from 'src/app/models/companyuserinvitemodel/company-user-invite';
import { UserInvite } from 'src/app/models/user-invite';
import { UserManagementFilterByFields } from 'src/app/models/gridFilters/user-management-filter-by-fields';
import { ExternalInvitesFilterFields } from 'src/app/models/gridFilters/external-invites-filter';
import { ExternalInvitation } from 'src/app/models/external-invitation';
import { UserDependencyUpdateRequest} from 'src/app/models/user-dependency';
import { UpdateCompanyUserRequest } from 'src/app/models/management/update-company-user-request';
import { InsertCompanyUserInviteRequest } from 'src/app/models/management/insert-company-user-invite-request';


@Injectable({
  providedIn: 'root'
})

export class ManagementService {
  apiURL: string = environment.apiURL;

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  constructor(private httpClient: HttpClient) { }

  //#region Invite User Service Methods
  public insertCompanyUserInvite(insertCompanyUserInviteReq: InsertCompanyUserInviteRequest) {
    return this.httpClient.post(`${this.apiURL}/insertCompanyUserInvite/`, insertCompanyUserInviteReq);
  }

  public resendCompanyUserInvite(companyUser: CompanyUserInvite) {
    return this.httpClient.post(`${this.apiURL}/resendCompanyUserInvite/`, companyUser);
  }

  public getInviteActiveStatus(userInviteGuid: string) {
    return this.httpClient.post(`${this.apiURL}/getInviteActiveStatus/${userInviteGuid}`, {});
  }

  public activateCompanyUserInvite(userInvite: UserInvite) {
    return this.httpClient.post(`${this.apiURL}/activateCompanyUserInvite/`, userInvite);
  }
  //#endregion

  //#region User Management Service Methods
  public getCompanyUsers(filterByFields: UserManagementFilterByFields) {
    return this.httpClient.post(`${this.apiURL}/getCompanyUsers`, filterByFields).pipe(map(this.extractData));
  }

  public getCreatedByUsers() {
    return this.httpClient.get(`${this.apiURL}/getCreatedByUsers`).pipe(map(this.extractData));
  }

  public updateCompanyUser(updateCompanyUserReq: UpdateCompanyUserRequest) {
    return this.httpClient.post(`${this.apiURL}/updateCompanyUser`, updateCompanyUserReq);
  }

  public deleteCompanyUser(companyUserId: number) {
    return this.httpClient.post(`${this.apiURL}/deleteCompanyUser/` + companyUserId, { responseType: 'text' });
  }

  public getCompanyUserInvites(filterFields: UserManagementFilterByFields) {
    return this.httpClient.post(this.apiURL + '/getCompanyUserInvites', filterFields);
  }

  public deleteCompanyUserInvite(companyUserInviteId: number) {
    return this.httpClient.post(`${this.apiURL}/deleteCompanyUserInvite/${companyUserInviteId}/`, companyUserInviteId);
  }

  public getUserDependencyJobs(companyUserId:number) {
    return this.httpClient.get(`${this.apiURL}/getUserDependencyJobs/${companyUserId}`);
  }

  public updateUserDependencyJobs(updateRequest: UserDependencyUpdateRequest) {
    return this.httpClient.post(`${this.apiURL}/updateUserDependencyJobs`, updateRequest);
  }
  //#endregion

  //#region Profit Rate Service Methods
  public insertProfitRateCalculator(profitRateCalculator: ProfitRateCalculator) {
    return this.httpClient.post(`${this.apiURL}/insertProfitRateCalculator/`, profitRateCalculator);
  }

  public getProfitRateCalculator() {
    return this.httpClient.get(`${this.apiURL}/getProfitRateCalculator`);
  }

  public updateProfitRateCalculator(profitRateCalculator: ProfitRateCalculator) {
    return this.httpClient.post(`${this.apiURL}/updateProfitRateCalculator/`, profitRateCalculator);
  }

  public deleteProfitRateCalculator(profitRateCalculatorId: number) {
    return this.httpClient.get(`${this.apiURL}/DeleteProfitRateCalculator/${profitRateCalculatorId}`);
  }

  public getProfitRateCalculatorDetails(profitRateCalculatorId: number) {
    return this.httpClient.get<ProfitRateCalculator>(`${this.apiURL}/getProfitRateCalculatorDetails/${profitRateCalculatorId}`);
  }
  //#endregion

  //#region Contact Management Service Methods

  public getClients() {
    return this.httpClient.get(`${this.apiURL}/getClients`);
  }

  public insertClient(insertClient: Client) {
    return this.httpClient.post(`${this.apiURL}/insertClient/`, insertClient);
  }

  public updateClient(updateClient: Client) {
    return this.httpClient.post(`${this.apiURL}/updateClient/`, updateClient);
  }
  public activateOrDeactivateClient(clientId: number) {
    return this.httpClient.delete(`${this.apiURL}/activateOrDeactivateClient/${clientId}`);
  }

  public getClientDetails(clientId: number) {
    return this.httpClient.get<Client>(`${this.apiURL}/getClientDetails/${clientId}`);
  }
  
  public deleteContactPerson(contactPersonId: number) {
    return this.httpClient.delete(`${this.apiURL}/deleteContactPerson/${contactPersonId}`);
  }

  public updateContactPerson(contactPerson: Contactperson) {
    return this.httpClient.post(`${this.apiURL}/updateContactPerson`, contactPerson);
  }
 
  //#endregion Contact Management Service Methods

  //External invitations
  public getExternalInvitations(filterFields: ExternalInvitesFilterFields) {
    return this.httpClient.post(this.apiURL + '/externalInvitations', filterFields);
  }

  public insertExternalInvitation(invitation: ExternalInvitation) {
    return this.httpClient.post(`${this.apiURL}/insertExternalInvitation/`, invitation);
  }


  public deleteExternalInvitation(invitationId: number) {
    return this.httpClient.delete(`${this.apiURL}/externalInvitations/${invitationId}`);
  }

  public resendExternalInvitation(invitation: ExternalInvitation) {
    return this.httpClient.post(`${this.apiURL}/resendExternalInvitations/`, invitation);
  }

  public getExternalInvitationStatus(externalInviteGuid: string) {
    return this.httpClient.post(`${this.apiURL}/getExternalInvitationStatus/${externalInviteGuid}`, {});
  }

  public getCompanyUserRoles() {
    return this.httpClient.get(`${this.apiURL}/getCompanyUserRoles`);
  }

}
