
<div class="social-login-main" id="container" *ngIf="!isUseractivation">
  <div id="main">
    <a class="nav-link page-scroll" [routerLink]="['/']">
      <img src="../../../assets/IMG/logo-new.png" style="height:55px;" />
    </a>
    <span class="login-buttons-main">
      <button (click)="socialSignIn('Microsoft',false)" class="office-btn login-buttons padding-left-0 padding-right-0 margin-left-auto"><i class="fa fa-windows" aria-hidden="true"></i> <span class="btn-cnt margin-left-10"> Sign in with Microsoft</span></button>
      <button (click)="socialSignIn('Google',false)" class="google-btn login-buttons padding-left-0 padding-right-0"><i class="fa fa-google" aria-hidden="true"></i>  <span class="btn-cnt margin-left-10"> Sign in with Google</span></button>
      <button (click)="socialSignIn('LinkedIn',false)" class="linkedin-btn login-buttons padding-left-0 padding-right-0 margin-right-auto"><i class="fa fa-linkedin" aria-hidden="true"></i>  <span class="btn-cnt margin-left-10"> Sign in with LinkedIn </span></button>
    </span>
    <hr style="opacity:0;" />
    <span class="login-buttons-main signup-button position-relative" style="left:-46px;">
      <span class="btn-cnt margin-left-auto" style="color:#333;">Sign up with </span>
      <button (click)="socialSignIn('Microsoft',true)" title="Microsoft" class="office-btn login-buttons padding-left-0 padding-right-0"><i class="fa fa-windows" aria-hidden="true"></i></button>
      <button (click)="socialSignIn('Google',true)" title="Google" class="google-btn login-buttons padding-left-0 padding-right-0"><i class="fa fa-google" aria-hidden="true"></i></button>
      <button (click)="socialSignIn('LinkedIn',true)" title="LinkedIn" class="linkedin-btn login-buttons padding-left-0 padding-right-0 margin-right-auto"><i class="fa fa-linkedin" aria-hidden="true"></i></button>
    </span>

    <div class="overRite" *ngIf="showAccountType"></div>
    <div class="popupContentBlock" *ngIf="showAccountType">
      <div class="heading-details">
        <span class="m-l-15 font-bold">Do you want to sign up with with Company / Freelancer?</span>
        <i class="fa fa-times pull-right margin-top-12 cursor-pointer" title="Close" (click)="showAccountType=false"></i>
      </div>
      <div class="padding-popup" style="height:200px;">
        <table>
          <tr>
            <td>
              <button type="button" class="btn btn-primary reg-tab is-centered" (click)="signUpASCompanpy(false)">
                SignUp as Company
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <button type="button" class="btn btn-primary reg-tab is-centered " (click)="signUpASCompanpy(true)">
                SignUp as Freelancer
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="clearfix"></div>
  <footer id="footer">
    <div class="footer padding-bottom-0">
      <div class="container">
        <div class="displayFlex" style="justify-content:center;">
          <div class="footer-col text-center" style="width:330px; margin-right:100px;">
            <h4 class="footer-links">About Graph5 Inc.</h4>
            <div class="text-footer">We are here to take away the inefficiencies in the recruitment process and make recruiters and hiring managers successful</div>
          </div>
          <div class="footer-col middle text-center">
            <h4 class="footer-links">Important Links</h4>
            <ul class="list-unstyled li-space-lg">
              <li class="media">
                <div class="media-body text-center font-14">
                  Read our
                  <a class="turquoise skyblue-color font-14" [routerLink]="['//TermsConditions']">Terms & Conditions</a>,<br />

                  <a class="turquoise skyblue-color font-14 padding-left-25" [routerLink]="['//PrivacyPolicy']">Privacy Policy</a>
                </div>
              </li>
            </ul>
          </div>

          <div class="footer-col last text-center" style="margin-left:100px;">
            <h4 class="footer-links">Social Media</h4>
            <div class="width100">
              <span class="margin-left-5">
                <a href="https://www.facebook.com/Graph5-Inc-109231663988084/" target="_blank">
                  <img src="../../../assets/IMG/facebook.png" title="Facebook" style="background: #01bed9;border-radius: 50%;padding: 5px; height:35px;" />
                </a>
              </span>
              <span class="margin-right-10  margin-left-10">
                <a href="https://www.linkedin.com/company/graph5inc" target="_blank">
                  <img src="../../../assets/IMG/linkedin.png" title="Linkedin" style="background: #01bed9;border-radius: 50%;padding: 5px; height:35px;" />
                </a>
              </span>
              <span>
                <a href="https://twitter.com/Graph5Inc" target="_blank">
                  <img src="../../../assets/IMG/twitter.png" title="Twitter" style="background: #01bed9;border-radius: 50%;padding: 5px; height:35px;" />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="copyright w-100">
          <p class="p-small">Copyright © 2020 <a href="https://www.Graph5.com">Graph5 Inc.</a> - All rights reserved</p>
          <!-- end of container -->
        </div> <!-- end of copyright -->
      </div> <!-- end of container -->
      <!-- end of footer -->
      <!-- end of footer -->
      <!-- Copyright -->
      <!-- end of copyright -->
      <!-- Scripts -->
    </div>
  </footer>
</div>

