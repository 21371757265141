import { Observable } from 'rxjs';
import { Message } from "src/app/components/chat-component/ng-chat/core/message";
import { User } from "src/app/components/chat-component/ng-chat/core/user";
import { ParticipantResponse } from "src/app/components/chat-component/ng-chat/core/participant-response";
import { IChatParticipant } from 'src/app/components/chat-component/ng-chat/core/chat-participant';
import { ChatNotificationModel } from 'src/app/models/chatNotoficationModel/chat-notification-model';

export abstract class ChatAdapter {
  // ### Abstract adapter methods ###

  public abstract listFriends(): Observable<ParticipantResponse[]>;

  public abstract getMessageHistory(destinataryId: any): Observable<Message[]>;

  public abstract sendMessage(message: Message): void;

  // ### Adapter/Chat income/ingress events ###

  public onFriendsListChanged(participantsResponse: ParticipantResponse[]): void {
    this.friendsListChangedHandler(participantsResponse);
  }

  public onChatNotificationsGet(chatNotifications: ChatNotificationModel[]): void {
    this.chatNotificationsGetHandler(chatNotifications);
  }

  public onMessageReceived(participant: IChatParticipant, message: Message, isChatWindowOpened: boolean): void {
    this.messageReceivedHandler(participant, message, isChatWindowOpened);
  }

  // Event handlers
  friendsListChangedHandler: (participantsResponse: ParticipantResponse[]) => void = (participantsResponse: ParticipantResponse[]) => { };
  chatNotificationsGetHandler: (chatNotifications: ChatNotificationModel[]) => void = (chatNotifications: ChatNotificationModel[]) => { };
  messageReceivedHandler: (participant: IChatParticipant, message: Message, isChatWindowOpened: boolean) => void = (participant: IChatParticipant = null, message: Message = null) => { };
}
