import { Attachments } from '../attachments/attachments';

export class Notification {
  notificationId: number;
  notificationTypeId: number;
  jobId: number;
  networkJobId: number;
  candidateId: number;
  networkCandidateId: number;
  jobDiscussionId: number;
  candidateDiscussionId: number;
  jobActivityTypeId: number;
  candidateActivityTypeId: number;
  notificationPriority: number;
  notificationMessage: string;
  companyId: number;
  companyUserId: number;
  performedBy: number;
  performedDate: Date;
  isNetworkJob: boolean;
  isRead: boolean;
  isReadAll: boolean;
  isCleared: boolean;
  isClearedAll: boolean;
  isDiscussionNotification: boolean;
  performedByName: string;
  isNetworkCandidate: boolean;
  connectionId: number;
  senderCompanyId: number;
  senderUserId: number;
  receiverCompanyId: number;
  receiverUserId: number;
  isCompanyNotification: boolean;
  isJob: boolean;
  isCandidate: boolean;
  performedByImage: Attachments = new Attachments();  //PR 3367 For image display
  performedByImageBlobName: string; //PR 3367 For image display
  isShared: boolean;
  isUnShared: boolean;
  isMigrationNotification: boolean;
  filterType:string;
}
