export const signalRNotificationConstants = {
  receiveNotification: 'receiveNotification',
  friendsListChanged: 'friendsListChanged',
  userStatusUpdated: 'userStatusUpdated',
  connectionsNotifications: 'connectionsNotifications',
  receiveUserMessage: 'receiveUserMessage',
  updateUserConnections: 'updateUserConnections',
  receiveUserNotification: 'receiveUserNotification',
  receiveCompanyUserNotification: 'receiveCompanyUserNotification',
  receiveCompanyNotification: 'receiveCompanyNotification',
  receiveWithinCompanyDiscussionMessage: 'receiveWithinCompanyDiscussionMessage',
  receiveEveryOnePost: 'receiveEveryOnePost',
  receiveMyConnectionsPost: 'receiveMyConnectionsPost',
  deletePost: 'deletePost',
  receiveCompanyShareHomePageJobs: 'receiveCompanyShareHomePageJobs',
  receiveCompanyUserShareHomePageJobs: 'receiveCompanyUserShareHomePageJobs',
  receiveCompanyUnShareHomePageEntity: 'receiveCompanyUnShareHomePageEntity',
  receiveCompanyUserUnShareHomePageEntity: 'receiveCompanyUserUnShareHomePageEntity',
  receiveCompanyShareHomePageCandidate: 'receiveCompanyShareHomePageCandidate',
  receiveCompanyUserShareHomePageCandidate: 'receiveCompanyUserShareHomePageCandidate',
  receiveCompanyDiscussion: 'receiveCompanyDiscussion',
  receiveCompanyUserDiscussion: 'receiveCompanyUserDiscussion',
  deleteCompanyUserDiscussion: 'deleteCompanyUserDiscussion',
  deleteNotification: 'deleteNotification',
  deleteChatNotification: 'deleteChatNotification',
  deleteCompanyDiscussion: 'deleteCompanyDiscussion',
  joinCompanyGroup: 'JoinCompanyGroup',
  joinCompanyAdminGroup: 'JoinCompanyAdminGroup',
  subscribe: 'Subscribe',
  company:'Company'
};
