import { Component, OnInit } from '@angular/core';
import { Route, Router} from '@angular/router';
import { NetworkService } from 'src/app/services/network/network.service';
import { ClearsearchService } from 'src/app/services/ClearSearch/clearsearch.service';
import { HeaderSearch } from 'src/app/models/enum/headersearch.enum';
import * as CryptoJS from 'crypto-js';
import { GlobalSearchRequest } from 'src/app/models/globalSearch/global-search-request';
import Swal from 'sweetalert2';
import { messages } from '../../../constants/messages';
import { _ } from 'underscore';
import { AccountType } from 'src/app/models/enum/account-type';
import { GlobalContentService } from 'src/app/services/global/global-content.service';
import * as commonFunctions from 'src/app/helpers/commonFunctions';

@Component({
  selector: 'app-searchconnection',
  templateUrl: './searchconnection.component.html',
  styleUrls: ['./searchconnection.component.css']
})

export class SearchconnectionComponent implements OnInit {
  searchTypes = [{ Id: 1, Name: "Company", Icon: "fa fa-building" }, { Id: 2, Name: "People", Icon: "fa fa-user" }, { Id: 3, Name: "Job", Icon: "fa fa-user" }, { Id: 4, Name: "Candidate", Icon: "fa fa-user" }];
  searchTerm = "";
  selectedSearchType: number = 1;
  isPersonalAccount: boolean = false;
  globalSearchRequest: GlobalSearchRequest;
  placeholder = "Search company";
  isShowSelection = false;
  blobUrl: string;
  searchResult = [];
  searchBy: any = HeaderSearch;
  isAtsEnabled: boolean;
  candidateBlobUrl: string;
  isViewMoreResults: boolean = false;
  loggedInUser;
  appConfigData: any;

  constructor(
    private router: Router,
    private networkService: NetworkService,
    private clearsearchService: ClearsearchService,
    private globalService: GlobalContentService) {
    this.onTextChange = _.debounce(this.onTextChange, 300);
  }

  ngOnInit() {
    this.appConfigData = require("appsettings.json");
    this.loggedInUser = this.globalService.getLoggedInUserData();
    this.isPersonalAccount = this.loggedInUser ? this.loggedInUser.user.isPersonalAccount : false;
    var subscription = this.clearsearchService.getEmittedValue()
      .subscribe(item => {
        this.searchTerm = commonFunctions.replaceSpecialCharacters(item)
      });
    this.candidateBlobUrl = this.isPersonalAccount ? this.appConfigData.BlobSettings.baseUrl + this.appConfigData.BlobSettings.freelancerCandidatePath : this.appConfigData.BlobSettings.baseUrl + this.appConfigData.BlobSettings.companyCandidatePath;
    this.blobUrl = this.appConfigData.BlobSettings.baseUrl + this.appConfigData.BlobSettings.profilePath;
    this.isAtsEnabled = this.loggedInUser ? this.loggedInUser.user.isAtsEnabled : false;
  }

  onSelection(type) {
    this.searchTerm = "";
    this.isShowSelection = false;
    switch (type) {
      case "1":
        this.placeholder = "Search company";
        break;
      case "2":
        this.placeholder = "Search people";
        break;
      case "3":
        this.placeholder = "Search job";
        break;
      case "4":
        this.placeholder = "Search candidate";
        break;
    }
  }

  onTextChange() {
    this.searchTerm = this.searchTerm.trim();
    setTimeout(() => {
      if (this.searchTerm.length > 0) {
        this.globalSearchRequest = {
          selectedSearchType: +this.selectedSearchType,
          searchTerm: commonFunctions.replaceSpecialCharacters(this.searchTerm).trim(),
          isAllResults: false
        };
        this.networkService.getGlobalSearchResults(this.globalSearchRequest).subscribe((data: any) => {
          this.isShowSelection = true;
          this.searchResult = data;
        }, (err) => {
          Swal.fire({ title: messages.GLOBALSEARCH_RESULTS_ERROR, type: 'error', showConfirmButton: true });
        });
      } else {
        this.isShowSelection = false;
      }
    }, 500);
  }

  redirectToRespectiveDetails(data, redirectTo) {
    if (data != undefined && redirectTo == 1) {
      data.networkCandidateId = (_.isNull(data.networkCandidateId) || _.isUndefined(data.networkCandidateId)) ? 0 : data.networkCandidateId;
      sessionStorage.setItem("candidateId", data.candidateId.toString());
      sessionStorage.setItem("isNetworkCandidate", JSON.stringify(data.isNetworkCandidate));
      sessionStorage.setItem("networkCandidateId", data.networkCandidateId.toString());
      sessionStorage.setItem("activeTab", "0");
      var candidateId = (data.isNetworkCandidate == true) ? CryptoJS.enc.Utf8.parse(data.networkCandidateId.toString()) : CryptoJS.enc.Utf8.parse(data.candidateId.toString());
      candidateId = CryptoJS.enc.Base64.stringify(candidateId);
      var isNetworkCandidate = CryptoJS.enc.Utf8.parse(data.isNetworkCandidate.toString());
      isNetworkCandidate = CryptoJS.enc.Base64.stringify(isNetworkCandidate);
      var isNetworkSubmittedCandidate = CryptoJS.enc.Utf8.parse("false");
      isNetworkSubmittedCandidate = CryptoJS.enc.Base64.stringify(isNetworkSubmittedCandidate);
      candidateId = candidateId.replace(/[^\w\s]/gi, '');
      isNetworkCandidate = isNetworkCandidate.replace(/[^\w\s]/gi, '');
      isNetworkSubmittedCandidate = isNetworkSubmittedCandidate.replace(/[^\w\s]/gi, '');
      var networkCandidateId = CryptoJS.enc.Utf8.parse(data.networkCandidateId.toString());
      networkCandidateId = CryptoJS.enc.Base64.stringify(networkCandidateId);
      networkCandidateId = networkCandidateId.replace(/[^\w\s]/gi, '');
      sessionStorage.setItem("connectionId", "0");
      if (this.loggedInUser.user.accountType == AccountType.HiringManager)
        this.router.navigate(["Candidates/NetworkCandidates/CandidateDetails", candidateId, networkCandidateId, isNetworkCandidate, isNetworkSubmittedCandidate]);
      else
        this.router.navigate(["Candidates/CandidatePool/CandidateDetails", candidateId, networkCandidateId, isNetworkCandidate, isNetworkSubmittedCandidate]);
      this.clearSearch();
    }
    else {
      sessionStorage.setItem("jobActiveTab", "0");
      sessionStorage.setItem("jobId", data.jobId.toString());
      sessionStorage.setItem("networkJobId", data.networkJobId.toString());
      sessionStorage.setItem("isNetworkJob", JSON.stringify(data.isNetworkJob));
      var jobId = CryptoJS.enc.Utf8.parse(data.jobId.toString());
      jobId = CryptoJS.enc.Base64.stringify(jobId);
      var isNetworkJob = CryptoJS.enc.Utf8.parse(data.isNetworkJob.toString());
      isNetworkJob = CryptoJS.enc.Base64.stringify(isNetworkJob);
      if (data.isNetworkJob) {
        jobId = CryptoJS.enc.Utf8.parse(data.networkJobId.toString());
        jobId = CryptoJS.enc.Base64.stringify(jobId);
      }
      jobId = jobId.replace(/[^\w\s]/gi, '')
      isNetworkJob = isNetworkJob.replace(/[^\w\s]/gi, '')
      sessionStorage.setItem("connectionId", "0");
      var networkJobId = CryptoJS.enc.Utf8.parse(data.networkJobId.toString());
      networkJobId = CryptoJS.enc.Base64.stringify(networkJobId);
      networkJobId = networkJobId.replace(/[^\w\s]/gi, '')
      this.router.navigate(["Jobs/OrgJobs/JobDetails", jobId, networkJobId, isNetworkJob]);
      this.clearSearch();
    }
  }

  viewProfile(profile) {
    this.searchTerm = "";
    this.isShowSelection = false;
    sessionStorage.setItem("profileId", profile.id.toString());
    sessionStorage.setItem("isCompany", JSON.stringify(this.selectedSearchType == this.searchBy.company));
    sessionStorage.setItem("isFromNetworkTab", JSON.stringify(false));
    this.router.navigate(["ProfilePage"]);
  }

  viewMoreResults() {
    if (this.searchTerm.length >= 1 && this.searchResult.length > 0) {
      sessionStorage.setItem("searchType", this.selectedSearchType.toString());
      sessionStorage.setItem("searchTerm", this.searchTerm);
      this.isShowSelection = false;
      this.navigateToResult();
      document.getElementById("globalSearchInput").blur();
    }
  }

  focusOut() {
    this.selectedSearchType = this.searchBy.company;
    this.placeholder = "Search company";
    this.searchTerm = "";
    this.isShowSelection = false;
  }

  clearSearch() {
    this.searchTerm = "";
    this.isShowSelection = false;
  }

  clearSearchResults() {
    this.searchResult = [];
    this.searchTerm = "";
    this.isShowSelection = false;
  }

  navigateToResult() {
    switch (+this.selectedSearchType) {
      case this.searchBy.candidate:
        this.router.navigate(["SearchResult/CandidateSearchResults"]);
        break;
      case this.searchBy.job:
        this.router.navigate(["SearchResult/JobSearchResults"]);
        break;
      default:
        this.router.navigate(["SearchResult"]);
    }
  }

  navigateTo(path: string, event: any) {
    if (event.ctrlKey) {
      window.open(this.appConfigData.ApplicationUrl + "/#/" + path, '_blank');
      return;
    }
    this.router.navigate([path]);
  }
}
