import { NgModule, ErrorHandler } from '@angular/core';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, LinkedInLoginProvider } from "angularx-social-login";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { HttpInterceptorHelper } from "src/app/helpers/interceptors/http-interceptor";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//#region User Defined Components
import { FooterComponent } from './components/footer/footer.component';
import { GlobalContentService } from './services/global/global-content.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePipe } from '@angular/common';
import { DeleteCheckBoxComponent } from './components/customgridcomponents/customcheckbox'; //PR 3318
import { EINDirective } from './directives/EIN/ein.directive';
import { NavigationGuard } from './helpers/navigationGuard/navigation.guard'; //PR 3369: for pageleave out.
import { NgChatModule } from 'src/app/components/chat-component/ng-chat/ng-chat.module';
import { Ng2SmartTableModule } from 'src/app/components/ng2-smart-table/ng2-smart-table.module';
import { MessageService } from 'primeng/components/common/messageservice';
import { Ng2CompleterModule } from 'ng2-completer';
import { AngularWebStorageModule } from 'angular-web-storage';
import { OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng5SliderModule } from 'ng5-slider';
import { SharedComponentsModule } from './components/shared-components/shared-components.module';
import { GridCheckBoxComponent } from './components/customgridcomponents/gridcheckbox.component';
import { HeaderModule } from './components/header/header.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SocialloginComponent } from './components/sociallogin/sociallogin.component';
import { GlobalErrorHandler } from './helpers/global-error-handler';
import { HelpComponent } from './components/help/help.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';

//#endregion
var appConfigData = require("appsettings.json");
let directiveDeclarations: any = [
  EINDirective
];
let componentDeclarations: any = [
  AppComponent,
  FooterComponent,
  DeleteCheckBoxComponent,
  GridCheckBoxComponent,
  SocialloginComponent
];

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(appConfigData.GoogleProviderId)
  },
  {
    id: LinkedInLoginProvider.PROVIDER_ID,
    provider: new LinkedInLoginProvider(appConfigData.linkedInConfig.clientId, false, 'en_US')
  }
]);

export function provideConfig() {
  return config;
}
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "red",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 4,
  "fgsColor": "#00324F",
  "fgsPosition": "center-center",
  "fgsSize": 40,
  "fgsType": "ball-spin-clockwise",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(250,250,250,0.4)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center"
};

@NgModule({
  declarations: [componentDeclarations, directiveDeclarations, EINDirective, PageNotFoundComponent, HelpComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    SocialLoginModule,
    Ng2CompleterModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    AngularWebStorageModule,
    OwlNativeDateTimeModule,
    Ng2SearchPipeModule,
    NgSelectModule,
    Ng5SliderModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    HeaderModule,
    MomentModule,
    NgChatModule,
    SharedComponentsModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxUiLoaderHttpModule.forRoot({
      exclude: [
        'https://test.graph5.com/api/privateChat',
        'https://test.graph5.com/api/updateNotificationStatus',
        'https://test.graph5.com/api/candidateStatus',
        'https://test.graph5.com/api/discussion',
        'https://test.graph5.com/api/v1/search/locations',
        'https://test.graph5.com/api/getHomePageSharedJobs',
        'https://test.graph5.com/api/getHomePageSharedCandidates',
        'https://test.graph5.com/api/unLikePost',
        'https://test.graph5.com/api/postLike',
        'https://test.graph5.com/api/getPostComments',
        'https://test.graph5.com/api/getPostCommentReplies',
        'https://test.graph5.com/api/deletePostComment',
        'https://test.graph5.com/api/addPostComment',
        'https://test.graph5.com/api/updatePostComment',
        'https://test.graph5.com/api/getPostLikedUsersOnScroll',
        'https://www.graph5.com/api/privateChat',
        'https://www.graph5.com/api/candidateStatus',
        'https://www.graph5.com/api/discussion',
        'https://www.graph5.com/api/v1/search/locations',
        'https://www.graph5.com/api/getHomePageSharedJobs',
        'https://www.graph5.com/api/getHomePageSharedCandidates',
        'https://www.graph5.com/api/updateNotificationStatus',
        'https://www.graph5.com/api/unLikePost',
        'https://www.graph5.com/api/postLike',
        'https://www.graph5.com/api/getPostComments',
        'https://www.graph5.com/api/getPostCommentReplies',
        'https://www.graph5.com/api/deletePostComment',
        'https://www.graph5.com/api/addPostComment',
        'https://www.graph5.com/api/updatePostComment',
        'https://www.graph5.com/api/getPostLikedUsersOnScroll',
        'https://dev.graph5.com/api/privateChat',
        'https://dev.graph5.com/api/candidateStatus',
        'https://dev.graph5.com/api/discussion',
        'https://dev.graph5.com/api/v1/search/locations',
        'https://dev.graph5.com/api/getHomePageSharedJobs',
        'https://dev.graph5.com/api/getHomePageSharedCandidates',
        'https://dev.graph5.com/api/updateNotificationStatus',
        'https://dev.graph5.com/api/unLikePost',
        'https://dev.graph5.com/api/postLike',
        'https://dev.graph5.com/api/getPostComments',
        'https://dev.graph5.com/api/getPostCommentReplies',
        'https://dev.graph5.com/api/deletePostComment',
        'https://dev.graph5.com/api/addPostComment',
        'https://dev.graph5.com/api/updatePostComment',
        'https://dev.graph5.com/api/getPostLikedUsersOnScroll',
      ],
      showForeground: true
    }),
    BrowserAnimationsModule,
    DragDropModule
  ],
  entryComponents: componentDeclarations,
  providers: [
    NgxNavigationWithDataComponent,
    DatePipe,
    GlobalContentService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorHelper,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    NavigationGuard,
    MessageService,
    NgxImageCompressService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
