import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { FormsModule } from '@angular/forms';
import { RatingModule } from 'ng-starrating';
import { SearchconnectionComponent } from './searchconnection/searchconnection.component';
import { RouterModule, Routes } from '@angular/router';
import { OutsideclickDirective } from './searchconnection/outsideclickdirective/outsideclick.directive';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { PhoneMaskDirective } from 'src/app/directives/PhoneNumber/phone-mask.directive';
import { ProgressBarModule } from "angular-progress-bar";
import { InputTrimModule } from 'ng2-trim-directive';
import { ApplicationFeedbackComponent } from './application-feedback/application-feedback.component';
import { QuillModule } from 'ngx-quill';
import { SettingsComponent } from './settings/settings.component';

export function overrideTabBehaviour() {
  return this.nextAll('input')[0].focus();
}

@NgModule({

  declarations: [HeaderComponent, SearchconnectionComponent, OutsideclickDirective, PhoneMaskDirective, ApplicationFeedbackComponent, SettingsComponent],

  imports: [
    CommonModule,
    FormsModule,
    RatingModule,
    ProgressBarModule,
    SharedComponentsModule,
    InputTrimModule,
    QuillModule.forRoot({
      modules: {
        toolbar: false,
        keyboard: {
          bindings: {
            tab: {
              key: 9,
              handler: overrideTabBehaviour
            },
          }
        }
      }
    })

  ],

  exports: [RouterModule, HeaderComponent, SearchconnectionComponent, OutsideclickDirective, PhoneMaskDirective, ApplicationFeedbackComponent, QuillModule]

})

export class HeaderModule { }
