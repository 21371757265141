import { UrlSegment, UrlSegmentGroup, Route } from '@angular/router';
import { sessionStorageKeyConstants } from '../constants/sessionStorageKeyConstants';
import * as CryptoJS from 'crypto-js';

export function AtsJobsMatcher(
  segments: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route) {
  const appConfigData = require("appsettings.json");
  let loggedInUserData = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser);
  if (loggedInUserData) {
    var bytes = CryptoJS.AES.decrypt(loggedInUserData, appConfigData.LoggedInUserEncryptionKey);
    loggedInUserData = bytes.toString(CryptoJS.enc.Utf8);
  }
  let loggedInUser = JSON.parse(loggedInUserData);
  const isAtsUser = loggedInUser ? loggedInUser.user.isAtsEnabled : false;
  if ((isAtsUser == null || isAtsUser == undefined) || segments.length === 0)
    return null;
  const isJobsPath = segments[0].path === "Jobs";
  if (isJobsPath && isAtsUser) {
    return { consumed: [segments[0]] };
  } else {
    return null;
  }
}
