import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from '../fileupload/SafePipe.pipe';
import { ActivityComponent } from '../activity/activity.component';
import { DiscussionComponent } from '../discussion/discussion.component';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/components/autocomplete/autocomplete'
import { BulkUploadComponent } from '../bulk-upload/bulk-upload.component';
import { PagerComponent } from '../customgridcomponents/pagercomponent/pagercomponent.component';
import { QuillModule } from 'ngx-quill';
import { PotentialCandidatesbulkUploadComponent } from '../potential-candidatesbulk-upload/potential-candidatesbulk-upload.component';
import { PotentialcandidatesComponent } from '../potential-candidates/potential-candidates.component';
import { Ng2SmartTableModule } from '../ng2-smart-table/ng2-smart-table.module';
import { FileuploadComponent } from '../fileupload/fileupload.component';
import { ImageCropperModule } from 'ngx-image-cropper';

// To highlight the next available input field
export function overrideTabBehaviour() {
  return this.nextAll('input')[0].focus();
}
var toolbarOptions = [['bold', 'italic'], ['image']];
@NgModule({

  declarations: [SafePipe, ActivityComponent, DiscussionComponent, BulkUploadComponent, PagerComponent, PotentialCandidatesbulkUploadComponent, PotentialcandidatesComponent, FileuploadComponent],

  imports: [
    CommonModule,
    FormsModule,
    AutoCompleteModule,
    Ng2SmartTableModule,
    ImageCropperModule,
    QuillModule.forRoot({
      modules: {
        toolbar: toolbarOptions,
        keyboard: {
          bindings: {
            tab: {
              key: 9,
              handler: overrideTabBehaviour
            },
          }
        }
      }
    })
  ],

  exports: [SafePipe, ActivityComponent, DiscussionComponent, AutoCompleteModule, QuillModule, BulkUploadComponent, PagerComponent, PotentialCandidatesbulkUploadComponent, PotentialcandidatesComponent, Ng2SmartTableModule, FileuploadComponent, ImageCropperModule]

})

export class SharedComponentsModule { }
