import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  BooleanUrl: string = "https://resources.workable.com/tutorial/source-google-boolean";
  appsettings: any;
  appConfigData: any;
  constructor() { }

  ngOnInit() {
  this.appsettings = require('appsettings.json');

  }
  //redirectToWindow() {
  //  window.open(this.BooleanUrl)
  //}

}
  


