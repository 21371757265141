import { Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PRIMARY_OUTLET } from "@angular/router";
import { CanDeactivate } from '@angular/router';
import { ClearsearchService } from 'src/app/services/ClearSearch/clearsearch.service';
import { AccountType } from 'src/app/models/enum/account-type';
import { sessionStorageKeyConstants } from 'src/app/constants/sessionStorageKeyConstants';
import * as CryptoJS from 'crypto-js';
import { GlobalContentService } from 'src/app/services/global/global-content.service';
import { _ } from 'underscore';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationGuard implements CanActivate, OnInit, CanDeactivate<CanComponentDeactivate> {
  private userLoggedIn: boolean;
  private router: Router;
  private lastVisited: string = '';
  appConfigData = require("appsettings.json");

  constructor(
    router: Router,
    private clearsearchService: ClearsearchService,
    private globalService: GlobalContentService) {
    this.router = router;
  }
  //PR 3369: for pageleave out.
  canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }

  ngOnInit() {

  }

  canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | boolean {
    var loggedIn = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser) == null ? false : true;
    if (!loggedIn) {
      this.router.navigate(['login']);
      return false;
    }
    let loggedInUser = this.globalService.getLoggedInUserData();
    var teamHirarchyCheck = (routerStateSnapshot.url.toLowerCase().indexOf("team") > -1 && ((!(loggedInUser.hasAdminPrivilege) && !loggedInUser.user.isPersonalAccount) || (!loggedInUser.user.isAtsEnabled)));
    var contactManagementCheck = (routerStateSnapshot.url.toLowerCase().indexOf("contactmanagement") > -1 && (!loggedInUser.user.isAtsEnabled));
    var userManagementCheck = (routerStateSnapshot.url.toLowerCase().indexOf("usermanagement") > -1 && (!loggedInUser.hasAdminPrivilege || loggedInUser.user.isPersonalAccount));
    var reportsCheck = (routerStateSnapshot.url.toLowerCase().indexOf("reports") > -1 && (!loggedInUser.user.isAtsEnabled));
    var hiringManagerCheck = (
      (routerStateSnapshot.url.toLowerCase().indexOf("candidatepool") > -1
        || routerStateSnapshot.url.toLowerCase().indexOf("onboardedcandidates") > -1
        || routerStateSnapshot.url.toLowerCase().indexOf("blacklistedcandidates") > -1
        || routerStateSnapshot.url.toLowerCase().indexOf("networkjobs") > -1)
      && loggedInUser.user.accountType == AccountType.HiringManager
    );
    
    if (teamHirarchyCheck || contactManagementCheck || contactManagementCheck || userManagementCheck || reportsCheck || hiringManagerCheck)
    {
      this.router.navigate(['/PageNotFound']);
      return (false);
    }
    
    let currentRouteUrl = sessionStorage.getItem(sessionStorageKeyConstants.currentUrl);
    if (currentRouteUrl) {
      currentRouteUrl = CryptoJS.AES.decrypt(sessionStorage.getItem(sessionStorageKeyConstants.currentUrl), this.appConfigData.currentUrlEncryptionKey);
      if (currentRouteUrl !== routerStateSnapshot.url) {
        sessionStorage.setItem("connectionId", "0");
      }
    }
    currentRouteUrl = CryptoJS.AES.encrypt(routerStateSnapshot.url, this.appConfigData.currentUrlEncryptionKey);
    sessionStorage.setItem(sessionStorageKeyConstants.currentUrl, currentRouteUrl);
    this.lastVisited = '';
    return true;
  }

  private getUrlWithoutSecondary(routerStateSnapshot: RouterStateSnapshot): UrlTree {
    var urlTree = this.router.parseUrl(routerStateSnapshot.url);
    var segment = urlTree.root;
    while (segment && segment.children) {

      delete (segment.children.secondary);

      segment = segment.children[PRIMARY_OUTLET];

    }
    return (urlTree);
  }

  private isPageRefresh(): boolean {
    return (!this.router.navigated);
  }

  private getCurrentRoute(currentState: string) {
    let cState = currentState.substring(1, currentState.length);
    var candi = cState.search('CandidateDetails');
    var job = cState.search('JobDetails');
    let isFromNetworkTab = sessionStorage.getItem("isFromNetworkTab") == null ? false : JSON.parse(sessionStorage.getItem("isFromNetworkTab"));
    if (candi != -1) {
      return cState = "Candidates";
    }
    else if (job != -1) {
      return cState = "Jobs";
    }
    else if (isFromNetworkTab && cState == "ConnectionProfile") {
      return cState = "Network";
    }
    else {
      return cState;
    }
  }
}
