//PR 3318: Added component for the delete checkbox in the contact management
import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'deletecheckbox-component',
  template: `<input type="checkbox" [(ngModel)]="rowData.isDeleted" (ngModelChange)="onModelChange()"/>`
})
export class DeleteCheckBoxComponent {
  rowData: any;
  value: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  onModelChange() {
    this.save.emit(this.rowData);
  }
}
