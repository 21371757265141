import { DecimalPipe } from '@angular/common';

export class Attachments {

  categoryId: number;
  blobName: string;
  originalName: string;
  attachmentType: string;
  size: number;
  uploadedby: number;
  uploadedDate: Date;
  placeHolder: string;
  sasUri: string;
  container: string;
}
