import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { sessionStorageKeyConstants } from 'src/app/constants/sessionStorageKeyConstants';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class GlobalContentService implements OnInit {

  public IsLoggedIn = new Subject<any>();
  IsLoggedIn$ = this.IsLoggedIn.asObservable();
  appSettingsData = require("appsettings.json");

  constructor() {
  }

  ngOnInit() {
    this.IsLoggedIn.next((sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser)) == null ? false : true);
  }

  setLoggedInUser(userData: any) {
    this.IsLoggedIn.next(userData == null ? false : true);
  }

  getLoggedInUserData() {
    let loggedInUserData = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser);
    if (loggedInUserData) {
      var bytes = CryptoJS.AES.decrypt(loggedInUserData, this.appSettingsData.LoggedInUserEncryptionKey);
      loggedInUserData = bytes.toString(CryptoJS.enc.Utf8);
    }
    return JSON.parse(loggedInUserData);
  }
}
