export const sessionStorageKeyConstants = {
  loggedInCompanyUser: 'KEY1',
  expireDateTime: 'KEY2',
  authToken: 'KEY3',
  companySettings: 'KEY4',
  currentUrl: 'KEY5',
  UsersConnectedList: 'KEY6',
  notificationUnReadCount: 'KEY7',
  userPrivacySettings: 'KEY8',
  regUserSocialData: 'KEY9'
};
