import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { CONFIGURATION } from 'src/app/constants/app.constants';
import { Discussion } from 'src/app/models/discussion/discussion';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ChatMessage } from 'src/app/models/chatMessages/chatMessages';
import { ChatAdapter } from 'src/app/components/chat-component/ng-chat/core/chat-adapter';
import { User } from 'src/app/components/chat-component/ng-chat/core/user';
import { Message } from 'src/app/components/chat-component/ng-chat/core/message';
import { IChatParticipant } from 'src/app/components/chat-component/ng-chat/core/chat-participant';
import { ChatParticipantType } from 'src/app/components/chat-component/ng-chat/core/chat-participant-type.enum';
import { ChatParticipantStatus } from 'src/app/components/chat-component/ng-chat/core/chat-participant-status.enum';
import { ParticipantResponse } from 'src/app/components/chat-component/ng-chat/core/participant-response';
import { ParticipantMetadata } from 'src/app/components/chat-component/ng-chat/core/participant-metadata';
import { Notification } from 'src/app/models/notificationmodel/notification';
import { Userpost } from 'src/app/models/userpost/userpost';
import * as CryptoJS from 'crypto-js';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { DatePipe } from '@angular/common';
import { ChatNotificationModel } from 'src/app/models/chatNotoficationModel/chat-notification-model';
import { signalRNotificationConstants } from 'src/app/constants/signalRNotificationConstants';
import { sessionStorageKeyConstants } from 'src/app/constants/sessionStorageKeyConstants';
import { Role } from 'src/app/models/enum/role.enum';

@Injectable({
  providedIn: 'root'
})
export class SignalrService extends ChatAdapter {
  particiPantType: ChatParticipantType;
  participantMetadata: ParticipantMetadata;
  usersData: User;
  authToken: string;
  chatParticipantStatus: ChatParticipantStatus;
  connectionEstablished = new Subject<Boolean>();
  private hubConnection: HubConnection;
  private messageReceivedSource = new Subject<any>();
  private newMessageReceived = new Subject<any>();
  private openChatnotification = new Subject<any>();
  private onGetChatnotification = new Subject<any>();
  // private updateDiscussionNotificationSource = new Subject<any>();
  private getNotificationSource = new Subject<any>();
  private userStatusUpdate = new Subject<any>();
  private connectionsNotificationsSource = new Subject<any>();
  private updatedUsersList = new Subject<any>();
  private updatedUserConnections = new Subject<any>();


  private receiveUserNotification = new Subject<any>();
  private receiveCompanyUserNotification = new Subject<any>();
  private receiveCompanyNotification = new Subject<any>();
  private receiveWithinCompanyDiscussionMessage = new Subject<any>();
  private receiveEveryOnePost = new Subject<any>();
  private receiveMyConnectionsPost = new Subject<any>();
  private deletePost = new Subject<any>();
  private receiveCompanyShareHomePageJobs = new Subject<any>();
  private receiveCompanyUserShareHomePageJobs = new Subject<any>();
  private receiveCompanyUnShareHomePageEntity = new Subject<any>();
  private receiveCompanyUserUnShareHomePageEntity = new Subject<any>();
  private receiveCompanyShareHomePageCandidate = new Subject<any>();
  private receiveCompanyUserShareHomePageCandidate = new Subject<any>();
  private receiveCompanyDiscussion = new Subject<any>();
  private receiveCompanyUserDiscussion = new Subject<any>();
  private deleteCompanyUserDiscussion = new Subject<any>();
  private deleteNotification = new Subject<any>();
  private deleteChatNotification = new Subject<any>();
  private deleteCompanyDiscussion = new Subject<any>();

  receiveUserMessage$ = this.messageReceivedSource.asObservable();
  newMessageReceived$ = this.newMessageReceived.asObservable();
  openChatnotification$ = this.openChatnotification.asObservable();
  onGetChatnotification$ = this.onGetChatnotification.asObservable();

  getNotification$ = this.getNotificationSource.asObservable();
  userStatusUpdate$ = this.userStatusUpdate.asObservable();
  updatedUsersList$ = this.updatedUsersList.asObservable();
  updatedUserConnections$ = this.updatedUserConnections.asObservable();

  receiveUserNotification$ = this.receiveUserNotification.asObservable();
  receiveCompanyUserNotification$ = this.receiveCompanyUserNotification.asObservable();
  receiveCompanyNotification$ = this.receiveCompanyNotification.asObservable();
  receiveWithinCompanyDiscussionMessage$ = this.receiveWithinCompanyDiscussionMessage.asObservable();
  receiveEveryOnePost$ = this.receiveEveryOnePost.asObservable();
  receiveMyConnectionsPost$ = this.receiveMyConnectionsPost.asObservable();
  deletePost$ = this.deletePost.asObservable();
  receiveCompanyShareHomePageJobs$ = this.receiveCompanyShareHomePageJobs.asObservable();
  receiveCompanyUserShareHomePageJobs$ = this.receiveCompanyUserShareHomePageJobs.asObservable();
  receiveCompanyUnShareHomePageEntity$ = this.receiveCompanyUnShareHomePageEntity.asObservable();
  receiveCompanyUserUnShareHomePageEntity$ = this.receiveCompanyUserUnShareHomePageEntity.asObservable();
  receiveCompanyShareHomePageCandidate$ = this.receiveCompanyShareHomePageCandidate.asObservable();
  receiveCompanyUserShareHomePageCandidate$ = this.receiveCompanyUserShareHomePageCandidate.asObservable();
  receiveCompanyDiscussion$ = this.receiveCompanyDiscussion.asObservable();
  receiveCompanyUserDiscussion$ = this.receiveCompanyUserDiscussion.asObservable();
  deleteCompanyUserDiscussion$ = this.deleteCompanyUserDiscussion.asObservable();
  deleteNotification$ = this.deleteNotification.asObservable();
  deleteChatNotification$ = this.deleteChatNotification.asObservable();
  deleteCompanyDiscussion$ = this.deleteCompanyDiscussion.asObservable();
  appConfigData = require("appsettings.json");

  public participantResponse: ParticipantResponse[] = [];
  constructor(private dashboardService: DashboardService, ) {
    super();
    this.authToken = sessionStorage.getItem(sessionStorageKeyConstants.authToken);
  }

  public getAccessToken() {
    const appSettingsData = require("appsettings.json");
    let accessToken = JSON.parse(sessionStorage.getItem(sessionStorageKeyConstants.authToken));
    if (accessToken != null && accessToken.length > 0) {
      var bytes = CryptoJS.AES.decrypt(accessToken, appSettingsData.APIAuthorizationKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return null;
  }

  public createConnection() {
    let token = this.getAccessToken();
    if (token !== null && this.hubConnection == null) {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(CONFIGURATION.baseUrls.server + 'chathub', {
          accessTokenFactory: () => this.getAccessToken()
        })
        //.configureLogging(signalR.LogLevel.Trace)
        .build();
      this.startConnection();
      this.hubConnection.onclose(h => {
        this.startConnection();
      });
      this.hubConnection.onreconnecting(h => {
      });
    }
  }

  private startConnection() {
    if (this.hubConnection != null && this.hubConnection.state == signalR.HubConnectionState.Disconnected) {
      this.hubConnection.start().then(() => {
        this.registerOnServerEvents();
        this.joinSignalrGroups();
      }).catch((error) => {

      });
    }
  }

  private joinSignalrGroups() {
    let loggedInUserData = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser);
    if (loggedInUserData) {
      var bytes = CryptoJS.AES.decrypt(loggedInUserData, this.appConfigData.LoggedInUserEncryptionKey);
      loggedInUserData = bytes.toString(CryptoJS.enc.Utf8);
    }
    var userData = JSON.parse(loggedInUserData);
    this.hubConnection.invoke(signalRNotificationConstants.joinCompanyGroup, userData.companyId.toString()).catch((err) => { });
    this.hubConnection.invoke(signalRNotificationConstants.subscribe, signalRNotificationConstants.company, userData.companyUser, userData.userId).catch((err) => { });

    if (userData.companyUserRole) {
      var user = userData.hasAdminPrivilege;
      if (user) {
        this.hubConnection.invoke(signalRNotificationConstants.joinCompanyAdminGroup, userData.companyId.toString()).catch((err) => { });
      }
    }
  }

  public newMessageReceivedAction(message: any) {
    this.newMessageReceived.next(message);
  }

  public openChatNotificationAction(chatNotification: IChatParticipant) {
    this.openChatnotification.next(chatNotification);
  }

  public getChatNotificationAction(chatNotifications: ChatNotificationModel[]) {
    this.onGetChatnotification.next(chatNotifications);
  }

  private registerOnServerEvents(): void {

    this.hubConnection.on(signalRNotificationConstants.receiveNotification, () => {
      this.getNotificationSource.next();
    });

    this.hubConnection.on(signalRNotificationConstants.friendsListChanged, (participantsResponse: ParticipantResponse[]) => {
      let loggedInUserData = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser);
      if (loggedInUserData) {
        var bytes = CryptoJS.AES.decrypt(loggedInUserData, this.appConfigData.LoggedInUserEncryptionKey);
        loggedInUserData = bytes.toString(CryptoJS.enc.Utf8);
      }
      var userData = JSON.parse(loggedInUserData);
      var updatedList: ParticipantResponse[] = participantsResponse.filter(x => x.participant.id != userData.userId);
      this.updatedUsersList.next(updatedList);
    });

    this.hubConnection.on(signalRNotificationConstants.userStatusUpdated, (userId, status) => {
      this.userStatusUpdate.next({ id: userId, status: status });
    });

    this.hubConnection.on(signalRNotificationConstants.connectionsNotifications, (notification) => {
      this.connectionsNotificationsSource.next(notification);
    });

    this.hubConnection.on(signalRNotificationConstants.receiveUserMessage, (message: ChatMessage) => {
      this.messageReceivedSource.next(message);
    });


    this.hubConnection.on(signalRNotificationConstants.updateUserConnections, (connectionSender: ParticipantResponse, currentUser: ParticipantResponse, connected: boolean) => {
      this.updatedUserConnections.next({ connectionSender: connectionSender, connectionAcceptor: currentUser, isConnected: connected });
    });

    this.hubConnection.on(signalRNotificationConstants.receiveUserNotification, (data) => {
      this.receiveUserNotification.next(data);
    });

    this.hubConnection.on(signalRNotificationConstants.receiveCompanyUserNotification, (notification: Notification, companyUserId: number) => {
      this.receiveCompanyUserNotification.next({ notification: notification, companyUserId: companyUserId });
    });

    this.hubConnection.on(signalRNotificationConstants.receiveCompanyNotification, (data) => {
      this.receiveCompanyNotification.next(data);
    });

    this.hubConnection.on(signalRNotificationConstants.receiveWithinCompanyDiscussionMessage, (discussion: Discussion) => {
      this.receiveWithinCompanyDiscussionMessage.next(discussion);
    });

    this.hubConnection.on(signalRNotificationConstants.receiveEveryOnePost, (userPost: Userpost) => {
      this.receiveEveryOnePost.next(userPost);
    });

    this.hubConnection.on(signalRNotificationConstants.receiveMyConnectionsPost, (userPost: Userpost) => {
      this.receiveMyConnectionsPost.next(userPost);
    });

    this.hubConnection.on(signalRNotificationConstants.deletePost, (userPost: any) => {
      this.deletePost.next(userPost);
    });

    this.hubConnection.on(signalRNotificationConstants.receiveCompanyShareHomePageJobs, (homePageJob: any) => {
      this.receiveCompanyShareHomePageJobs.next(homePageJob);
    });

    this.hubConnection.on(signalRNotificationConstants.receiveCompanyUserShareHomePageJobs, (homePageJob: any, companyUserId: number) => {
      this.receiveCompanyUserShareHomePageJobs.next({ homePageJob: homePageJob, companyUserId: companyUserId });
    });

    this.hubConnection.on(signalRNotificationConstants.receiveCompanyUnShareHomePageEntity, (isFromJob: boolean) => {
      this.receiveCompanyUnShareHomePageEntity.next(isFromJob);
    });

    this.hubConnection.on(signalRNotificationConstants.receiveCompanyUserUnShareHomePageEntity, (companyUserId: number, isJobUnshared: boolean) => {
      this.receiveCompanyUserUnShareHomePageEntity.next({ companyUserId: companyUserId, isJobUnshared: isJobUnshared });
    });

    this.hubConnection.on(signalRNotificationConstants.receiveCompanyShareHomePageCandidate, (homePageCandidate: any) => {
      this.receiveCompanyShareHomePageCandidate.next(homePageCandidate);
    });

    this.hubConnection.on(signalRNotificationConstants.receiveCompanyUserShareHomePageCandidate, (candidate: any, companyUserId: number) => {
      this.receiveCompanyUserShareHomePageCandidate.next({ candidate: candidate, companyUserId: companyUserId });
    });

    this.hubConnection.on(signalRNotificationConstants.receiveCompanyDiscussion, (discussion: any, isReply: boolean) => {
      this.receiveCompanyDiscussion.next({ discussion: discussion, isReply: isReply });
    });

    this.hubConnection.on(signalRNotificationConstants.receiveCompanyUserDiscussion, (discussion: any, companyUserId: number, isReply: boolean) => {
      this.receiveCompanyUserDiscussion.next({ discussion: discussion, companyUserId: companyUserId, isReply: isReply });
    });

    this.hubConnection.on(signalRNotificationConstants.deleteCompanyUserDiscussion, (discussion: any, companyUserId: number) => {
      this.deleteCompanyUserDiscussion.next({ discussion: discussion, companyUserId: companyUserId });
    });

    this.hubConnection.on(signalRNotificationConstants.deleteNotification, (notification: any, companyUserId: number) => {
      this.deleteNotification.next({ notification: notification, companyUserId: companyUserId });
    });

    this.hubConnection.on(signalRNotificationConstants.deleteChatNotification, (disconnectedFromUserId: number) => {
      this.deleteChatNotification.next({ disconnectedFromUserId: disconnectedFromUserId });
    });

    this.hubConnection.on(signalRNotificationConstants.deleteCompanyDiscussion, (discussion: any) => {
      this.deleteCompanyDiscussion.next(discussion);
    });
  }

  listFriends(): Observable<ParticipantResponse[]> {
    return Observable.empty();
  }

  getMessageHistory(destinataryId: any): Observable<Message[]> {
    let messageHistory: ChatMessage[] = [];
    return new Observable(obs => {
      this.dashboardService.recieveUserMessage(destinataryId).subscribe((data: any) => {
        for (let i = 0; i < data.length; i++) {
          messageHistory.push({
            type: 1,
            toId: data[i].toId,
            fromId: data[i].fromId,
            message: data[i].message,
            dateSent: data[i].dateSent,
            dateSeen: data[i].dateSeen,
            messageId: data[i].messageId,
            timeSinceMessage: data[i].timeSinceMessage,
          })
        }
        obs.next(messageHistory);
      });
    });
  }

  sendMessage(message: any): void {
    var datePipe = new DatePipe(message.dateSent.toString());
    let loggedInUserData = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser);
    if (loggedInUserData) {
      var bytes = CryptoJS.AES.decrypt(loggedInUserData, this.appConfigData.LoggedInUserEncryptionKey);
      loggedInUserData = bytes.toString(CryptoJS.enc.Utf8);
    }
    var currentLoggedInUser = JSON.parse(loggedInUserData);
    message.dateSent = datePipe.transform(message.dateSent.toString(), 'M/d/yy h:mm a', currentLoggedInUser.appTimezoneOffset, window.navigator.language);
    this.dashboardService.sendUserMessage(message).subscribe((data: any) => {
    });
  }

  stopConnection() {
    if (this.hubConnection !== null) {
      this.hubConnection.stop().then((status) => { }).catch(() => { });
      this.hubConnection.off(signalRNotificationConstants.receiveNotification);
      this.hubConnection.off(signalRNotificationConstants.friendsListChanged);
      this.hubConnection.off(signalRNotificationConstants.userStatusUpdated);
      this.hubConnection.off(signalRNotificationConstants.connectionsNotifications);
      this.hubConnection.off(signalRNotificationConstants.receiveUserMessage);
      this.hubConnection.off(signalRNotificationConstants.updateUserConnections);
      this.hubConnection.off(signalRNotificationConstants.receiveUserNotification);
      this.hubConnection.off(signalRNotificationConstants.receiveCompanyUserNotification);
      this.hubConnection.off(signalRNotificationConstants.receiveCompanyNotification);
      this.hubConnection.off(signalRNotificationConstants.receiveWithinCompanyDiscussionMessage);
      this.hubConnection.off(signalRNotificationConstants.receiveEveryOnePost);
      this.hubConnection.off(signalRNotificationConstants.receiveMyConnectionsPost);
      this.hubConnection.off(signalRNotificationConstants.deletePost);
      this.hubConnection.off(signalRNotificationConstants.receiveCompanyShareHomePageJobs);
      this.hubConnection.off(signalRNotificationConstants.receiveCompanyUserShareHomePageJobs);
      this.hubConnection.off(signalRNotificationConstants.receiveCompanyUnShareHomePageEntity);
      this.hubConnection.off(signalRNotificationConstants.receiveCompanyUserUnShareHomePageEntity);
      this.hubConnection.off(signalRNotificationConstants.receiveCompanyShareHomePageCandidate);
      this.hubConnection.off(signalRNotificationConstants.receiveCompanyUserShareHomePageCandidate);
      this.hubConnection.off(signalRNotificationConstants.receiveCompanyDiscussion);
      this.hubConnection.off(signalRNotificationConstants.receiveCompanyUserDiscussion);
      this.hubConnection.off(signalRNotificationConstants.deleteCompanyUserDiscussion);
      this.hubConnection.off(signalRNotificationConstants.deleteNotification);
      this.hubConnection = null;
    }
  }
}
