import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,

  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import * as CryptoJS from 'crypto-js';
import { sessionStorageKeyConstants } from 'src/app/constants/sessionStorageKeyConstants';
import * as commonFunctions from 'src/app/helpers/commonFunctions';

@Injectable()
export class HttpInterceptorHelper implements HttpInterceptor {
  authReq: any;
  appConfigData:any;
  isBlobcall: boolean;
  isLinkedInCall: boolean = false;
  constructor() {
    this.appConfigData = require("appsettings.json");
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.isLinkedInCall = req.url.indexOf("www.linkedin.com") != -1;
    this.isBlobcall = req.url.indexOf(require("appsettings.json").StorageAccountPath) != -1;
    var accessToken = JSON.parse(sessionStorage.getItem(sessionStorageKeyConstants.authToken));
    if (accessToken) {
      var bytes = CryptoJS.AES.decrypt(accessToken, this.appConfigData.APIAuthorizationKey);
      accessToken = bytes.toString(CryptoJS.enc.Utf8);
    }
    else
      accessToken = '';
    if (!this.isBlobcall && !this.isLinkedInCall) {
      let browser = commonFunctions.getBrowserName();
      req = req.clone({
        headers: new HttpHeaders({ 'Authorization': 'Bearer ' + accessToken, 'Browser': browser })
      });
    }
    else if (!this.isLinkedInCall) {
      req = req.clone({
        headers: new HttpHeaders()
      });
    }
    return next.handle(req).catch((error, caught) => {
      //console.log(error);
      return Observable.throw(error);
    }) as any;
  }
}
