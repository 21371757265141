import { Image } from '../imagemodel/image';
import { UserProfileModel } from '../userprofilemodel/user-profile-model';
import { Roles } from '../rolesmodel/roles';
import { City } from '../city/city';
import { Country } from '../country/country';
import { State } from '../state/state';
import { Attachments } from '../attachments/attachments';
import { Location } from '../location'
import { AccountType } from '../enum/account-type';

export class User {
  accountType: AccountType;
  companyUserRole: string;
  userId: number;
  firstName: string = '';
  lastName: string = '';
  loginEmailId: string = '';
  personalContactNumber: string = '';
  companyUserContactNumber: string = '';
  address: string = '';
  zipCode: string = '';
  timeZone: string = '';
  gender: string = '';
  aboutMe: string = '';
  workTitle: string = '';
  workCompany: string = '';
  userImageId: number;
  CreatedBy: number;
  CreatedDate: Date = new Date();
  ModifiedBy: number;
  ModifiedDate: Date = new Date();
  userImage: Attachments = new Attachments(); 
  timeZoneOffSet: string = '';
  isActive: boolean = false;
  userFullName: string = "";
  isPersonalAccount: boolean = false;
  isPersonalAccountReadOnly: boolean = false;
  UserProfileConfiguration: UserProfileModel = new UserProfileModel();
  isSharedBy: boolean = false;
  userName: string;
  userRole: Roles = new Roles();
  companyUserEmailId: string; 
  applicationTimeZone: string;
  userImageBlobName: string;
  userProfileCompletionPercentage: number;
  userLocation = new Location();
}
