import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appEIN]'
})
export class EINDirective {

  constructor(public ngControl: NgControl) { }
  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }


  onInputChange(event, backspace) {
    if (event !== null) {
      let newVal = event.replace(/\D/g, '');
      if (backspace && newVal.length <= 1) {   //PR 3350: to remove the numbers properly
        newVal = newVal.substring(0, newVal.length - 1);
      }
      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length <= 2) {
        newVal = newVal.replace(/^(\d{0,2})/, '$1');
      } else if (newVal.length <= 9) {
        newVal = newVal.replace(/^(\d{0,2})(\d{0,5})/, '$1-$2');
      } 
      else {
        newVal = newVal.substring(0, 9);
        newVal = newVal.replace(/^(\d{0,2})(\d{0,5})/, '$1-$2');
      }
      this.ngControl.valueAccessor.writeValue(newVal);
    }
  }

}
