<!DOCTYPE html>
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
  <link href="../../../assets/Css/bootstrap.css" rel="stylesheet" />
  <meta http-equiv="x-ua-compatible" content="ie=edge">
  <title>Expansion panels</title>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet">
</head>
<h1 class="text-center font-32 margin-top-30">Welcome to Graph5 Help</h1>
<br>
<body class="padding-left-20 padding-right-20">
  <br>
  <div class="help-links">
    <h3 style="text-decoration: underline;margin-bottom:30px !important;" class="font-25">What do you need help with?</h3>
    <div class="padding-15 width100 display-inline-flex margin-center">
      <a href="#orgjobs" class="padding-25 display-grid nav-link page-scroll"><img src="../../../assets/IMG/suitcase (1).png"><span class="no-wrap img-text">Organization Jobs</span></a>
      <a href="#networkjobs" class="padding-25 display-grid nav-link page-scroll"><img src="../../../assets/IMG/network jobs.png"><span class="no-wrap img-text">Network Jobs</span></a>
      <a href="#archivedjobs" class="padding-25 display-grid nav-link page-scroll"><img src="../../../assets/IMG/archived jobs.png"><span class="no-wrap img-text">Archived Jobs</span></a>
    </div>
    <br><br>
    <div class="padding-15 width100 display-inline-flex margin-center">
      <a href="#candidatepool" class="padding-25 display-grid nav-link page-scroll"><img src="../../../assets/IMG/candidates pool.png"><span class="no-wrap img-text">Organization Candidate Pool</span></a>
      <a href="#networkcandidates" class="padding-25 display-grid nav-link page-scroll"><img src="../../../assets/IMG/network candidates.png"><span class="no-wrap img-text">Network Candidates</span></a>
      <a href="#blacklistedcandidates" class="padding-25 display-grid nav-link page-scroll"><img src="../../../assets/IMG/blacklisted candidates.png"><span class="no-wrap img-text">Blacklisted Candidates</span></a>
      <a href="#onboardedcandidates" class="padding-25 display-grid nav-link page-scroll"><img src="../../../assets/IMG/onboarded.png"><span class="no-wrap img-text">Onboarded Candidates</span></a>
      <a href="#archivedcandidates" class="padding-25 display-grid nav-link page-scroll"><img src="../../../assets/IMG/archived candidates.png"><span class="no-wrap img-text">Archived Candidates</span></a>
    </div>
    <br><br>
    <div class="padding-15 width100 display-inline-flex margin-center">
      <a href="#clientsubmission" class="padding-25 display-grid nav-link page-scroll"><img src="../../../assets/IMG/client submission.png"><span class="no-wrap img-text">Client Submission</span></a>
      <a href="#recruitersubmission" class="padding-25 display-grid nav-link page-scroll"><img src="../../../assets/IMG/recruiting submissions.png"><span class="no-wrap img-text">Recruiter Submission</span></a>
      <a href="#recruitmentstatus" class="padding-25 display-grid nav-link page-scroll"><img src="../../../assets/IMG/recruiting status.png"><span class="no-wrap img-text">Recruitment Status</span></a>
      <a href="#aging" class="padding-25 display-grid nav-link page-scroll"><img src="../../../assets/IMG/anti-age.png"><span class="no-wrap img-text">Aging</span></a>
    </div>
  </div>

  <div class="panel">
    <h3 style="text-decoration: underline;" class="font-25">Suggested for you:</h3>
    <details>
      <summary>
        <ul>
          <li class="font-20">Graph5 Dashboard</li>
          <li></li>
        </ul>
      </summary>
      <div class="content">
        <p> As of like social networking sites we to contain posts so that a context can reach out to more people rather than sharing with individuals, we have given two options so that an individual can select whether to share the post within the connections or everyone in the Graph5 domain.</p>
        <ul class="padding-left-20">
          <li> For posts, we allow both text and images.</li>
          <li>Also, we provided four tiles on either side of post-panel for easy identification of favorite jobs, favorite candidates, new network jobs and new network candidates.</li>
        </ul>
      </div>
    </details>
    <details>
      <summary>
        <ul>
          <li class="font-20">Editing Your Profile</li>
          <li></li>
        </ul>
      </summary>
      <div class="content">
        <p>In the header, top-right corner look for "Profile Image" and on clicking that you can find the options "My Profile" and also "Company Profile"(For company admins), and they can be updated accordingly.</p>
      </div>
    </details>
    <details>
      <summary>
        <ul>
          <li class="font-20">Searching On Graph5 Platform</li>
        </ul>
      </summary>
      <div class="content">

        <p> You can find a search bar in the header, we have four options in dropdown for searching the content on Graph5 Platform :</p>
        <ul class="padding-left-20">
          <li>
            <b>Company:</b> You can search for companies with company names and send a connection request on the company level.
          </li>
          <li><b>People:</b> You can search for company users/freelancers with their name's or work title or work company and send a connection request on user level.</li>
        </ul>
        <hr />
        <ul class="padding-left-20">
          <li>
            <b>Job:</b> You can search for jobs belongs to you or your organization (company/ freelancer) either your own or network or archived jobs with job id, job title and with Skill mentioned in that particular job.
          </li>
          <li>
            <b>Candidate:</b>  Two options are available here.
          </li>
        </ul>
        <ol>
          <li><b>Candidate Search:</b> This option can be used to search for candidates belongs to you or your organization (company/freelancer) network, On-boarded, Blacklisted or Archived candidates with candidate id, candidate name and with Skill(s) mentioned for that particular candidate in the details section. </li>
          <li><b>Candidate Boolean search:</b> To make this option work effectively "Boolean mode" should be <b> "ON" </b> and search can be performed on candidate's resumes/CVs using Boolean strings with the combinations of OR, AND.</li>
        </ol>
        <p>
          A Sample of Boolean string : <b>(java OR J2EE) AND SQL AND (Typescript OR "Type script")</b><br />
        </p>
      </div>
    </details>
    <details>
      <summary>
        <ul>
          <li class="font-20">About Connections</li>
        </ul>
      </summary>
      <div class="content">

        <p>In graph5, connection requests can be sent on two different levels.</p>
        <ul class="padding-left-20">
          <li><b>Company level:</b> company-company connection ( All the users of the company can view/edit/delete shared jobs or candidates depending upon their permission levels ).</li>
          <li><b>User level:</b> one-one user-level connection ( Note: freelancer is also considered as an individual user).</li>
          <li><b>Hiring manager:</b> Sending requests to Hiring Manager is not allowed at the moment but the hiring manager can search and send requests to both users and companies.</li>

        </ul>
      </div>
    </details>
    <details>
      <summary>
        <ul>
          <li class="font-20">Migration On Graph5 (ATS &#8646; Non-ATS)</li>
        </ul>
      </summary>
      <div class="content">
        <ul class="padding-left-20">
          <li>ATS(Applicant tracking system).</li>
          <li>Upgrade from the Non-ATS system to the ATS system.</li>
          <li>Downgrade from the ATS system to the Non-ATS system.</li>
          <li>Restrictions are applied here which can be seen when trying to migrate</li>
        </ul>
      </div>
    </details>
    <details>
      <summary>
        <ul>
          <li class="font-20">Closing your Graph5 Account</li>
        </ul>
      </summary>
      <div class="content">
        <p>We'll be sorry to see you go. Closing your account will delete any Jobs or Candidates you've shared or received, plus your discussions and messages Please reach out to <a class="turquoise" href="mailto:admin@Graph5.com">admin@graph5.com</a></p>
      </div>
    </details>
  </div>
  <br><br>
  <br><br>
  <div class="padding-left-100 padding-right-100">
    <h6>Introducing Team Hierarchy & Gatekeeper</h6>
    <p class="margin-bottom-0">A structured team helps in organizing the team members effectively & helps in defining their functions.</p>
    <a class="displayFlex default-link nav-link page-scroll" href="#teamHierarchy">LEARN MORE <i class="fa fa-arrow-right" style="margin:2px" aria-hidden="true"></i></a>
  </div>
  <br /><br />
  <div class="padding-left-100 padding-right-100">
    <div>
      <div id="orgjobs">
        <h6>
          Organization jobs
        </h6>
        <p>The jobs created by you / your company users or jobs which are cloned from archived jobs and the accepted network jobs are shown in Organization jobs.</p>
        <ul>
          <li>Accepted network jobs will be shown with a yellow background.</li>
          <li>
            You can find the Archive option in Organization jobs (delete option) grid through which you can archive jobs, which goes into the <b>Archived jobs</b> tab.
          </li>
          <li>
            You can share jobs through <b>share</b> option in job details.
          </li>
          <li>
            You can favorite a job through its respective job details page which appears on the grid with a favorite icon.
          </li>
        </ul>
        <a href="#assignedcandidates" class="display-grid nav-link page-scroll">Assigning candidates for a job</a>
      </div>
      <br /><br />
      <div id="networkjobs">
        <h6>
          Network jobs
        </h6>
        <p>
          Jobs shared with you/your company users are shown in Network jobs.
        </p>
        <ul>
          <li>
            Accepting network jobs can be done in two ways either by accepting it from the grid or in the respective job details page.
          </li>
          <li>Accepted network jobs are indicated with green color <span><i class="material-icons cursor-default accepted-job-gird" style="font-size:15px !important;margin-right:5px;" title="Accepted">check_circle</i></span>icon</li>
          <li>Unaccepted network jobs are indicated with the blue color <span class="accept primary-btn-network-grid margin-right-0" title="Accept job"  aria-hidden="true" style="padding: 2px 5px !important;">Accept</span> button.</li>
        </ul>
        <a href="#assignedcandidatesnetwork" class="display-grid nav-link page-scroll">Assigning candidates for a network job</a>
      </div>
      <br /><br />
      <div id="archivedjobs">
        <h6>
          Archived jobs
        </h6>
        <p>
          The jobs that are deleted by you / your company users are shown in Archived jobs.
        </p>
        <ul>
          <li>If the accepted network jobs got unshared by the other company or user then it will be archived.</li>
          <li>
            If the connected user got disconnected then the jobs shared to that user will be archived.
          </li>
          <li>
            You can clone your own archived jobs through the clone icon from the details page.
          </li>
        </ul>
      </div>
      <br /><br />
    </div>
    <div>
      <div id="candidatepool">
        <h6>
          Organization candidate pool
        </h6>
        <p>
          The candidates created by you / your company users and the candidates which are imported from archived candidates are shown in Organization candidates pool.
        </p>
        <ul>
          <li>
            Bulk upload feature can be used using the <b>Bulk Upload</b> button along with candidate resumes/CVs as attachments.
          </li>
          <li>Deleting a candidate from the Organization candidate pool grid will archive candidates, which goes into the <b>Archived Candidates</b> tab.</li>
          <li>Favorite or blacklist a particular candidate from the respective candidate details page with the help of <b>Favorite</b> and <b>Blacklist</b> options that can be found below the candidate profile image.</li>
          <li>
            Share a candidate through <b>share option</b> to connected users or companies from the candidate details page.
          </li>
        </ul>
      </div>
      <br /><br />
      <div id="networkcandidates">
        <h6>
          Network candidates
        </h6>
        <p>The candidates shared with you on a user level or a company level are shown in Network Candidates.</p>
        <ul>
          <li>When you make use of a network candidate by attaching it to a job, it is considered as an accepted candidate which will be highlighted with a <b>yellow background</b>.</li>
          <li>An import of network candidates can be done into the organization from the respective candidate details page with the help of an <b>Import</b> icon.</li>
        </ul>
      </div>
      <br /><br />
      <div id="blacklistedcandidates">
        <h6>
          Blacklisted candidates
        </h6>
        <p>
          The candidates that are blacklisted by you / your company users from the candidate details page are shown in Blacklisted Candidates.
        </p>
        <ul>
          <li> Comments/remarks can be seen while blacklisting the candidate by clicking on the message icon which is located at the end of the record.</li>
        </ul>
      </div>
      <br /><br />
      <div id="onboardedcandidates">
        <h6>
          Onboarded candidates
        </h6>
        <p>
          The candidates that are onboarded by you/your company users through the job details page are shown in onboarded candidates.
        </p>
        <ul>
          <li>
            Toggle button can be used to filter <b>In Contact</b> or <b>Out Of Contract</b>.
          </li>
          <li><b>In Contract</b></li>
        </ul>
        <ol>
          <li>This grid includes candidates that are onboarded and are in contract with the company.</li>
          <li>You can edit the contract start and ends dates through the Edit icon in the grid.</li>
          <li>
            Warning is indicated with UserIcon that reflects candidates whose contract will end soon in the next 7days.
          </li>
        </ol>
        <ul><li><b>Out Of Contract</b></li></ul>
        <ol>
          <li>This grid includes candidates that are onboarded and whose contract has been ended.</li>
          <li>As like In Contract this also contains edit icon for editing start and end dates of the contract.</li>
        </ol>
      </div>
      <br /><br />
      <div id="archivedcandidates">
        <h6>
          Archived candidates
        </h6>
        <p>
          The candidates that are deleted by you / your company users are shown in Archived Candidates.
        </p>
        <ul>
          <li>
            If the accepted network candidate(s) got unshared by the other company/user then it will be shown in archived.
          </li>
          <li>
            If the connected user got disconnected then the candidates shared with that user will be archived.
          </li>
        </ul>
      </div>
      <br /><br />
    </div>
    <div>
      <div id="clientsubmission">
        <h6>
          Client submission
        </h6>
        <ul>
          <li>
            This report shows the submissions per client with the date filters applied on Job creation Date or Job submission date.
          </li>
          <li>Click on each client record to see break down on each job level.</li>
        </ul>
      </div>
      <br /><br />
      <div id="recruitersubmission">
        <h6>
          Recruiter submission
        </h6>
        <ul>
          <li>This report shows the submission on the Recruiter basis with the date filters applied.</li>
          <li>Click on each Recruiter record to see break down on the job level with all the attached candidate(s) along with its current status.</li>
        </ul>
      </div>
      <br /><br />
      <div id="recruitmentstatus">
        <h6>
          Recruitment status
        </h6>
        <ul>
          <li>
            It has 3 tiles in which the first two shows insights of both client submissions and recruiter submission, and the third tile gives the insights of submission based on candidate status.
          </li>
        </ul>
        <b>Filters:</b>
        <ol>
          <li>Date filters</li>
          <li>
            All and latest option: All option pulls submissions including old data and latest pull the submission based on the current status of assigned candidate.
          </li>
          <li>Tile level Client and Recruiter filters.</li>
        </ol>
      </div>
      <br /><br />
      <div id="aging">
        <h6>
          Aging
        </h6>
        <p>Aging report is to analyze the age took to address a job or age took for the update of assigned candidate status.</p>
      </div>
      <br /><br />
    </div>
    <div id="assignedcandidates">
      <h6>Assigned candidates workflow for a job</h6>
      <p>
        Statuses of assigned candidates in the job details page are grouped into 5 categories.
      </p>
      <ol>
        <li>
          Sourced
        </li>
        <li>
          Internal
        </li>
        <li>
          External[Client]
        </li>
        <li>
          Onboarding
        </li>
        <li>Abandoned</li>
      </ol>
      <p><b class="red-color-fa">Note: </b>Non-Ats system has only Sourced Category.</p>
      <div>
        <p>They are all again sub-divided as follows: </p>
        <b>Sourced</b>
        <ul>
          <li><b>Unscreened</b> - Candidates sourced to a job who needs to be screened are shown in this bucket.</li>
          <li><b>Network Submitted</b> - When a job is shared in the company/user level and the recipient client submits the candidates to the network job, those client submitted candidates are shown in this bucket on the parent side.</li>
        </ul>
        <b>Internal</b>
        <ul>
          <li><b>Rejected</b> - Unscreened/Network Submitted Candidates will end up with this bucket due to any of the reasons like candidate's unavailability, fake candidates...etc.</li>
          <li><b>Interview Scheduled</b> - Candidates for whom the internal interview is scheduled comes into this bucket. candidates will be moved to this bucket automatically when we schedule interview using assigned candidate options </li>
          <li><b>Interview Pass</b> - All the candidates who cleared the internal interview belongs to this bucket.</li>
          <li><b>Interview Fail</b> - All the candidates who failed to clear the internal interview belongs to this bucket.</li>
        </ul>
        <b>External[Client]</b>
        <ul>
          <li><b>Submitted</b> - All the candidates who cleared the internal interview will be submitted to the client. Here we collect the data like Client Submission Rate, Candidate Pay Rate, Notes which also can be edited later.</li>
          <li><b>Interview Scheduled</b> - When we here from the client that the submitted candidate's interview is scheduled, candidates will be moved to this bucket.</li>
          <li><b>Interview Pass</b> - All the candidates who cleared the external(Client) interview belongs to this bucket.</li>
          <li><b>Interview Fail</b> - All the candidates who failed to clear the external(Client) interview belongs to this bucket.</li>
        </ul>
        <b>Onboarding</b>
        <ul>
          <li><b>In Progress</b> - Candidates will be assigned to this bucket when they clear the external(Client) interview and onboarding is in progress.</li>
          <li><b>Onboarded</b> - Candidates onboarded at the client will positively end up the workflow with this bucket.</li>
        </ul>
        <b>Abandoned</b>
        <ul>
          <li><b>Abandoned</b> - Due to any of the reasons like candidate's unavailability, unwilling to join...etc will be moved to this bucket. Candidates from any of the above buckets can be directed to this bucket except Internal Rejected & Onboarded.</li>
        </ul>
      </div>
      <br /><br />
      <div id="assignedcandidatesnetwork">
        <h6>Assigned candidates workflow for a network job</h6>
        <p>
          Statuses of assigned candidates in the network job details page are grouped into 4 categories.
        </p>
        <ol>
          <li>
            Sourced
          </li>
          <li>
            Internal
          </li>
          <li>
            External[Client]
          </li>
          <li>Abandoned</li>
        </ol>
        <p><b class="red-color-fa">Note: </b>Non-Ats system has only Sourced & External[Client] categories.</p>
        <div>
          <p>They are all again sub-divided as follows: </p>
          <b>Sourced</b>
          <ul>
            <li><b>Unscreened</b> - Candidates sourced to a network job who needs to be screened are shown in this bucket.</li>
          </ul>
          <b>Internal</b>
          <ul>
            <li><b>Rejected</b> - Unscreened Candidates will end up with this bucket due to any of the reasons like candidate's unavailability, fake candidates...etc.</li>
            <li><b>Interview Scheduled</b> - Candidates for whom the internal interview is scheduled comes into this bucket. candidates will be moved to this bucket automatically when we schedule interview using assigned candidate options </li>
            <li><b>Interview Pass</b> - All the candidates who cleared the internal interview belongs to this bucket.</li>
            <li><b>Interview Fail</b> - All the candidates who failed to clear the internal interview belongs to this bucket.</li>
          </ul>
          <b>External[Client]</b>
          <ul>
            <li><b>Submitted</b> - All the candidates who cleared the internal interview will be submitted to the client. Here we collect the data like Client Submission Rate, Candidate Pay Rate, Notes which also can be edited later.</li>
            <li><b>withdrawn</b> - Client submitted candidates can be withdrawn due to any of the reasons like candidate's unavailability, unwilling to join...etc.</li>
          </ul>
          <b>Abandoned</b>
          <ul>
            <li><b>Abandoned</b> - Due to any of the reasons like candidate's unavailability, unwilling to join...etc will be moved to this bucket. Candidates from any of the above buckets can be directed to this bucket except from Internal Rejected.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <br /><br />
  <div id="teamHierarchy" class="padding-left-100 padding-right-100">
    <div>
      <h6>Team Management using Hierarchy:</h6>
      <ul>
        <li>A structured team helps in organizing the team members effectively & helps in defining their functions.</li>
        <li>Using hierarchy, it would be easy to manage the team & assign work items to them as per their hierarchical position.</li>
      </ul>
      <br />
      <b><u>Roles/Users:</u></b>
      <ol>
        <li>Business Owner</li>
        <li>Business Head</li>
        <li>Account Manager</li>
        <li>Lead Recruiter</li>
        <li>Recruiter</li>
      </ol>
      <h6>Hierarchy:</h6>
      <div class="padding-left-100 padding-right-100"><img src="../../../assets/IMG/TeamHierarchy-pic.png" /></div>
      <br />
      <ul>
        <li>By the above hierarchy, it is clear that Recruiter reports to Lead Recruiter, Lead Recruiter reports to Account Manager, Account Manager reports to Business Head, Business Head reports to Business Owner.</li>
        <li>Now, the structure can be changed by removing/updating the roles using User Management. But, a business owner should be present mandatorily to lead every role in the hierarchy.</li>
        <li>Only Business Owners, Business Heads, Account Manager can remove/update a role. They can allow permissions to Lead Recruiter or Recruiter to add/remove/update a role.</li>
      </ul>
    </div>
    <br />
    <div>
      <h6>Gatekeeper:</h6>
      <ul>
        <li>Gatekeeper is a business rule in Graph5 application, based on Team Hierarchy. The sole objective is to allow the Super level users to view the network jobs shared with sub-level users who are present in Hierarchy. This helps the Super level users to keep an eye over the jobs taken up by Sub-level users. </li>
        <li>For instance, Business Owner, Business Head, Account Manager & Lead Recruiter can view the jobs shared to a recruiter & tag that job to any recruiter.</li>
      </ul>
    </div>
    <br /><br />
    <p><b class="red-color-fa">Note: </b>There is no Team Management concept for freelancer accounts.</p>
  </div>
  <br /><br />

  <div class="footer footercolor">
    <div class="container">
      <div class="row  justify-content-md-center">
        <div class="first col-lg-4">
          <h4 class="text-center">Have any other question?</h4>
          <p style="font-size:14px !important;text-align:center;">Our Graph5 Support can help you find answers. Write to us at <a class="turquoise" href="mailto:support@Graph5.com">support@graph5.com</a></p>
        </div>
        <div class="middle text-center col-lg-3">
          <h4>What's new</h4>
          <ul style="list-style-type:none; font-size:14px;padding-left:0 !important;">
            <li>Networking</li>
            <li>Migration</li>
          </ul>
        </div>
        <div class="last text-center col-lg-2">
          <h4>Social Media</h4>
          <div class="width100">
            <span class="margin-left-5">
              <a href="https://www.facebook.com/Graph5-Inc-109231663988084/" target="_blank">
                <img src="../../../assets/IMG/facebook.png" title="Facebook" style="background: #01bed9;border-radius: 50%;padding: 5px; height:35px;" />
              </a>
            </span>
            <span class="margin-right-10  margin-left-10">
              <a href="https://www.linkedin.com/company/graph5inc" target="_blank">
                <img src="../../../assets/IMG/linkedin.png" title="linkedin" style="background: #01bed9;border-radius: 50%;padding: 5px; height:35px;" />
              </a>
            </span>
            <span>
              <a href="https://twitter.com/Graph5Inc" target="_blank">
                <img src="../../../assets/IMG/twitter.png" title="Twitter" style="background: #01bed9;border-radius: 50%;padding: 5px; height:35px;" />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="copyright">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <p class="p-small">Copyright © 2020 Graph5 Inc. - All rights reserved</p>
        </div>
      </div>
    </div>
  </div>

</body>
