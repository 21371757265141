
<div class="form-group searchbar-style position-relative width100" appOutsideclick (clickOutside)="clearSearch()">

  <select class="searchNav" [(ngModel)]="selectedSearchType" (change)="onSelection(selectedSearchType)" id="selectedSearchType">
    <option *ngFor="let type of searchTypes" [value]="type.Id">{{type.Name}}</option>
  </select>
  <div class="clearfix"></div>
  <a class="pull-right">
    <i class="material-icons themeColorwithAnimation search-icon" (click)="viewMoreResults()">search</i>
  </a>
  <input type="text" id="globalSearchInput" trim="blur" [(ngModel)]="searchTerm" maxlength="400" style="padding-right:10px;" (ngModelChange)="onTextChange()" placeholder={{placeholder}} class="globalSearchtxtbox" (keyup.enter)="viewMoreResults()" autocomplete="off" />
  <ul class="globalSearchUL" *ngIf="isShowSelection && (selectedSearchType==1 || selectedSearchType==2)">
    <li *ngFor="let result of searchResult" class="bindingSearchValue  cursor-pointer" (click)="viewProfile(result)">
      <table>
        <tr>
          <td>
            <div class="avatar-search">
              <img *ngIf="result.profileImageBlobName!='' && result.profileImageBlobName!=null && selectedSearchType==searchBy.people" src="{{blobUrl}}{{result.profileImageBlobName}}" class="profileImgSRC" />
              <img *ngIf="selectedSearchType==searchBy.people && (result.profileImageBlobName =='' || result.profileImageBlobName==null)" src="../../../../assets/IMG/Profile_Empty.PNG" class="profileImgSRC" />
              <img *ngIf="result.profileImageBlobName!='' && result.profileImageBlobName!=null && selectedSearchType==searchBy.company" src="{{blobUrl}}{{result.profileImageBlobName}}" class="profileImgSRC" />
              <img *ngIf="selectedSearchType==searchBy.company && (result.profileImageBlobName =='' || result.profileImageBlobName==null)" src="../../../../assets/IMG/default-company.png" class="profileImgSRC" />
            </div>
          </td>
          <td>
            <div class="search-CNT overflow-text">
              <span class="overflow-text" title="{{result.name}}">{{result.name}}</span>
              <small class="displayFlex no-wrap keep-word" *ngIf="selectedSearchType==searchBy.company" style="color:#808080;text-overflow: ellipsis; overflow: hidden; width: 250px;" title="{{result.location}}">
                {{result.locationWithCodes|slice:0:25}}{{result.locationWithCodes?.length>25?'...':''}}
              </small>
              <small class="displayFlex" *ngIf="selectedSearchType==searchBy.people && result.workTitleAndCompany?.length>0" style="color:#808080;text-overflow: ellipsis; overflow: hidden; width: 250px;">
                {{result.workTitleAndCompany|slice:0:25}}{{result.workTitleAndCompany?.length>25?'...':''}}
              </small>
            </div>
          </td>
        </tr>
      </table>
    </li>


    <li *ngIf="searchResult.length==0" class="no-search">  <h5 style="color:#7b7b7b !important;  position: relative; top: 172px; font-size: 13px;">No results found</h5></li>
    <li *ngIf="searchResult.length>0" (click)="viewMoreResults()" class="moreResult" style="padding:5px 10px !important;"> <a> See for more results</a></li>
  </ul>

  <ul class="globalSearchUL" *ngIf="isShowSelection && (selectedSearchType==3)">
    <li *ngFor="let result of searchResult" class="bindingSearchValue  cursor-pointer" (click)="redirectToRespectiveDetails(result,0)">
      <table>
        <tr>
          <td>
            <div class="job-search-avatar">
              <img [src]="result.isNetworkJob? '../../../assets/IMG/network jobs.png' : '../../../assets/IMG/suitcase (1).png'" class="job-search-style">
            </div>
          </td>
          <td>
            <div class="search-CNT overflow-text">
              <span class="overflow-text" title="{{result.jobTitle}}">{{result.jobTitle}}</span>
              <small class="displayFlex keep-word" *ngIf="selectedSearchType==searchBy.job" style="color:#808080;text-overflow: ellipsis; overflow: hidden; width: 250px" title="{{result.location}}">
                {{result.locationWithCodes|slice:0:25}}{{result.locationWithCodes?.length>25?'...':''}}
              </small>
              <!--<small *ngIf="result.isNetworkJob">Network</small>
              <div *ngIf="result.isFavourite"><i class="favColor fa fa-star faviconcandidatedetails font-14" title="Favorite job"></i></div>-->
            </div>
          </td>
        </tr>
      </table>
    </li>
    <li *ngIf="searchResult?.length==0" class="no-search">  <h5 style="color:#7b7b7b !important;  position: relative; top: 172px; font-size: 13px;">No results found</h5></li>
    <li *ngIf="searchResult?.length>0" (click)="viewMoreResults()" class="moreResult"> <a> See for more results</a></li>
  </ul>


  <ul class="globalSearchUL" *ngIf="isShowSelection && (selectedSearchType==4)">
    <li *ngFor="let result of searchResult" class="bindingSearchValue  cursor-pointer" (click)="redirectToRespectiveDetails(result,1)">
      <table>
        <tr>
          <td>
            <div class="avatar-search">
              <img *ngIf="result.candidateImageBlobNameWithSasUri!='' && result.candidateImageBlobNameWithSasUri!=null && selectedSearchType==searchBy.candidate" src="{{result.candidateImageBlobNameWithSasUri}}" class="profileImgSRC" />
              <img *ngIf="selectedSearchType==searchBy.candidate && (result.candidateImageBlobNameWithSasUri =='' || result.candidateImageBlobNameWithSasUri==null)" src="../../../../assets/IMG/Profile_Empty.PNG" class="profileImgSRC" />
            </div>
          </td>
          <td>
            <div class="search-CNT overflow-text">
              <span class="overflow-text" title="{{result.candidateName}}">{{result.candidateName}}</span>
              <small class="displayFlex keep-word" *ngIf="selectedSearchType==searchBy.candidate" style="color:#808080;text-overflow: ellipsis; overflow: hidden; width: 250px;" title="{{result.candidateJobTitle}}">
                {{result.candidateJobTitle|slice:0:25}}{{result.candidateJobTitle?.length>25?'...':''}}
              </small>
              <small class="displayFlex keep-word" *ngIf="selectedSearchType==searchBy.candidate" style="color:#808080;text-overflow: ellipsis; overflow: hidden; width: 250px;" title="{{result.location}}">
                {{result.locationWithCodes|slice:0:25}}{{result.locationWithCodes?.length>25?'...':''}}
              </small>
              <!--<small *ngIf="result.isNetworkCandidate">Network</small>
              <div *ngIf="result.isFavourite"><i class="favColor fa fa-star faviconcandidatedetails font-14" title="Favorite candidate"></i></div>-->
            </div>
          </td>
        </tr>
      </table>
    </li>
    <li *ngIf="searchResult?.length==0" class="no-search">  <h5 style="color:#7b7b7b !important;  position: relative; top: 172px; font-size: 13px;">No results found</h5></li>
    <li *ngIf="searchResult?.length>0" (click)="viewMoreResults()" class="moreResult"> <a> See for more results</a></li>
  </ul>
</div>
<span *ngIf="(selectedSearchType==3 || selectedSearchType==4)" class="pull-right adv-srch">
  <a class="text-color-dark adv-srch-title font-size-12" (click)="selectedSearchType==3 ? navigateTo('SearchResult/JobSearchResults',$event) : navigateTo('SearchResult/CandidateSearchResults',$event)">Advanced Search</a>
</span>
