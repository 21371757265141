export enum JobFilterEnum {
  JobSharedToOthers = 1,
  FavoriteJobs = 2,
  JobSharedToUs = 3,
  JobsOnHold = 4,
  OwnJobs = 5,
}

export enum JobTabEnum {
  OrgJobs = 1,
  MyJobs = 2,
  JobsSent = 3,
  JobsReceived = 4,
  FavoriteJobs = 5,
  ArchivedJobs = 6
}
