import { Image } from 'src/app/models/imagemodel/image'
import { City } from '../city/city';
import { Country } from '../country/country';
import { State } from '../state/state';
import { Attachments } from '../attachments/attachments';
import { Location } from '../location'

export class Company {
  companyId: number;
  company: string = '';
  aboutCompany: string = '';
  employerIdentificationNumber: string = '';
  companyWebSite: string = '';
  companyImageId: number;
  cisActive: boolean = false;
  createdBy: number;
  createdDate: Date = new Date();
  codifiedBy: number;
  codifiedDate: Date = new Date();
  companyImage: Attachments = new Attachments();   //PR 3362 Company profile
  //PR 3350 Added parameters for the  company profile
  stateId: number;
  countryId: number;
  address: string = "";
  zipCode: string;
  companyEmailId: string;
  companyContactNumber: string;
  timeZoneId: number;
  timeZone: string;
  webSite: string;
  companyProfileCompletionPercentage: number;
  companyLocation = new Location();
}
