<div class="displayFlex file-upload margin-top-10">
  <div class="image-upload-wrap">
    <input class="file-upload-input width70" title="{{options?.attachmentType =='UserProfilePhoto'|| options?.attachmentType =='CompanyProfilePhoto'|| options?.attachmentType==='ProfilePhoto'?'Upload '+ options?.placeHolder:options?.blobName == '' ? 'Upload '+ options?.placeHolder : options?.originalName }}" [disabled]="isDisabled" (click)="document.value = null" value="" type="file" #document (change)="updateFiles($event,document.files)" id="{{'fileUpload'+options?.attachmentType}}" accept="{{options?.permittedFiles}}" />
    <div class="drag-text" *ngIf="!(isDisabled && options?.attachmentType=='ProfilePhoto')">
      <i class="userIcon margin-0 fa fa-camera" *ngIf="options?.attachmentType =='UserProfilePhoto'|| options?.attachmentType =='CompanyProfilePhoto'|| options?.attachmentType==='ProfilePhoto'" aria-hidden="true" [ngClass]="(options?.blobName!=='' && options?.blobName!==null && !isDisabled) ? 'padding-right-25' : 'candidate-padding' "></i>
      <img style="height:20px !important;width:auto !important;float:left;" class="userIcon" [src]="imageSource(options?.extension)" *ngIf="!(options?.attachmentType =='UserProfilePhoto'|| options?.attachmentType =='CompanyProfilePhoto'|| options?.attachmentType==='ProfilePhoto')" />
      <span>{{options?.blobName == '' ? 'Upload '+ options?.placeHolder : options?.originalName|slice:0:15}}</span><span *ngIf="options?.originalName?.length > 15">...</span>
      <img [src]="url" *ngIf="url!==null && url!=='' && options?.blobName!=='' && options?.blobName!==null && !isUpdatePage && isImageCropping" class="userIcon themeColorDark skypeUser margin-0"> <br />
    </div>
  </div>

  <div class="Upload-icons" *ngIf="options?.blobName!=='' && options?.blobName!==null ">
    <i title="Download" class="fa fa-download cursor-pointer" (click)="downloadFile()" *ngIf="options.attachmentType!=='UserProfilePhoto' && options.attachmentType!=='CandidateProfilePhoto' && options.attachmentType!=='ProfilePhoto' &&  options.attachmentType!=='CompanyProfilePhoto'&& isUpdatePage "></i>
    <i title="Delete" *ngIf="!isDisabled" class="fa fa-trash cursor-pointer delete-candid" (click)="removeFile()"></i>
    <i title="Preview" class="fa fa-external-link cursor-pointer" (click)="viewFile()" *ngIf="options.attachmentType!=='UserProfilePhoto' && options.attachmentType!=='CandidateProfilePhoto' && options.attachmentType!=='ProfilePhoto' &&  options.attachmentType!=='CompanyProfilePhoto' && isUpdatePage "></i>

  </div>
</div>

<div class="overRite" *ngIf="showCropper"></div>
<div class="popupContentBlock bulkupload" style="width:800px" *ngIf="showCropper">
  <div class="pop-up-header">
    <span class="m-l-15 font-bold">Crop your image</span>
  </div>
  <div class="padding-popup img-crop">
    <form name="clientSubmissionForm" #submissionForm="ngForm" novalidate>
      <div style="width:100%; height:400px; text-align:center;">
        <image-cropper style="height:400px; text-align:center;" [imageChangedEvent]="imageChangedEvent"
                       [maintainAspectRatio]="true"
                       [aspectRatio]="4 / 4"
                       [resizeToWidth]="200"
                       [cropperMinWidth]="200"
                       [onlyScaleDown]="true"
                       [roundCropper]="false"
                       format="png"
                       outputType="both"
                       (imageCropped)="imageCropped($event)"
                       (imageLoaded)="imageLoaded()"
                       [style.display]="showCropper ? null : 'none'"
                       [alignImage]="'left'"></image-cropper>
      </div>
      <img [src]="croppedImage" class="croppedImg" />
    </form>
  </div>
  <footer class="float-width">
    <button class="primary-btn outline-none margin-left-10 pull-right" style="padding:0 15px;" (click)="showCropper=false">Cancel</button>
    <button type="button" class="btn ignorBTN pull-right margin-left-10 secondary-btn text-color-white withInORGbtn" (click)="uploadCroppedImage()">Upload</button>
  </footer>
</div>
