import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { messages } from 'src/app/constants/messages';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { _ } from 'underscore';
import { EmailNotificationSettings } from 'src/app/models/emailSettings/emailSettings';
import { CompanySettings } from 'src/app/models/company-settings';
import { CompanyUser } from 'src/app/models/companyusermodel/companyuser';
import { AccountType } from 'src/app/models/enum/account-type';
import { Role } from 'src/app/models/enum/role.enum';
import { UserPrivacySettings } from 'src/app/models/user-privacy-settings';
import * as CryptoJS from 'crypto-js';
import { sessionStorageKeyConstants } from 'src/app/constants/sessionStorageKeyConstants';
import { GlobalContentService } from 'src/app/services/global/global-content.service';
import { NotificationSetting } from 'src/app/models/notificationSettings/notification-settings';
import * as commonFunctions from 'src/app/helpers/commonFunctions';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(private masterService: MasterService, private router: Router, private globalService: GlobalContentService) { }
  companySettings: CompanySettings = new CompanySettings();
  isCompanySettingEditable: boolean;
  isSettingsSubmitted: boolean;
  isEmailDistributionListValid: boolean = true;
  emailNotificationSettings: EmailNotificationSettings = new EmailNotificationSettings();
  userPrivacySettings: UserPrivacySettings = new UserPrivacySettings();
  isValid: boolean;
  loggedInCompanyUser: any;
  hasAdminPrivilege: boolean;
  isPersonalAccount: boolean;
  isOnlyInterviewer: boolean;
  isAtsEnabled: boolean;
  isPersonalAccountReadOnly: boolean;
  appConfigData;
  notificationSettings: NotificationSetting = new NotificationSetting();
  notificationSettingsCopy: string;
  ngOnInit() {
    this.appConfigData = require("appsettings.json");
    this.loggedInCompanyUser = this.globalService.getLoggedInUserData();
    this.isAtsEnabled = this.loggedInCompanyUser.user.isAtsEnabled;
    if (this.loggedInCompanyUser != null) {
      this.isPersonalAccount = this.loggedInCompanyUser.user.accountType == AccountType.FreeLancer || this.loggedInCompanyUser.user.accountType == AccountType.HiringManager;
      this.isOnlyInterviewer = (this.loggedInCompanyUser.companyUserRole.roleId == Role.Interviewer) ? true : false;
      this.hasAdminPrivilege = this.loggedInCompanyUser.hasAdminPrivilege;
      this.isPersonalAccountReadOnly = this.loggedInCompanyUser.user.isPersonalAccountReadOnly;
    }
    if (!this.isPersonalAccount && this.hasAdminPrivilege)
      this.getCompanySettings();
    if (this.isAtsEnabled)
      this.getEmailNotificationSettings();
    this.getNotificationSettings();
  }

  getCompanySettings() {
    this.masterService.getCompanySettings().subscribe((companySettings: any) => {
      if (commonFunctions.isValidObject(companySettings) && commonFunctions.isValidObject(companySettings.emailDistributionList)) {
        this.companySettings = companySettings;
        let encryptedCompanySettings = CryptoJS.AES.encrypt(JSON.stringify(companySettings), this.appConfigData.companySettingsEncryptionKey);
        sessionStorage.setItem(sessionStorageKeyConstants.companySettings, encryptedCompanySettings);
      }
    }, (err) => {
      Swal.fire({ title: messages.SOME_THING_WENT_WRONG, type: 'warning', showConfirmButton: true });
    });
  }

  closeCompanySettings() {
    this.isCompanySettingEditable = false;
    this.isSettingsSubmitted = false;
  }

  updateCompanySettings() {
    if (this.isEmailDistributionListValid) {
      this.masterService.updateCompanySettings(this.companySettings).subscribe((isSuccess) => {
        if (isSuccess) {
          let companySettings = CryptoJS.AES.encrypt(JSON.stringify(this.companySettings), this.appConfigData.companySettingsEncryptionKey);
          sessionStorage.setItem(sessionStorageKeyConstants.companySettings, companySettings);
          this.closeCompanySettings();
        }
      }, (err) => {
        Swal.fire({ title: messages.SOME_THING_WENT_WRONG, type: 'warning', showConfirmButton: true });
      });
    }
    else {
      Swal.fire({ title: messages.INVALID_EMAIL_WARNING_MESSAGE, type: 'warning', showConfirmButton: true });
    }
  }

  validateEmailDistributionList(emailId) {
    this.isEmailDistributionListValid = (emailId !== null && !messages.EMAILID_REGEX.test(emailId) && emailId != undefined && emailId != "") ? false : true;
  }

  validateCompanySettings() {
    this.isSettingsSubmitted = true;
    if (!_.isNull(this.companySettings.emailDistributionList) && (!_.isUndefined(this.companySettings.emailDistributionList)) && this.companySettings.emailDistributionList !== "") {
      this.updateCompanySettings();
    }
    else {
      Swal.fire({ title: messages.ENTER_THE_EMAILDISTRIBUTION, type: 'warning', showConfirmButton: true });
    }
  }

  getEmailNotificationSettings() {
    this.masterService.getEmailNotificationSettings().subscribe((settings: EmailNotificationSettings) => {
      this.emailNotificationSettings = settings == null ? new EmailNotificationSettings() : settings;
    });
  }

  updateEmailNotificationSettings() {
    this.masterService.updateEmailNotificationSettings(this.emailNotificationSettings).subscribe((isSuccess: boolean) => {

    });
  }

  cancelEmailDistributionEdit() {
    this.isCompanySettingEditable = false;
    this.companySettings = sessionStorage.getItem("companySettings") == null ? new CompanySettings() : JSON.parse(sessionStorage.getItem("companySettings"));
  }
  editCompanysettings() {
    this.isCompanySettingEditable = true
    this.isEmailDistributionListValid = true;
  }

  getNotificationSettings() {
    this.masterService.getNotificationSetting().subscribe((settings: NotificationSetting) => {
      this.notificationSettings = settings;
      this.notificationSettingsCopy = JSON.stringify(settings);
    });
  }

  updateNotificationSettings() {
    this.masterService.updateNotificationSetting(this.notificationSettings).subscribe((isSuccess: boolean) => {
      this.notificationSettingsCopy = JSON.stringify(this.notificationSettings);
    }, (err) => {
      this.notificationSettings = JSON.parse(this.notificationSettingsCopy);
      Swal.fire({ title: messages.NOTIFICATION_ERROR, type: 'error', showConfirmButton: true });
    });
  }
}
