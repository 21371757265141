import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input, OnChanges } from '@angular/core';
import { SocialUser } from "angularx-social-login";
import { GlobalContentService } from 'src/app/services/global/global-content.service';
import { Router } from '@angular/router';
import { SearchconnectionComponent } from './searchconnection/searchconnection.component';
import { MasterService } from 'src/app/services/master/master.service';
import { LoginService } from 'src/app/services/login.service';
import { Notification } from 'src/app/models/notificationmodel/notification';
import { SignalrService } from 'src/app/services/signalR/signalr.service';
import Swal from 'sweetalert2';
import { messages } from 'src/app/constants/messages';
import { JobActivityTypeEnum } from 'src/app/models/jobActivityTypeEnum/job-activity-type-enum.enum';
import { CandidateActivityTypeEnum } from 'src/app/models/candidateActivityTypeEnum/candidate-activity-type-enum';
import { User } from 'src/app/models/usermodel/user';
import { Company } from 'src/app/models/companymodel/company';
import * as CryptoJS from 'crypto-js';
import { DatePipe } from '@angular/common';
import { ChatAdapter } from 'src/app/components/chat-component/ng-chat/core/chat-adapter';
import { IChatController } from 'src/app/components/chat-component/ng-chat/core/chat-controller';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import * as angular from 'angular';
import { ChatNotificationModel } from 'src/app/models/chatNotoficationModel/chat-notification-model';
import * as commonFunctions from 'src/app/helpers/commonFunctions';
import { NotificationType } from 'src/app/models/enum/notification-type.enum';
import { _ } from 'underscore';
import { SearchService } from 'src/app/services/search.service';
import { AccountType } from 'src/app/models/enum/account-type';
import { CompanySettings } from 'src/app/models/company-settings';
import { Role } from 'src/app/models/enum/role.enum';
import { sessionStorageKeyConstants } from 'src/app/constants/sessionStorageKeyConstants';
import { NetworkService } from 'src/app/services/network/network.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  public adapter: ChatAdapter;
  public loggedInUser: SocialUser;
  @ViewChild('userChatInstance')
  protected userChatInstance: IChatController;
  @ViewChild('companyContact') companyContact: ElementRef;
  @ViewChild('userContact') userContact: ElementRef;
  @ViewChild('companyContactNumber') companyContactNumber: ElementRef;
  @Input() selectedNavigationTab: string;
  notifications: Notification[] = [];
  notificationsCopy: Notification[] = [];
  discussionNotifications: Notification[];
  notificationUnReadCount: number;
  discussionNotificationUnReadCount: number;
  companyId: number;
  appConfigData: any;
  companyUserId: number;
  loggedInCompany: string;
  orderIsRead: string;
  isPersonalAccount: boolean = false;
  orderOnDate: string;
  isViewSettings: boolean = false;
  selectedTab: number = 0;
  isProfileEditable: boolean = false;
  user = new User();
  userId: number;
  viewProfile: boolean = false;
  formSubmitted: boolean = false;
  validContactNumber: boolean = true;
  userCopy: User;
  loggedInUserFullName: string;
  blobUrl: string;
  title = "Chat (beta)";
  company = new Company();
  companyCopy: Company;
  isCompanyWebsite: boolean = true;
  isCompanyProfileEditable: boolean = false;
  viewCompanyProfile: boolean = false;
  isEmailValid: boolean = true;
  isValidCompanyContact: boolean = true;
  timeZonesList: any = [];
  isCompanyValid: boolean = true;
  isEINValid: boolean = true;
  validUser: boolean;
  isValidCompanyUserContact: boolean = true;
  hasAdminPrivilege: boolean;
  userImage: string;
  companyImage: string;
  isLoggedIn: boolean;
  srcOfProfileImage: string;
  srcOfCompanyImage: string;
  userProfileImage: string;
  viewFeedback: boolean = false;
  companySettings: any;
  showSearchBar: boolean = true;
  isFilterSelected: boolean = false;
  messagesCount: number = 0;
  chatNotifications: ChatNotificationModel[] = [];
  usersConnected: any = [];
  candidateCheck = true;
  jobCheck = true;
  networkCheck = true;
  activeChatDialogIds = [];
  viewMigrate: boolean = false;
  enableMigration: boolean = false;
  notificationType: any;
  isEmailDistributionListValid: boolean = true;
  isCompanySettingEditable: boolean;
  isSettingsSubmitted: boolean;
  isDashboardEnabled: boolean;
  zipcodeRegex = new RegExp("^([A-Za-z0-9-]+[ ]{0,})+$");
  websiteRegex = new RegExp(/^((https?|ftp|smtp):\/\/)?(www.)?[-a-zA-Z0-9]+\.([-a-zA-Z0-9]{1,}\.)?[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/); //PR 3428: to validate wedsite field.
  loggedInCompanyUser: any;
  that = this;
  profilePhotoOptions: any;
  companyPhotoOptions: any;
  myObserver = null;
  isJobsTabActive: boolean = false;
  isCandidateTabActive: boolean = false;
  isManagementTabActive: boolean = false;
  isReportsTabActive: boolean = false;
  isAtsEnabled: boolean;
  isChatEnabled: boolean;
  isValidZipcode: boolean = true;
  locations: Location[] = [];
  isLocationValid: boolean = true;
  location: string = "";
  showNotificationBadge: boolean = true;
  showChatNotificationBadge: boolean = true;
  isAccountManager: boolean = false;
  selectedFilter: string = "All";
  isTeamManagementVisible: boolean = false;
  filterOptions = [{ name: "All", value: '0', image: "../../../assets/IMG/all.png", notificationCount: 0, isHiringManager: false },
  { name: "Jobs", value: '1', image: "../../../assets/IMG/suitcase (1).png", notificationCount: 0, isHiringManager: false },
  { name: "Candidates", value: '2', image: "../../../assets/IMG/candidates pool.png", notificationCount: 0, isHiringManager: true },
  { name: "Connections", value: '3', image: "../../../assets/IMG/connections.png", notificationCount: 0, isHiringManager: false },
  { name: "Discussions", value: '4', image: "../../../assets/IMG/discussions.png", notificationCount: 0, isHiringManager: false },
  { name: "Network Jobs", value: '5', image: "../../../assets/IMG/network jobs.png", notificationCount: 0, isHiringManager: true },
  { name: "Network Candidates", value: '6', image: "../../../assets/IMG/network candidates.png", notificationCount: 0, isHiringManager: false }];
  discussionNotificationTypes = [NotificationType.JobDiscussion, NotificationType.CandidateDiscussion,
  NotificationType.NetworkCandidateDiscussion, NotificationType.NetworkJobDiscussion]
  filterText = "Filter";
  isReadAllEnable: boolean = true;
  isClearAllEnable: boolean = true;
  private getNotificationSubscription: any;
  private newMessageReceivedSubscription: any;
  private receiveUserNotificationSubscription: any;
  private receiveCompanyUserNotificationSubscription: any;
  private receiveCompanyNotificationSubscription: any;
  private popChatNotificationsForDisconnectedToUserSubscription: any;
  private deleteNotificationSubscription: any;
  private updateChatMessageNotificationsSubscription: any;
  private popChatNotificationsForDisconnectedFromUserSubscription: any;
  isOnlyInterviewer: boolean;
  isHiringManager: boolean;
  constructor(
    private globalContentService: GlobalContentService,
    private router: Router,
    private signalrService: SignalrService,
    private masterService: MasterService,
    private loginService: LoginService,
    private dashboardService: DashboardService,
    private searchService: SearchService,
    private networkService: NetworkService) {
    this.appConfigData = require("appsettings.json");
    this.loggedInCompanyUser = this.globalContentService.getLoggedInUserData();
    this.profilePhotoOptions = {
      categoryId: parseInt(this.loggedInCompanyUser == null ? "0" : this.loggedInCompanyUser.userId == null ? "0" : this.loggedInCompanyUser.userId),
      attachmentType: "UserProfilePhoto",
      blobName: '',
      originalName: '',
      maxFileSize: 5,
      permittedFiles: '.jpg,.jpeg,.png',
      fileId: 0,
      category: "User",
      placeHolder: "user profile photo"
    }
    this.companyPhotoOptions = {
      categoryId: parseInt(this.loggedInCompanyUser == null ? "0" : this.loggedInCompanyUser.companyId == null ? "0" : this.loggedInCompanyUser.companyId),
      attachmentType: "CompanyProfilePhoto",
      blobName: '',
      originalName: '',
      maxFileSize: 5,
      permittedFiles: '.jpg,.jpeg,.png',
      fileId: 0,
      category: "Company",
      placeHolder: "company profile photo"
    }
    this.getNotificationSubscription = this.signalrService.getNotification$.subscribe(
      () => {
        setTimeout(() => {
          this.getNotifications();
        }, 500);
      }
    );

    this.newMessageReceivedSubscription = this.signalrService.newMessageReceived$.subscribe(
      (message: any) => {
        if (message.toId === this.userId) {
          setTimeout(() => {
            let usersConnectedList = sessionStorage.getItem(sessionStorageKeyConstants.UsersConnectedList);
            if (usersConnectedList) {
              var bytes = CryptoJS.AES.decrypt(usersConnectedList, this.appConfigData.chatParticipantsEncryptionKey);
              usersConnectedList = bytes.toString(CryptoJS.enc.Utf8);
            }
            this.usersConnected = JSON.parse(usersConnectedList)
            var datePipe = new DatePipe(message.notificationUTCDate);
            var currentLoggedInUser = this.globalContentService.getLoggedInUserData();
            message.notificationDate = datePipe.transform(message.notificationUTCDate + ' UTC', 'M/d/yy h:mm a', currentLoggedInUser.appTimezoneOffset, window.navigator.language);
            if (this.usersConnected != undefined && this.usersConnected != null && this.usersConnected.length > 0) {
              let user = this.usersConnected.find(x => x.participant.id == message.fromId);
              if (user != null && user != undefined && this.chatNotifications.length > 0) {
                let userNotification = this.chatNotifications.find(x => x.fromId == message.fromId);
                if (userNotification != null && userNotification != undefined) {
                  let NotificationIndex = this.chatNotifications.indexOf(userNotification);
                  this.chatNotifications[NotificationIndex].unreadMessageCount++;
                  this.chatNotifications[NotificationIndex].notificationDate = message.notificationDate;
                  var sortedList = _.sortBy(JSON.parse(JSON.stringify(this.chatNotifications)), function (notification) {
                    return new Date(notification.notificationDate);
                  });
                  this.chatNotifications = sortedList.slice().reverse();
                } else {
                  this.chatNotifications.unshift({
                    fromId: message.fromId,
                    fromName: user.participant.displayName,
                    fromUserBlobImage: message.fromUserBlobImage,
                    dateSent: message.dateSent,
                    isRead: false,
                    unreadMessageCount: 1,
                    notificationDate: message.notificationDate,
                    notificationUTCDate: message.notificationUTCDate
                  });
                }
              }
              else {
                this.chatNotifications.unshift({
                  fromId: message.fromId,
                  fromName: user.participant.displayName,
                  fromUserBlobImage: message.fromUserBlobImage,
                  dateSent: message.dateSent,
                  isRead: false,
                  unreadMessageCount: 1,
                  notificationDate: message.notificationDate,
                  notificationUTCDate: message.notificationUTCDate
                });
              }
            }
            this.showChatNotificationBadge = true;
            sessionStorage.setItem("chatMessagesCount", this.chatNotifications.length.toString());
            sessionStorage.setItem("showChatNotificationBadge", JSON.stringify(this.showChatNotificationBadge));
          }, 500);
        }
      });

    this.isLoggedIn = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser) == null ? false : true;

    this.receiveUserNotificationSubscription = this.signalrService.receiveUserNotification$.subscribe(
      (notification: any) => {
        setTimeout(() => {
          this.receiveUserNotifications(notification);
        }, 500);
      }
    );

    this.receiveCompanyUserNotificationSubscription = this.signalrService.receiveCompanyUserNotification$.subscribe(
      (notification: any) => {
        setTimeout(() => {

          if (notification.companyUserId == this.companyUserId) {
            this.receiveCompanyUserNotifications(notification.notification);
          }

        }, 500);
      }
    );

    this.receiveCompanyNotificationSubscription = this.signalrService.receiveCompanyNotification$.subscribe(
      (notification: any) => {
        setTimeout(() => {

          this.receiveCompanyNotifications(notification);

        }, 500);
      }
    );

    this.deleteNotificationSubscription = this.signalrService.deleteNotification$.subscribe((discussionDetails: any) => {
      setTimeout(() => {
        if (discussionDetails.companyUserId == this.companyUserId)
          this.deleteNotification(discussionDetails.notification);
      }, 500);
    });

    this.popChatNotificationsForDisconnectedToUserSubscription = this.signalrService.deleteChatNotification$.subscribe((chatNotificationDetails: any) => {
      setTimeout(() => {
        this.deleteChatNotification(chatNotificationDetails.disconnectedFromUserId);
      }, 500);
    });
    this.updateChatMessageNotificationsSubscription = this.dashboardService.updateChatMessageNotifications$.subscribe((userId: any) => {
      setTimeout(() => {
        this.chatNotifications = this.chatNotifications.filter(notification => notification.fromId != userId);
        var newMessagesCount = this.chatNotifications.length;
        var messagesCount = sessionStorage.getItem("chatMessagesCount") == null ? 0 : +sessionStorage.getItem("chatMessagesCount");
        if (newMessagesCount > messagesCount) {
          this.showChatNotificationBadge = true;
        }
        sessionStorage.setItem("chatMessagesCount", newMessagesCount.toString());
        sessionStorage.setItem("showChatNotificationBadge", JSON.stringify(this.showChatNotificationBadge));
      }, 500);
    });

    this.popChatNotificationsForDisconnectedFromUserSubscription = this.networkService.popChatNotifications$.subscribe((chatNotification: any) => {
      setTimeout(() => {
        this.chatNotifications = this.chatNotifications.filter(item => (item.fromId !== chatNotification.disconnectedUserId));
        sessionStorage.setItem("chatMessagesCount", this.chatNotifications.length.toString());
      }, 500);
    });
  }
  ngOnChanges() {
    if (this.selectedNavigationTab) {
      this.getCurrentActiveTab();
    }
  }

  ngOnInit() {
    this.appConfigData = require("appsettings.json");
    this.blobUrl = this.appConfigData.BlobSettings.baseUrl + this.appConfigData.BlobSettings.profilePath;
    if (this.isLoggedIn) {
      this.userId = this.loggedInCompanyUser.userId === null ? 0 : this.loggedInCompanyUser.userId;
      this.orderIsRead = "isRead";
      this.orderOnDate = "performedDate";
      let companySettings = sessionStorage.getItem(sessionStorageKeyConstants.companySettings);
      if (companySettings) {
        var bytes = CryptoJS.AES.decrypt(companySettings, this.appConfigData.companySettingsEncryptionKey);
        companySettings = bytes.toString(CryptoJS.enc.Utf8);
      }
      companySettings = JSON.parse(companySettings);
      this.companySettings = !_.isUndefined(companySettings) && !_.isNull(companySettings) ? companySettings : new CompanySettings();
      this.discussionNotifications = [];
      this.discussionNotificationUnReadCount = 0;
      this.companyId = this.loggedInCompanyUser.companyId === null ? 0 : this.loggedInCompanyUser.companyId;
      this.isPersonalAccount = this.loggedInCompanyUser.user.accountType == AccountType.FreeLancer || this.loggedInCompanyUser.user.accountType == AccountType.HiringManager;
      this.loggedInCompany = this.loggedInCompanyUser.company.company;
      this.loggedInUserFullName = this.loggedInCompanyUser.companyUser;
      this.companyUserId = this.loggedInCompanyUser.companyUserId === null ? 0 : this.loggedInCompanyUser.companyUserId;
      this.hasAdminPrivilege = this.loggedInCompanyUser.hasAdminPrivilege;
      this.userProfileImage = this.loggedInCompanyUser.userImageBlobName;
      this.userImage = this.blobUrl + this.userProfileImage;
      this.companyImage = this.loggedInCompanyUser.companyImageBlobName;
      this.isAtsEnabled = this.loggedInCompanyUser.user.isAtsEnabled;
      this.isChatEnabled = this.appConfigData.EnableChat;
      this.enableMigration = this.appConfigData.EnableMigration;
      this.notificationType = NotificationType;
      this.signalrService.createConnection();
      this.showNotificationBadge = JSON.parse(sessionStorage.getItem("showNotificationBadge")) == null ? true : JSON.parse(sessionStorage.getItem("showNotificationBadge"));
      this.showChatNotificationBadge = JSON.parse(sessionStorage.getItem("showChatNotificationBadge")) == null ? true : JSON.parse(sessionStorage.getItem("showChatNotificationBadge"));
      this.getNotifications();
      this.getMessageNotifications();
      let usersConnectedList = sessionStorage.getItem(sessionStorageKeyConstants.UsersConnectedList);
      if (usersConnectedList) {
        var bytes = CryptoJS.AES.decrypt(usersConnectedList, this.appConfigData.chatParticipantsEncryptionKey);
        usersConnectedList = bytes.toString(CryptoJS.enc.Utf8);
      }
      this.usersConnected = JSON.parse(usersConnectedList);
      this.isDashboardEnabled = this.appConfigData.EnableDashboard;
      this.isAccountManager = (this.loggedInCompanyUser.companyUserRole.roleId == Role.AccountManager);
      this.isOnlyInterviewer = (this.loggedInCompanyUser.companyUserRole.roleId == Role.Interviewer);
      this.isHiringManager = (this.loggedInCompanyUser.companyUserRole.roleId == Role.HiringManager);
      this.selectedFilter = sessionStorage.getItem("selectedFiltervalue");
      if (this.isHiringManager) {
        this.filterOptions = this.filterOptions.filter(x => x.isHiringManager == false);
      }
      this.filterNotifications(this.selectedFilter);
    }
    if ((this.loggedInCompanyUser.hasAdminPrivilege == true) && !this.isPersonalAccount) {
      this.isTeamManagementVisible = true;
    }
  }

  setDefaultValues() {
    this.validContactNumber = true;
    this.isValidCompanyContact = true;
    this.isValidCompanyUserContact = true;
    this.isEINValid = true;
    this.isCompanyWebsite = true;
    this.isEmailValid = true;
    this.isValidZipcode = true;
  }

  logOut(): void {
    this.router.navigate(["/login"]).then(async (data) => {
      if (data) {
        await sessionStorage.clear();
        this.globalContentService.setLoggedInUser(null);
        this.loginService.unSubscribeUserFromSignalR(this.userId).subscribe((data: any) => {
          this.signalrService.stopConnection();
        });
      }
    });
  }

  clearSearch() {
    sessionStorage.removeItem('connectionId');
    sessionStorage.removeItem("PageNumber");
    sessionStorage.removeItem("jobsPageNumber");
  }

  clearJobDetails(isClearJob: boolean) {
    sessionStorage.removeItem('connectionId');
    if (isClearJob) {
      sessionStorage.removeItem('jobActiveTab');
      sessionStorage.removeItem('isJobDetails');
    }
  }

  clearCandidateTab(navigatedFromCandidates: boolean) {
    sessionStorage.removeItem('connectionId');
    sessionStorage.removeItem('activeTab');
  }

  clearDashboardTab(isFromDashboard) {
    sessionStorage.removeItem('connectionId');
    if (isFromDashboard) {
      sessionStorage.removeItem('isFromDashboard');
    }
  }

  getNotifications() {
    this.masterService.getNotifications().subscribe((data: Notification[]) => {
      this.notifications = data;
      this.notificationsCopy = JSON.parse(JSON.stringify(data));
      this.notificationUnReadCount = this.notifications.filter(notification => (notification.isRead == false)).length;
      this.assigneFiltersAndFilterCount(true);
      var unReadNotifiactions = sessionStorage.getItem(sessionStorageKeyConstants.notificationUnReadCount) == null ? 0 : +sessionStorage.getItem(sessionStorageKeyConstants.notificationUnReadCount);
      if (this.notificationUnReadCount > unReadNotifiactions) {
        this.showNotificationBadge = true;
      }
      sessionStorage.setItem(sessionStorageKeyConstants.notificationUnReadCount, this.notificationUnReadCount.toString());
    });
  }

  assigneFiltersAndFilterCount(hasData?: boolean, isScrollStop?) {
    this.filterOptions[0].notificationCount = hasData ? this.notificationsCopy.filter(notification => (notification.isRead == false)).length : 0;
    this.filterOptions[1].notificationCount = hasData ? this.notificationsCopy.filter(notification => (notification.isRead == false && notification.notificationTypeId == NotificationType.Job)).length : 0;
    this.filterOptions[2].notificationCount = hasData ? (this.isHiringManager ? (this.notificationsCopy.filter(notification => (notification.isRead == false && notification.notificationTypeId == NotificationType.Connection)).length) :
      (this.notificationsCopy.filter(notification => (notification.isRead == false && notification.notificationTypeId == NotificationType.Candidate)).length)) : 0;
    this.filterOptions[3].notificationCount = hasData ? (this.isHiringManager ? (this.notificationsCopy.filter(notification => (notification.isRead == false && (notification.notificationTypeId == NotificationType.CandidateDiscussion || notification.notificationTypeId == NotificationType.JobDiscussion || notification.notificationTypeId == NotificationType.NetworkJobDiscussion || notification.notificationTypeId == NotificationType.NetworkCandidateDiscussion))).length) :
      (this.notificationsCopy.filter(notification => (notification.isRead == false && notification.notificationTypeId == NotificationType.Connection)).length)) : 0;
    this.filterOptions[4].notificationCount = hasData ? (this.isHiringManager ? (this.notificationsCopy.filter(notification => (notification.isRead == false && notification.notificationTypeId == NotificationType.NetworkCandidate)).length) :
      (this.notificationsCopy.filter(notification => (notification.isRead == false && (notification.notificationTypeId == NotificationType.CandidateDiscussion || notification.notificationTypeId == NotificationType.JobDiscussion || notification.notificationTypeId == NotificationType.NetworkJobDiscussion || notification.notificationTypeId == NotificationType.NetworkCandidateDiscussion))).length)) : 0;
    if (!this.isHiringManager) {
      this.filterOptions[5].notificationCount = hasData ? this.notificationsCopy.filter(notification => (notification.isRead == false && notification.notificationTypeId == NotificationType.NetworkJob)).length : 0;
      this.filterOptions[6].notificationCount = hasData ? this.notificationsCopy.filter(notification => (notification.isRead == false && notification.notificationTypeId == NotificationType.NetworkCandidate)).length : 0;
    }
    this.filterNotifications(this.selectedFilter, isScrollStop);
  }

  deleteDiscussions(discussion) {
    this.discussionNotifications = this.discussionNotifications.filter(item => (item.jobDiscussionId !== discussion.discussionId));
    this.discussionNotifications = this.discussionNotifications.filter(item => (item.candidateDiscussionId !== discussion.discussionId));
    this.discussionNotificationUnReadCount = this.discussionNotifications.filter(notification => (notification.isRead == false)).length;
  }

  markAllasRead(isDiscussionNotification: boolean) {
    var isReadAll = false;
    var message = isDiscussionNotification ? messages.READ_ALL_DISCUSSIONS_WARNING_MESSAGE : messages.READ_ALL_MESSSAGES_WARNING_MESSAGE;
    if (isDiscussionNotification) {
      if (this.discussionNotificationUnReadCount > 0) {
        isReadAll = true;
      }
    } else {
      if (this.filterOptions.filter(x => x.name == this.selectedFilter)[0].notificationCount > 0) {
        isReadAll = true;
      }
    }

    if (isReadAll)
      if (isReadAll) {
        let notification = new Notification();
        notification.isReadAll = true;
        notification.isDiscussionNotification = isDiscussionNotification;
        notification.filterType = this.selectedFilter;
        let _this = this;
        Swal.fire({
          title: message,
          text: '',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        }).then(function (result) {
          if (result.value) {
            _this.masterService.updateNotificationStatus(notification).subscribe((isSuccess: any) => {
              if (isSuccess === true) {
                if (isDiscussionNotification) {
                  _this.discussionNotificationUnReadCount = 0;
                  _this.discussionNotifications.forEach(function (notification) {
                    notification.isRead = true;
                  });
                }
                else {


                  _this.notifications.forEach(function (notification) {
                    notification.isRead = true;
                  });
                  _this.updateNotificationsCopy();
                  _this.notificationUnReadCount = _this.notificationsCopy.filter(notification => (notification.isRead == false)).length;
                }
              }

            });
          }
        });
      }

  }

  updateNotificationsCopy() {
    this.notifications.forEach((item: Notification) => {
      let notificationIndex = this.notificationsCopy.findIndex(x => x.notificationId == item.notificationId);
      if (notificationIndex >= 0)
        this.notificationsCopy[notificationIndex].isRead = true;
    });
    this.assigneFiltersAndFilterCount(true);
  }

  navigateFromNotification(notification: Notification, index: number, event) {
    if (event.target.closest('.markAsRead')) {
      return;
    }
    if (index >= 0) {
      this.notifications[index].isRead = true;
      if (this.isFilterSelected) {
        var notificationIndex = this.notificationsCopy.findIndex(x => x.notificationId == notification.notificationId);
        if (notificationIndex >= 0)
          this.notificationsCopy[notificationIndex].isRead = true;
      }
      else {
        this.notificationsCopy[index].isRead = true;
      }
      notification.isRead = true;
      notification.isDiscussionNotification = false;
      this.notificationUnReadCount = this.notifications.filter(notification => (notification.isRead == false)).length;
      this.assigneFiltersAndFilterCount(true);
      sessionStorage.setItem(sessionStorageKeyConstants.notificationUnReadCount, this.notificationUnReadCount.toString());
      this.masterService.updateNotificationStatus(notification).subscribe((data: any) => {
      });

      if (notification.notificationTypeId == NotificationType.Connection) {
        this.router.navigate(["Network"]);
      }
      else if (notification.notificationTypeId == NotificationType.Job || notification.notificationTypeId == NotificationType.JobDiscussion) {
        this.navigateNotificationToJob(notification.jobId, notification.connectionId);
      }
      else if (notification.notificationTypeId == NotificationType.NetworkJob || notification.notificationTypeId == NotificationType.NetworkJobDiscussion) {
        this.navigateNotificationToNetworkJob(notification.jobId, notification.networkJobId, notification.connectionId);
      }
      else if (notification.notificationTypeId == NotificationType.Candidate || notification.notificationTypeId == NotificationType.CandidateDiscussion) {
        this.navigateNotificationToCandidate(notification.candidateId, notification.connectionId);
      }
      else if (notification.notificationTypeId == NotificationType.NetworkCandidate || notification.notificationTypeId == NotificationType.NetworkCandidateDiscussion) {
        this.navigateNotificationToNetworkCandidate(notification.candidateId, notification.networkCandidateId, notification.connectionId);
      }
    }
  }

  navigateNotificationToJob(jobId: number, connectionId: number) {
    if (jobId != null && jobId != 0) {
      sessionStorage.setItem("jobActiveTab", "0");
      sessionStorage.setItem("jobId", jobId.toString());
      sessionStorage.setItem("networkJobId", "0");
      sessionStorage.setItem("connectionIdThroughNotification", connectionId == null ? "0" : connectionId.toString());
      sessionStorage.setItem("isNetworkJob", "false");

      var cryptoIsNetworkJob = CryptoJS.enc.Utf8.parse("false");
      cryptoIsNetworkJob = CryptoJS.enc.Base64.stringify(cryptoIsNetworkJob);
      cryptoIsNetworkJob = cryptoIsNetworkJob.replace(/[^\w\s]/gi, '');

      var cryptoJobId = CryptoJS.enc.Utf8.parse(jobId.toString());
      cryptoJobId = CryptoJS.enc.Base64.stringify(cryptoJobId);
      cryptoJobId = cryptoJobId.replace(/[^\w\s]/gi, '');

      var cryptoNetworkJobId = CryptoJS.enc.Utf8.parse("0");
      cryptoNetworkJobId = CryptoJS.enc.Base64.stringify(cryptoNetworkJobId);
      cryptoNetworkJobId = cryptoNetworkJobId.replace(/[^\w\s]/gi, '');

      this.router.navigate(["Jobs/OrgJobs/JobDetails", cryptoJobId, cryptoNetworkJobId, cryptoIsNetworkJob]);
    }
  }

  navigateNotificationToNetworkJob(jobId: number, networkJobId: number, connectionId: number) {
    if (jobId != null && jobId != 0 && networkJobId != null && networkJobId != 0) {
      sessionStorage.setItem("jobActiveTab", "0");
      sessionStorage.setItem("jobId", jobId.toString());
      sessionStorage.setItem("networkJobId", networkJobId.toString());
      sessionStorage.setItem("connectionIdThroughNotification", connectionId == null ? "0" : connectionId.toString());
      sessionStorage.setItem("isNetworkJob", "true");

      var cryptoIsNetworkJob = CryptoJS.enc.Utf8.parse("true");
      cryptoIsNetworkJob = CryptoJS.enc.Base64.stringify(cryptoIsNetworkJob);
      cryptoIsNetworkJob = cryptoIsNetworkJob.replace(/[^\w\s]/gi, '');

      var cryptoJobId = CryptoJS.enc.Utf8.parse(jobId.toString());
      cryptoJobId = CryptoJS.enc.Base64.stringify(cryptoJobId);
      cryptoJobId = cryptoJobId.replace(/[^\w\s]/gi, '');

      var cryptoNetworkJobId = CryptoJS.enc.Utf8.parse(networkJobId.toString());
      cryptoNetworkJobId = CryptoJS.enc.Base64.stringify(cryptoNetworkJobId);
      cryptoNetworkJobId = cryptoNetworkJobId.replace(/[^\w\s]/gi, '');

      this.router.navigate(["Jobs/NetworkJobs/JobDetails", cryptoJobId, cryptoNetworkJobId, cryptoIsNetworkJob]);
    }
  }

  navigateNotificationToCandidate(candidateId: number, connectionId: number) {
    if (candidateId != null && candidateId != 0) {
      sessionStorage.setItem("activeTab", "0");
      sessionStorage.setItem("candidateId", candidateId.toString());
      sessionStorage.setItem("networkCandidateId", "0");
      sessionStorage.setItem("connectionIdThroughNotification", connectionId == null ? "0" : connectionId.toString());
      sessionStorage.setItem("isNetworkCandidate", "false");

      var cryptoIsNetworkCandidate = CryptoJS.enc.Utf8.parse("false");
      cryptoIsNetworkCandidate = CryptoJS.enc.Base64.stringify(cryptoIsNetworkCandidate);
      cryptoIsNetworkCandidate = cryptoIsNetworkCandidate.replace(/[^\w\s]/gi, '');

      var cryptoIsNetworkSubmittedCandidate = CryptoJS.enc.Utf8.parse("false");
      cryptoIsNetworkSubmittedCandidate = CryptoJS.enc.Base64.stringify(cryptoIsNetworkSubmittedCandidate);
      cryptoIsNetworkSubmittedCandidate = cryptoIsNetworkSubmittedCandidate.replace(/[^\w\s]/gi, '');

      var cryptoCandidateId = CryptoJS.enc.Utf8.parse(candidateId.toString());
      cryptoCandidateId = CryptoJS.enc.Base64.stringify(cryptoCandidateId);
      cryptoCandidateId = cryptoCandidateId.replace(/[^\w\s]/gi, '');

      var cryptoNetworkCandidateId = CryptoJS.enc.Utf8.parse("0");
      cryptoNetworkCandidateId = CryptoJS.enc.Base64.stringify(cryptoNetworkCandidateId);
      cryptoNetworkCandidateId = cryptoNetworkCandidateId.replace(/[^\w\s]/gi, '');

      this.router.navigate(["Candidates/CandidatePool/CandidateDetails", cryptoCandidateId, cryptoNetworkCandidateId, cryptoIsNetworkCandidate, cryptoIsNetworkSubmittedCandidate]);
    }
  }

  navigateNotificationToNetworkCandidate(candidateId: number, networkCandidateId: number, connectionId: number) {
    if (candidateId != null && candidateId != 0 && networkCandidateId != null && networkCandidateId != 0) {
      sessionStorage.setItem("activeTab", "0");
      sessionStorage.setItem("candidateId", candidateId.toString());
      sessionStorage.setItem("networkCandidateId", networkCandidateId.toString());
      sessionStorage.setItem("connectionIdThroughNotification", connectionId == null ? "0" : connectionId.toString());
      sessionStorage.setItem("isNetworkCandidate", "true");

      var cryptoIsNetworkCandidate = CryptoJS.enc.Utf8.parse("true");
      cryptoIsNetworkCandidate = CryptoJS.enc.Base64.stringify(cryptoIsNetworkCandidate);
      cryptoIsNetworkCandidate = cryptoIsNetworkCandidate.replace(/[^\w\s]/gi, '');

      var cryptoIsNetworkSubmittedCandidate = CryptoJS.enc.Utf8.parse("false");
      cryptoIsNetworkSubmittedCandidate = CryptoJS.enc.Base64.stringify(cryptoIsNetworkSubmittedCandidate);
      cryptoIsNetworkSubmittedCandidate = cryptoIsNetworkSubmittedCandidate.replace(/[^\w\s]/gi, '');

      var cryptoCandidateId = CryptoJS.enc.Utf8.parse(candidateId.toString());
      cryptoCandidateId = CryptoJS.enc.Base64.stringify(cryptoCandidateId);
      cryptoCandidateId = cryptoCandidateId.replace(/[^\w\s]/gi, '');

      var cryptoNetworkCandidateId = CryptoJS.enc.Utf8.parse(networkCandidateId.toString());
      cryptoNetworkCandidateId = CryptoJS.enc.Base64.stringify(cryptoNetworkCandidateId);
      cryptoNetworkCandidateId = cryptoNetworkCandidateId.replace(/[^\w\s]/gi, '');

      this.router.navigate(["Candidates/NetworkCandidates/CandidateDetails", cryptoCandidateId, cryptoNetworkCandidateId, cryptoIsNetworkCandidate, cryptoIsNetworkSubmittedCandidate]);
    }
  }


  clearAllNotifications() {
    var isClear = false;
    var message = messages.CLEAR_ALL_NOTIFICATIONS_WARNING_MESSAGE;
    if (this.notifications.length > 0) {
      isClear = true;
    }
    if (isClear) {
      let notification = new Notification();
      notification.isClearedAll = true;
      notification.filterType = this.selectedFilter;
      let _this = this;
      Swal.fire({
        title: message,
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      }).then(function (result) {
        if (result.value) {
          _this.masterService.updateNotificationStatus(notification).subscribe((isSuccess: any) => {
            if (isSuccess === true) {
              _this.removeClearedNotifications();
              _this.notifications = [];
              _this.assigneFiltersAndFilterCount(true);
              _this.notificationUnReadCount = _this.notificationsCopy.filter(notification => (notification.isRead == false)).length;
            }
          });
        }
      });
    }
  }

  removeClearedNotifications() {
    this.notifications.forEach((item) => {
      const removedNotificationIndex = this.notificationsCopy.findIndex(x => x.notificationId == item.notificationId);
      if (removedNotificationIndex >= 0)
        this.notificationsCopy.splice(removedNotificationIndex, 1);
    });
  }

  markNotificationAsRead(notification: Notification, index: number) {
    if (index >= 0) {
      this.notifications[index].isRead = true;
      if (this.isFilterSelected) {
        var notificationIndex = this.notificationsCopy.findIndex(x => x.notificationId == notification.notificationId);
        if (notificationIndex >= 0)
          this.notificationsCopy[notificationIndex].isRead = true;
      }
      else {
        this.notificationsCopy[index].isRead = true;
      }
      notification.isRead = true;
      this.notificationUnReadCount = this.notificationsCopy.filter(notification => (notification.isRead == false)).length;
      this.assigneFiltersAndFilterCount(true, false);
      sessionStorage.setItem(sessionStorageKeyConstants.notificationUnReadCount, this.notificationUnReadCount.toString());
      this.masterService.updateNotificationStatus(notification).subscribe((isSuccess: any) => {
        if (isSuccess === true) {

        }
      });
    }
  }

  clearNotification(notification: Notification, index: number) {
    notification.isCleared = true;
    var message = messages.CLEAR_NOTIFICATION_WARNING_MESSAGE;
    this.masterService.updateNotificationStatus(notification).subscribe((isSuccess: any) => {
      if (isSuccess === true) {
        this.notifications.splice(index, 1);
        if (this.isFilterSelected) {
          var notificationIndex = this.notificationsCopy.findIndex(x => x.notificationId == notification.notificationId);
          if (notificationIndex >= 0)
            this.notificationsCopy.splice(notificationIndex, 1);
        }
        else {
          this.notificationsCopy.splice(index, 1);
        }
        this.notificationUnReadCount = this.notificationsCopy.filter(notification => (notification.isRead == false)).length;
        this.assigneFiltersAndFilterCount(true, false);
        sessionStorage.setItem(sessionStorageKeyConstants.notificationUnReadCount, this.notificationUnReadCount.toString());
      }
    });

  }

  editProfile() {
    this.isProfileEditable = true;
    this.userCopy = JSON.parse(JSON.stringify(this.user));
    this.setDefaultValues();
  }

  validateUser(user) {
    this.formSubmitted = true;
    user.personalContactNumber = this.userContact.nativeElement.value;
    user.companyUserContactNumber = this.companyContact.nativeElement.value;
    if (angular.isDefined(user.firstName) && angular.isDefined(user.lastName) && user.firstName != "" && user.lastName !== "" && user.personalContactNumber != "" && angular.isDefined(user.personalContactNumber) && angular.isDefined(user.workTitle) && user.workTitle != "" && angular.isDefined(user.workCompany) && user.workCompany != ""
      && angular.isDefined(user.zipCode) && user.zipCode != "" && user.zipCode != null && user.companyUserContactNumber != "" && angular.isDefined(user.companyUserContactNumber) && !_.isUndefined(user.companyUserEmailId) && !_.isNull(user.companyUserEmailId) && !_.isUndefined(user.userLocation.name) && !_.isNull(user.userLocation.name) && (!_.isEmpty(user.userLocation.name))) {
      if (angular.isDefined(user.personalContactNumber)) {
        if (this.validContactNumber && this.isValidCompanyUserContact) {
          this.validUser = true;
        }
        else {
          this.validUser = false;
          Swal.fire({ title: messages.INVALID_CONTACT_WARNING_MESSAGE, type: 'warning', showConfirmButton: true });
        }
      }
      if (!_.isUndefined(user.zipCode) && this.validUser) {
        if (this.isValidZipcode) {
          this.validUser = true;
        }
        else {
          this.validUser = false;
          Swal.fire({ title: messages.ENTER_CORRECT_ZIPCODE, type: 'warning', showConfirmButton: true });
        }
      }

      if (!_.isUndefined(user.companyUserEmailId) && this.validUser) {
        if (this.isEmailValid && this.isEmailValid) {
          this.validUser = true;
        }
        else {
          this.validUser = false;
          Swal.fire({ title: messages.INVALID_EMAIL_WARNING_MESSAGE, type: 'warning', showConfirmButton: true });
        }
      }
      if (this.validUser) {
        this.updateUserProfile(user);
      }

    }
    else {
      if (_.isUndefined(user.userLocation.name) || _.isNull(user.userLocation.name) || _.isEmpty(user.userLocation.name))
        this.isLocationValid = false;
      Swal.fire({ title: messages.ENTER_ALL_DETAILS, type: 'warning', showConfirmButton: true });
    }
  }

  validatePersonalProfile(user) {
    this.formSubmitted = true;
    user.personalContactNumber = this.userContact.nativeElement.value;
    if (angular.isDefined(user.firstName) && angular.isDefined(user.lastName) && user.firstName != "" && user.lastName !== "" && user.personalContactNumber != "" && angular.isDefined(user.personalContactNumber) && angular.isDefined(user.workTitle) && user.workTitle != "" && angular.isDefined(user.workCompany) && user.workCompany != ""
      && angular.isDefined(user.zipCode) && user.zipCode != "" && user.zipCode != null && user.timeZone !== "" && angular.isDefined(user.timeZone) && !_.isUndefined(user.userLocation) && !_.isNull(user.userLocation) && (!_.isEmpty(user.userLocation))) {  //PR 3387:added time zone

      if (!_.isUndefined(user.personalContactNumber)) {
        if (this.validContactNumber) {
          this.validUser = true;
        }
        else {
          this.validUser = false;
          Swal.fire({ title: messages.INVALID_CONTACT_WARNING_MESSAGE, type: 'warning', showConfirmButton: true });
        }
      }
      if (!_.isUndefined(user.zipCode) && this.validUser) {
        if (this.isValidZipcode) {
          this.validUser = true;
        }
        else {
          this.validUser = false;
          Swal.fire({ title: messages.ENTER_CORRECT_ZIPCODE, type: 'warning', showConfirmButton: true });
        }
      }

      if (this.validUser) {
        this.updateUserProfile(user);
      }

    }
    else {
      if (_.isUndefined(user.userLocation) || _.isNull(user.userLocation) || _.isEmpty(user.userLocation))
        this.isLocationValid = false;
      Swal.fire({ title: messages.ENTER_ALL_DETAILS, type: 'warning', showConfirmButton: true });
    }
  }

  updateUserProfile(user) {
    if (user.gender == null) {
      user.gender = "";
    }
    this.masterService.updateUserProfile(user).subscribe((data: any) => {
      setTimeout(() => {
        if (data.item1) {
          Swal.fire({ title: "Profile updated", type: 'success', showConfirmButton: false, timer: 3000 });
          this.viewProfile = false;
          this.formSubmitted = false;
          this.loggedInUserFullName = user.firstName + ' ' + user.lastName;
          this.updateStorage();
          this.user.userProfileCompletionPercentage = data.item2;
          this.userCopy.userProfileCompletionPercentage = data.item2;
          this.loggedInCompanyUser.user.userProfileCompletionPercentage = data.item2;
        }
      }, 1000);
    }, (err) => {
      Swal.fire({ title: messages.PROFILE_UPDATE_ERROR, type: 'error', showConfirmButton: true });
    });
  }

  getUserProfile() {
    this.isProfileEditable = false;
    this.isEmailValid = true;
    if (this.isPersonalAccount) {
      this.getTimeZones();
    }
    this.masterService.getUserProfile().subscribe((data: any) => {
      this.user = data;
      this.loggedInCompanyUser.user.userProfileCompletionPercentage = this.user.userProfileCompletionPercentage;
      if (this.user.userImage !== null && this.user.userImage !== undefined) {
        this.profilePhotoOptions.blobName = this.user.userImage.blobName;
        this.profilePhotoOptions.originalName = this.user.userImage.originalName;
        this.profilePhotoOptions.isActive = true;
        this.profilePhotoOptions.categoryId = this.userId;
      }
      else {
        this.profilePhotoOptions.blobName = '';
        this.profilePhotoOptions.originalName = '';
      }
      if (this.user.gender == "" || this.user.gender == undefined) {
        this.user.gender = null;
      }
      this.srcOfProfileImage = this.blobUrl + this.profilePhotoOptions.blobName;  //PR 3383:image source
      this.userCopy = JSON.parse(JSON.stringify(this.user));
      this.viewProfile = true;
    }, (err) => {
      Swal.fire({ title: messages.PROFILE_DETAILS_ERROR, type: 'error', showConfirmButton: true });
    });
    let element = document.getElementById('scroll');
    element.scrollIntoView(false);

  }

  validateContactNumber(contactNumber, contactType) {
    switch (contactType) {
      case 0:
        contactNumber = this.userContact.nativeElement.value;
        break;
      case 1:
        contactNumber = this.companyContactNumber.nativeElement.value;
        break;
      case 2:
        contactNumber = this.companyContact.nativeElement.value;
        break;
    }
    if (commonFunctions.validateContactNumber(contactNumber)) {
      switch (contactType) {
        case 0:
          this.validContactNumber = false;
          break;
        case 1:
          this.isValidCompanyContact = false;
          break;
        case 2:
          this.isValidCompanyUserContact = false;
          break;
      }
    }
    else {
      switch (contactType) {
        case 0:
          this.validContactNumber = true;
          this.user.personalContactNumber = contactNumber;
          break;
        case 1:
          this.isValidCompanyContact = true;
          this.company.companyContactNumber = contactNumber;
          break;
        case 2:
          this.isValidCompanyUserContact = true;
          this.user.companyUserContactNumber = contactNumber;
      }
    }
  }

  onCompanyWebsite(url) {
    url = url.toLowerCase();
    if (!this.websiteRegex.test(url) && url != '' && url != null) {
      this.isCompanyWebsite = false;
    }
    else {
      this.isCompanyWebsite = true;
    }
  }

  validateZipCode(code) {
    if (this.zipcodeRegex.test(code)) {
      this.isValidZipcode = true;
    }
    else {
      this.isValidZipcode = false;
    }
  }

  closeUserProfile() {
    if (JSON.stringify(this.userCopy).toLowerCase() !== JSON.stringify(this.user).toLowerCase() && this.isProfileEditable) {
      Swal.fire({
        title: messages.LEAVE_CONFIRMATION_MESSAGE,
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.value) {
          this.viewProfile = false;
          this.isProfileEditable = false;
          this.formSubmitted = false;
        }
      });
    }
    else {
      this.viewProfile = false;
      this.isProfileEditable = false;
      this.formSubmitted = false;
    }

  }

  getCompanyProfile() {
    this.isCompanyProfileEditable = false;
    this.viewCompanyProfile = true;
    this.formSubmitted = false;
    this.getTimeZones();
    this.masterService.getCompanyProfile().subscribe((data: any) => {
      this.company = data;
      this.loggedInCompanyUser.company.companyProfileCompletionPercentage = this.company.companyProfileCompletionPercentage;
      if (this.company.companyImage !== null && this.company.companyImage !== undefined) {
        this.companyPhotoOptions.blobName = this.company.companyImage.blobName;
        this.companyPhotoOptions.originalName = this.company.companyImage.originalName;
        this.companyPhotoOptions.isActive = true;
        this.companyPhotoOptions.categoryId = this.company.companyImage.categoryId;
      }
      else {
        this.companyPhotoOptions.blobName = "";
        this.companyPhotoOptions.originalName = "";
      }
      this.srcOfCompanyImage = this.blobUrl + this.companyPhotoOptions.blobName;  //PR 3383:image source
      this.companyCopy = JSON.parse(JSON.stringify(this.company));
    }, (err) => {
      Swal.fire({ title: messages.PROFILE_DETAILS_ERROR, type: 'error', showConfirmButton: true });
    });
    let element = document.getElementById('scrolls');
    element.scrollIntoView(false);
  }

  editCompanyProfile() {
    this.isCompanyProfileEditable = true;
    this.companyCopy = JSON.parse(JSON.stringify(this.company));
    this.setDefaultValues();
  }

  validateCompanyProfile(company) {
    this.formSubmitted = true;
    company.companyContactNumber = this.companyContactNumber.nativeElement.value;
    if (angular.isDefined(company.company) && company.company != "" && angular.isDefined(company.address) && company.address != "" && angular.isDefined(company.zipCode) && company.zipCode != "" && company.zipCode != null
      && angular.isDefined(company.aboutCompany) && company.aboutCompany != "" && !_.isUndefined(company.webSite) && !_.isEmpty(company.webSite) && angular.isDefined(company.companyEmailId) && company.companyEmailId != "" && angular.isDefined(company.companyContactNumber) && company.companyContactNumber != "" && company.timeZone !== "" && angular.isDefined(company.timeZone)
      && angular.isDefined(company.companyLocation) && company.companyLocation != "" && !_.isUndefined(company.companyLocation.name) && !_.isNull(company.companyLocation.name) && (!_.isEmpty(company.companyLocation.name))) {
      if (angular.isDefined(company.companyContactNumber)) {
        if (this.isValidCompanyContact) {
          this.isCompanyValid = true;
        }
        else {
          this.isCompanyValid = false;
          Swal.fire({ title: messages.INVALID_CONTACT_WARNING_MESSAGE, type: 'warning', showConfirmButton: true });
        }
      }
      if (!_.isUndefined(company.zipCode) && this.isCompanyValid) {
        if (this.isValidZipcode) {
          this.isCompanyValid = true;
        }
        else {
          this.isCompanyValid = false;
          Swal.fire({ title: messages.ENTER_CORRECT_ZIPCODE, type: 'warning', showConfirmButton: true });
        }
      }

      if (angular.isDefined(company.companyEmailId) && this.isCompanyValid) {
        if (this.isEmailValid) {
          this.isCompanyValid = true;
        }
        else {
          this.isCompanyValid = false;
          Swal.fire({ title: messages.INVALID_EMAIL_WARNING_MESSAGE, type: 'warning', showConfirmButton: true });
        }
      }
      if (angular.isDefined(company.employerIdentificationNumber) && this.isCompanyValid) {
        if (this.isEINValid) {
          this.isCompanyValid = true;
        }
        else {
          this.isCompanyValid = false;
          Swal.fire({ title: messages.INVALID_EIN_WARNING_MESSAGE, type: 'warning', showConfirmButton: true });
        }
      }
      //PR 3428: to validate wedsite field.
      if (angular.isDefined(company.webSite) && this.isCompanyValid) {
        if (this.isCompanyWebsite) {
          this.isCompanyValid = true;
        }
        else {
          this.isCompanyValid = false;
          Swal.fire({ title: messages.ENTER_VALID_DATA, type: 'warning', showConfirmButton: true });
        }
      }

      if (this.isCompanyValid) {
        this.updateCompanyProfile(company);
      }
    }
    else {
      if (_.isUndefined(company.companyLocation.name) || _.isNull(company.companyLocation.name) || _.isEmpty(company.companyLocation.name))
        this.isLocationValid = false;
      Swal.fire({ title: messages.ENTER_ALL_DETAILS, type: 'warning', showConfirmButton: true });
    }
  }

  validateEIN(event) {
    if (event.target.value.length < 10 && event.target.value !== "" && angular.isDefined(event.target.value)) {
      this.isEINValid = false;
    }
    else {
      this.isEINValid = true;
    }
    this.company.employerIdentificationNumber = event.target.value;
  }

  updateCompanyProfile(company) {
    this.masterService.updateCompanyProfile(company).subscribe((data: any) => {
      setTimeout(() => {
        if (data.item1) {
          Swal.fire({ title: "Profile updated", type: 'success', showConfirmButton: false, timer: 3000 });
          this.viewCompanyProfile = false;
          this.formSubmitted = false;
          this.loggedInCompany = company.company;
          this.loggedInCompanyUser.company.company = company.company;
          let loggedInUser = JSON.stringify(this.loggedInCompanyUser)
          loggedInUser = CryptoJS.AES.encrypt(loggedInUser, this.appConfigData.LoggedInUserEncryptionKey);
          sessionStorage.setItem(sessionStorageKeyConstants.loggedInCompanyUser, loggedInUser);
          this.updateStorage();
          this.company.companyProfileCompletionPercentage = data.item3;
          this.companyCopy.companyProfileCompletionPercentage = data.item3;
          this.loggedInCompanyUser.company.companyProfileCompletionPercentage = data.item3;
        }
        else if (data.item2 == 1) {
          Swal.fire({ title: "Company Name already exists", type: 'warning', showConfirmButton: true });
        }
        else if (data.item2 == 2) {
          Swal.fire({ title: "Please enter valid EIN number", type: 'warning', showConfirmButton: true });
        }
      }, 1000);
    }, (err) => {
      Swal.fire({ title: messages.PROFILE_UPDATE_ERROR, type: 'error', showConfirmButton: true });
    });
  }

  closeCompanyProfileUpdate() {
    if (JSON.stringify(this.companyCopy).toLowerCase() !== JSON.stringify(this.company).toLowerCase()) {
      Swal.fire({
        title: messages.LEAVE_CONFIRMATION_MESSAGE,
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.value) {
          this.viewCompanyProfile = false;
          this.isCompanyProfileEditable = false;
          this.formSubmitted = false;
        }
      });
    }
    else {
      this.viewCompanyProfile = false;
      this.isCompanyProfileEditable = false;
      this.formSubmitted = false;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validateEmail(emailId) {
    if (emailId !== null && !messages.EMAILID_REGEX.test(emailId) && emailId != undefined && emailId != "") {
      this.isEmailValid = false;
    }
    else {
      this.isEmailValid = true;
    }
  }

  getConnectionsNotifications(notification: Notification) {
    notification.notificationTypeId = NotificationType.Connection;
    if (notification.isCompanyNotification) {
      if (this.companyId == notification.companyId && this.hasAdminPrivilege) {
        this.notifications.unshift(notification);
        this.notificationsCopy.unshift(notification);
        this.assigneFiltersAndFilterCount(true);
        this.notificationUnReadCount = this.notificationsCopy.filter(notification => (notification.isRead == false)).length;
        this.showNotificationBadge = true;
        sessionStorage.setItem("showNotificationBadge", JSON.stringify(this.showNotificationBadge));
        sessionStorage.setItem(sessionStorageKeyConstants.notificationUnReadCount, this.notificationUnReadCount.toString());
      }
    }
    else {
      if (this.userId == notification.companyId) {
        this.notifications.unshift(notification);
        this.notificationsCopy.unshift(notification);
        this.assigneFiltersAndFilterCount(true);
        this.notificationUnReadCount = this.notificationsCopy.filter(notification => (notification.isRead == false)).length;
        this.showNotificationBadge = true;
        sessionStorage.setItem("showNotificationBadge", JSON.stringify(this.showNotificationBadge));
        sessionStorage.setItem(sessionStorageKeyConstants.notificationUnReadCount, this.notificationUnReadCount.toString());
      }
    }
  }

  refreshAttachment(object) {
    this.updateStorage();
    if (object.attachment.attachmentType === 'CompanyProfilePhoto') {
      this.companyPhotoOptions.blobName = object.attachment.blobName;
      this.srcOfCompanyImage = this.blobUrl + this.companyPhotoOptions.blobName;
      this.loggedInCompanyUser.companyImageBlobName = this.companyPhotoOptions.blobName;
      let loggedInUser = JSON.stringify(this.loggedInCompanyUser)
      loggedInUser = CryptoJS.AES.encrypt(loggedInUser, this.appConfigData.LoggedInUserEncryptionKey);
      sessionStorage.setItem(sessionStorageKeyConstants.loggedInCompanyUser, loggedInUser);
      this.companyPhotoOptions.originalName = object.attachment.originalName;
      this.companyPhotoOptions.categoryId = this.companyId;
      this.company.companyProfileCompletionPercentage = object.profilePercentage;
      this.companyCopy.companyProfileCompletionPercentage = object.profilePercentage;
      this.loggedInCompanyUser.company.companyProfileCompletionPercentage = object.profilePercentage;
    }
    else if (object.attachment.attachmentType === 'UserProfilePhoto') {
      this.profilePhotoOptions.blobName = object.attachment.blobName;
      this.profilePhotoOptions.originalName = object.attachment.originalName;
      this.srcOfProfileImage = this.blobUrl + this.profilePhotoOptions.blobName;
      this.userProfileImage = this.profilePhotoOptions.blobName;
      this.userImage = this.blobUrl + this.profilePhotoOptions.blobName;
      this.loggedInCompanyUser.userImageBlobName = this.profilePhotoOptions.blobName
      let loggedInUser = JSON.stringify(this.loggedInCompanyUser)
      loggedInUser = CryptoJS.AES.encrypt(loggedInUser, this.appConfigData.LoggedInUserEncryptionKey);
      sessionStorage.setItem(sessionStorageKeyConstants.loggedInCompanyUser, loggedInUser);
      this.dashboardService.userImageUpdateEvent(this.profilePhotoOptions.blobName);
      this.user.userProfileCompletionPercentage = object.profilePercentage;
      this.userCopy.userProfileCompletionPercentage = object.profilePercentage;
      this.loggedInCompanyUser.user.userProfileCompletionPercentage = object.profilePercentage;
    }
  }

  updateStorage() {
    this.loginService.getLoggedInUserByCompanyId(this.companyUserId).subscribe((response: any) => {
      let loggedInUser = JSON.stringify(response.item1.companyUser)
      loggedInUser = CryptoJS.AES.encrypt(loggedInUser, this.appConfigData.LoggedInUserEncryptionKey);
      sessionStorage.setItem(sessionStorageKeyConstants.loggedInCompanyUser, loggedInUser);
      var accessToken = CryptoJS.AES.encrypt(response.item2.accessToken, this.appConfigData.APIAuthorizationKey);
      sessionStorage.setItem(sessionStorageKeyConstants.authToken, JSON.stringify(accessToken.toString()));
      var expiryTime = CryptoJS.AES.encrypt(response.item2.expireDateTimeUtc, this.appConfigData.sessionExpiryTimeEncryptionKey);
      sessionStorage.setItem(sessionStorageKeyConstants.expireDateTime, expiryTime);
      let companySettings = CryptoJS.AES.encrypt(JSON.stringify(response.item1.companySettings), this.appConfigData.companySettingsEncryptionKey);
      sessionStorage.setItem(sessionStorageKeyConstants.companySettings, companySettings);
    }, (err) => {
      Swal.fire({ title: messages.LOGGEDIN_USER_BYID__ERROR, type: 'error', showConfirmButton: true });
    });
  }

  private getCurrentRoute(currentState: string) {
    let cState = currentState.substring(1, currentState.length);
    var candi = cState.search('CandidateDetails');
    var job = cState.search('JobDetails');
    let isFromNetworkTab = JSON.parse(sessionStorage.getItem("isFromNetworkTab")) == null ? false : JSON.parse(sessionStorage.getItem("isFromNetworkTab"));
    if (candi != -1) {
      return cState = "Candidates";
    }
    else if (job != -1) {
      return cState = "Jobs";
    }
    else if (isFromNetworkTab && cState == "ProfilePage") {
      return cState = "Network";
    }
    else {
      return cState;
    }
  }

  navigateTo(path: string, event: any) {
    if (event.ctrlKey) {
      window.open(this.appConfigData.ApplicationUrl + "/#/" + path, '_blank');
      return;
    }
    this.router.navigate([path]);
  }

  getFeedback() {
    this.viewFeedback = true;
  }

  closeFeedback() {
    this.viewFeedback = false;
  }

  private getCurrentActiveTab() {
    if (this.selectedNavigationTab == 'Jobs' || this.selectedNavigationTab == 'Jobs/OrgJobs' || this.selectedNavigationTab == 'Jobs/NetworkJobs' || this.selectedNavigationTab == 'Jobs/ArchivedJobs' || this.selectedNavigationTab.includes("JobDetails")) {
      this.isJobsTabActive = true;
      this.isCandidateTabActive = false;
      this.isManagementTabActive = false;
      this.isReportsTabActive = false;
    }
    else if (this.selectedNavigationTab == 'Candidates' || this.selectedNavigationTab == 'Candidates/CandidatePool' || this.selectedNavigationTab == 'Candidates/NetworkCandidates' || this.selectedNavigationTab == 'Candidates/ArchivedCandidates' || this.selectedNavigationTab == 'Candidates/BlacklistedCandidates' || this.selectedNavigationTab == 'Candidates/OnboardedCandidates' || this.selectedNavigationTab.includes("CandidateDetails")) {
      this.isJobsTabActive = false;
      this.isCandidateTabActive = true;
      this.isManagementTabActive = false;
      this.isReportsTabActive = false;
    }
    else if (this.selectedNavigationTab == 'Reports/ClientSubmission' || this.selectedNavigationTab == 'Reports/RecruiterSubmission' || this.selectedNavigationTab == 'Reports/RecruitingStatus' || this.selectedNavigationTab == 'Reports/Aging' || this.selectedNavigationTab === 'Reports/Dashboard') {
      this.isJobsTabActive = false;
      this.isCandidateTabActive = false;
      this.isManagementTabActive = false;
      this.isReportsTabActive = true;
    }
    else {
      this.isJobsTabActive = false;
      this.isCandidateTabActive = false;
      this.isManagementTabActive = false;
      this.isReportsTabActive = false;
    }

  }

  private openChatWithUser(chatNotification: ChatNotificationModel) {
    let usersConnectedList = sessionStorage.getItem(sessionStorageKeyConstants.UsersConnectedList);
    if (usersConnectedList) {
      var bytes = CryptoJS.AES.decrypt(usersConnectedList, this.appConfigData.chatParticipantsEncryptionKey);
      usersConnectedList = bytes.toString(CryptoJS.enc.Utf8);
    }
    this.usersConnected = JSON.parse(usersConnectedList);
    if ((!_.isNull(this.usersConnected)) && (!_.isUndefined(this.usersConnected)) && this.usersConnected.length > 0) {
      let sentUser = this.usersConnected.find(x => x.participant.id == chatNotification.fromId);
      this.chatNotifications = this.chatNotifications.filter(notification => notification.fromId != chatNotification.fromId);
      this.signalrService.openChatNotificationAction(sentUser.participant);
    }
    this.showChatNotificationBadge = false;
    sessionStorage.setItem("showChatNotificationBadge", JSON.stringify(this.showChatNotificationBadge));
    sessionStorage.setItem("chatMessagesCount", this.chatNotifications.length.toString());
  }

  closeMigrate() {
    this.viewMigrate = false;
  }

  migrate() {
    this.masterService.migrateToAtsOrNonAts().subscribe((response: any) => {
      if (response) {
        this.logOut();
      }
      else {
        this.closeMigrate();
        Swal.fire({ title: messages.MIGRATIONFAILED_ALERT, type: 'warning', showConfirmButton: true });
      }
    }, (err) => {
      Swal.fire({ title: messages.MIGRATIONFAILED_ALERT, type: 'error', showConfirmButton: true });
    });
  }

  receiveUserNotifications(notification: Notification) {
    this.notifications.unshift(notification);
    this.notificationsCopy.unshift(notification);
    this.assigneFiltersAndFilterCount(true);
    this.notificationUnReadCount = this.notificationsCopy.filter(notification => (notification.isRead == false)).length;
    this.showNotificationBadge = true;
    sessionStorage.setItem("showNotificationBadge", JSON.stringify(this.showNotificationBadge));
    sessionStorage.setItem(sessionStorageKeyConstants.notificationUnReadCount, this.notificationUnReadCount.toString());
  }

  receiveCompanyUserNotifications(notification: Notification) {
    this.notifications.unshift(notification);
    this.notificationsCopy.unshift(notification);
    this.assigneFiltersAndFilterCount(true);
    this.notificationUnReadCount = this.notificationsCopy.filter(notification => (notification.isRead == false)).length;
    this.showNotificationBadge = true;
    sessionStorage.setItem("showNotificationBadge", JSON.stringify(this.showNotificationBadge));
    sessionStorage.setItem(sessionStorageKeyConstants.notificationUnReadCount, this.notificationUnReadCount.toString());
  }

  receiveCompanyNotifications(notification: Notification) {
    if (notification.performedBy !== this.companyUserId) {
      if (notification.isMigrationNotification) {
        this.router.navigate(["/login"]).then(async (data) => {
          if (data) {
            await sessionStorage.clear();
            this.globalContentService.setLoggedInUser(null);
            this.loginService.unSubscribeUserFromSignalR(this.userId).subscribe((data: any) => {
              this.signalrService.stopConnection();
            });
            Swal.fire({
              title: notification.notificationMessage,
              text: '',
              type: 'warning',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: messages.TEXT_OK,
            }).then(function (result) {
              if (result.value) {
              }
            });
          }
        });
      }
      else {
        this.notifications.unshift(notification);
        this.notificationsCopy.unshift(notification);
        this.assigneFiltersAndFilterCount(true);
        this.notificationUnReadCount = this.notificationsCopy.filter(notification => (notification.isRead == false)).length;
        this.showNotificationBadge = true;
        sessionStorage.setItem("showNotificationBadge", JSON.stringify(this.showNotificationBadge));
        sessionStorage.setItem(sessionStorageKeyConstants.notificationUnReadCount, this.notificationUnReadCount.toString());
      }
    }
  }



  deleteNotification(notification) {
    this.notifications = this.notifications.filter(item => (item.notificationId !== notification.notificationId));
    this.notificationsCopy = JSON.parse(JSON.stringify(this.notifications));
    this.notificationUnReadCount = this.notificationsCopy.filter(notification => (notification.isRead == false)).length;
    sessionStorage.setItem(sessionStorageKeyConstants.notificationUnReadCount, this.notificationUnReadCount.toString());
  }

  deleteChatNotification(disconnectedFromUserId) {
    this.chatNotifications = this.chatNotifications.filter(item => (item.fromId !== disconnectedFromUserId));
    sessionStorage.setItem("chatMessagesCount", this.chatNotifications.length.toString());
  }

  searchLocations(event) {
    this.isLocationValid = true;
    var searchTerm = event.query.trim() == "" ? null : event.query;
    this.searchService.getLocations(searchTerm).subscribe((data: any) => {
      this.locations = data;
    });
  }

  getTimeZones() {
    this.masterService.getTimeZonesList().subscribe((data: any) => {
      this.timeZonesList = data;
    }, (err) => {
      Swal.fire({ title: messages.LOCATION_ERROR, type: 'error', showConfirmButton: true });
    });
  }

  popUnReadCount() {
    this.showNotificationBadge = false;
    sessionStorage.setItem("showNotificationBadge", JSON.stringify(this.showNotificationBadge));
  }

  hideMessageCount() {
    this.showChatNotificationBadge = false;
    sessionStorage.setItem("showChatNotificationBadge", JSON.stringify(this.showChatNotificationBadge));
  }

  getMessageNotifications() {
    this.dashboardService.getMessageNotifications().subscribe((notifications: ChatNotificationModel[]) => {
      this.chatNotifications = notifications;
      var newMessagesCount = this.chatNotifications.length;
      var messagesCount = sessionStorage.getItem("chatMessagesCount") == null ? 0 : +sessionStorage.getItem("chatMessagesCount");
      if (newMessagesCount > messagesCount) {
        this.showChatNotificationBadge = true;
      }
      sessionStorage.setItem("chatMessagesCount", newMessagesCount.toString());
      sessionStorage.setItem("showChatNotificationBadge", JSON.stringify(this.showChatNotificationBadge));
    });
  }

  viewCompanySettings() {
    this.router.navigate(["Settings"]);
  }

  getCompanySettings() {
    this.masterService.getCompanySettings().subscribe((data: any) => {
      this.companySettings = data;
      let settings = CryptoJS.AES.encrypt(JSON.stringify(this.companySettings), this.appConfigData.companySettingsEncryptionKey);
      sessionStorage.setItem(sessionStorageKeyConstants.companySettings, settings);
    }, (err) => {
      Swal.fire({ title: messages.SOME_THING_WENT_WRONG, type: 'warning', showConfirmButton: true });
    });
  }

  ngOnDestroy() {
    this.getNotificationSubscription.unsubscribe();
    this.newMessageReceivedSubscription.unsubscribe();
    this.receiveUserNotificationSubscription.unsubscribe();
    this.receiveCompanyUserNotificationSubscription.unsubscribe();
    this.receiveCompanyNotificationSubscription.unsubscribe();
    this.popChatNotificationsForDisconnectedToUserSubscription.unsubscribe();
    this.deleteNotificationSubscription.unsubscribe();
    this.updateChatMessageNotificationsSubscription.unsubscribe();
    this.popChatNotificationsForDisconnectedFromUserSubscription.unsubscribe();
  }

  filterNotifications(filter?, isScrollStop?) {
    this.isFilterSelected = true;
    this.selectedFilter = (filter == "" || filter == null) ? "All" : filter;
    sessionStorage.setItem("selectedFiltervalue", this.selectedFilter);
    switch (filter) {
      case "Jobs":
        const jobsNotifications = this.notificationsCopy.filter(not => not.notificationTypeId == NotificationType.Job);
        this.notifications = JSON.parse(JSON.stringify(jobsNotifications));
        break;
      case "Candidates":
        const candidatesNotifications = this.notificationsCopy.filter(not => not.notificationTypeId == NotificationType.Candidate);
        this.notifications = JSON.parse(JSON.stringify(candidatesNotifications));
        break;
      case "Connections":
        const connectionsNotifications = this.notificationsCopy.filter(not => not.notificationTypeId == NotificationType.Connection);
        this.notifications = JSON.parse(JSON.stringify(connectionsNotifications));
        break;
      case "Discussions":
        const discussionsNotifications = this.notificationsCopy.filter(not => (not.notificationTypeId == NotificationType.JobDiscussion || not.notificationTypeId == NotificationType.CandidateDiscussion || not.notificationTypeId == NotificationType.NetworkJobDiscussion || not.notificationTypeId == NotificationType.NetworkCandidateDiscussion));
        this.notifications = JSON.parse(JSON.stringify(discussionsNotifications));
        break;
      case "Network Jobs":
        const networkJobNotifications = this.notificationsCopy.filter(not => not.notificationTypeId == NotificationType.NetworkJob);
        this.notifications = JSON.parse(JSON.stringify(networkJobNotifications));
        break;
      case "Network Candidates":
        const networkCandidatesNotifications = this.notificationsCopy.filter(not => not.notificationTypeId == NotificationType.NetworkCandidate);
        this.notifications = JSON.parse(JSON.stringify(networkCandidatesNotifications));
        break;
      case "All":
        this.notifications = JSON.parse(JSON.stringify(this.notificationsCopy));
        break;
    }
    this.isClearAllEnable = (this.notifications.length == 0) ? false : true;
    this.isReadAllEnable = (this.filterOptions.filter(x => x.name == this.selectedFilter)[0].notificationCount == 0) ? false : true;
    if (isScrollStop == undefined)
      document.getElementById("scrollTop").scrollIntoView(true);
  }

  async clearAllAndNavigate(event) {
    await this.clearSearch();
    await this.clearJobDetails(false);
    await this.clearCandidateTab(true);
    await this.clearDashboardTab(true);
    this.navigateTo('Management/UserManagement', event);
  }

}
