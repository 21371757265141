import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Notification } from 'src/app/models/notificationmodel/notification';
import { NotificationSetting } from 'src/app/models/notificationSettings/notification-settings';
import { User } from 'src/app/models/usermodel/user';
import { map } from 'rxjs/operators';
import { Company } from 'src/app/models/companymodel/company';
import { Subject } from 'rxjs';
import { ContactUs } from 'src/app/models/contactUs/contactus';
import { CompanySettings } from 'src/app/models/company-settings';
import { AppConfiguration } from 'read-appsettings-json';
import { EmailNotificationSettings } from 'src/app/models/emailSettings/emailSettings';
import { UserPrivacySettings } from 'src/app/models/user-privacy-settings';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  apiURL: string = environment.apiURL;
  recaptchaSecretKey: string = AppConfiguration.Setting().ReCaptcha.RecaptchaSecretKey;
  private UpdatePageNumber = new Subject<any>();
  UpdatePageNumber$ = this.UpdatePageNumber.asObservable();

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  constructor(private httpClient: HttpClient) { }

  //#region Master Data Service Methods
  public getCountries() {
    return this.httpClient.get(`${this.apiURL}/masterData/getCountries`).pipe(map(this.extractData));
  }

  public getStatesByCountryId(countryId: number) {
    return this.httpClient.get(`${this.apiURL}/masterData/getStatesByCountryId/${countryId}`);
  }

  public getCitiesByStateId(stateId: number) {
    return this.httpClient.get(`${this.apiURL}/masterData/getCitiesByStateId/${stateId}`);
  }

  public getTimeZonesList() {
    return this.httpClient.get(`${this.apiURL}/masterData/getTimeZones`).pipe(map(this.extractData));
  }

  public getSkills() {
    return this.httpClient.get(`${this.apiURL}/masterData/getSkills`);
  }
  //#endregion


  //#region Notification Service Methods
  public getNotifications() {
    return this.httpClient.get(`${this.apiURL}/getNotifications`);
  }

  public updateNotificationStatus(notification: Notification) {
    return this.httpClient.post(`${this.apiURL}/updateNotificationStatus`, notification);
  }

  public getNotificationSetting() {
    return this.httpClient.get(`${this.apiURL}/notificationSettings`);
  }

  public updateNotificationSetting(notificationSetting: NotificationSetting) {
    return this.httpClient.post(`${this.apiURL}/notificationSettings`, notificationSetting);
  }

  public updateCompanySettings(companySettings: CompanySettings) {
    return this.httpClient.post(`${this.apiURL}/companySettings`, companySettings);
  }

  public getCompanySettings() {
    return this.httpClient.get(`${this.apiURL}/getCompanySettings`);
  }

  public getRoleBasedCompanyUsers(roleId: number) {
    return this.httpClient.get(`${this.apiURL}/getRoleBasedCompanyUsers/${roleId}`);
  }

  //PR 3350: TO get and Update the company and User profiles
  public getUserProfile() {
    return this.httpClient.get(`${this.apiURL}/getUserProfile`);
  }

  public updateUserProfile(userProfile: User) {
    return this.httpClient.post(`${this.apiURL}/updateUserProfile`, userProfile);
  }

  public getCompanyProfile() {
    return this.httpClient.get(`${this.apiURL}/getCompanyProfile`);
  }

  public updateCompanyProfile(company: Company) {
    return this.httpClient.post(`${this.apiURL}/updateCompanyProfile`, company);
  }

  //PR 3350: TO get and Update the company and User profiles

  public applicationFeedback(formData: any) {
    return this.httpClient.post(`${this.apiURL}/applicationFeedback`, formData);
  }

  public getAppFeedbackCategories() {
    return this.httpClient.get(`${this.apiURL}/getAppFeedbackCategories/`);
  }

  public migrateToAtsOrNonAts() {
    return this.httpClient.get(`${this.apiURL}/migrateToAtsOrNonAts`);
  }

  public updatePageNumber() {
    this.UpdatePageNumber.next();
  }

  public SubmitContactUs(contactUs: ContactUs) {
    return this.httpClient.post(`${this.apiURL}/submitContactUs`, contactUs);
  }

  public getIpAddress() {
    return this.httpClient.get(`https://cors-anywhere.herokuapp.com/https://api.ipify.org/?format=json`);
  }
  public getLocation(ipAddress: any) {
    return this.httpClient.get("https://cors-anywhere.herokuapp.com/https://api.apility.net/geoip/" + ipAddress + "");
  }
  public validateCaptcha(response: string) {
    var object = {
      'response': response,
      'secret': this.recaptchaSecretKey
    }
    return this.httpClient.post(`https://cors-anywhere.herokuapp.com/https://www.google.com/recaptcha/api/siteverify?secret=${this.recaptchaSecretKey}&response=${response}`, object);
  }

  public updateEmailNotificationSettings(emailNotificationSettings: EmailNotificationSettings) {
    return this.httpClient.post(`${this.apiURL}/emailNotificationSettings`, emailNotificationSettings);
  }

  public getEmailNotificationSettings() {
    return this.httpClient.get(`${this.apiURL}/emailNotificationSettings`);
  }
  public getUserPrivacySettings() {
    return this.httpClient.get(`${this.apiURL}/privacySettings`);
  }
  public updateUserPrivacySettings(privacySettings: UserPrivacySettings) {
    return this.httpClient.post(`${this.apiURL}/privacySettings`, privacySettings);
  }
}
