import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  apiURL: string = environment.apiURL;
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  constructor(private httpClient: HttpClient) { }

  public uploadImage(formData: any) {
    return this.httpClient.post(`${this.apiURL}/uploadImage`, formData);
  }

  public deleteFile(formData: any) {
    return this.httpClient.post(`${this.apiURL}/deleteFile`, formData);
  }
}
