import Swal from 'sweetalert2';
import { _ } from 'underscore';
import { CandidateGridFilterByFields } from '../models/candidate/Grid/candidate-grid-filter-by-fields';
import { JobsGridFilterByFields } from '../models/filterbyfelds/JobsGridFilterByFields';
import { DatePipe } from '@angular/common';
import { sessionStorageKeyConstants } from '../constants/sessionStorageKeyConstants';
import * as CryptoJS from 'crypto-js';
import { JobFilterEnum } from '../models/jobFilterEnum/job-filter-enum';
import { CandidateFilterEnum } from '../models/candidateFilterEnum/candidate-filter-enum';
import { JobUser } from '../models/job/job-user';
import { JobUserByRole } from '../models/job/job-user-by-role';


export function changePhoneNumber(event) {
  let newVal = event.toString().replace(/\D/g, '');
  if (newVal.length === 0) {
    newVal = '';
  } else if (newVal.length <= 3) {
    newVal = newVal.replace(/^(\d{0,3})/, '($1)');
  } else if (newVal.length <= 6) {
    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
  } else if (newVal.length <= 10) {
    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
  } else {
    newVal = newVal.substring(0, 10);
    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
  }
  return newVal;
}
export function validatefile(fileName, fileSize, permittedFiles) {
  var error = '';
  var permittedFilesArray = permittedFiles.toLowerCase().split(",");
  var extension = fileName.substring(fileName.lastIndexOf("."));
  if ((permittedFilesArray.indexOf("*.*") === -1) && permittedFilesArray.indexOf(extension.toLowerCase()) === -1) {
    Swal.fire({ title: "Unsupported file.", type: 'warning', timer: 3000 });
    error = "Unsupported file."
  }
  else if (fileSize > this.maxImageSize) {
    Swal.fire({ title: "File size exceeded 5Mb.", type: 'warning', timer: 3000 });
    error = "File size exceeded 5Mb.";
  }
  return error;
}
export function validateContactNumber(contactNumber) {
  return (!_.isUndefined(contactNumber) && !_.isEmpty(contactNumber) && contactNumber.length != 14);
}

export function validateCandidateContactNumber(contactNumber) {
  return (!_.isUndefined(contactNumber) && !_.isEmpty(contactNumber) && contactNumber.length != 10);
}
export function commonFunctionForserverSideSortingForCandidates(data, filterByFields, workAuthorizationsList, usersMasterData): any {
  if (data.filter.filters.length > 0) {
    data.filter.filters.forEach((value, index) => {
      switch (value.field) {
        case "candidateId":
          filterByFields.candidateId = value.search.trim();
          break;
        case "candidateName":
          filterByFields.candidateName = replaceSpecialCharacters(value.search).trim();
          break;
        case "firstName":
          filterByFields.firstName = replaceSpecialCharacters(value.search).trim();
          break;
        case "lastName":
          filterByFields.lastName = replaceSpecialCharacters(value.search).trim();
          break;
        case "contactNumber":
          filterByFields.contactNumber = replaceSpecialCharacters(value.search).trim();
          break;
        case "emailId":
          filterByFields.emailId = replaceSpecialCharacters(value.search).trim();
          break;
        case "candidateJobTitle":
          filterByFields.candidateJobTitle = replaceSpecialCharacters(value.search).trim();
          break;
        case "workAuthorizationType":
          var workAuthorizations = workAuthorizationsList.filter(wa => wa.workAuthorizationType == value.search);
          filterByFields.workAuthorizationTypeId = workAuthorizations.length > 0 ? workAuthorizations[0].workAuthorizationTypeId : null;
          break;
        case "experienceInYears":
          filterByFields.experienceInYears = value.search.trim();
          break;
        case "rate":
          filterByFields.rate = value.search.trim();
          break;
        case "location":
          filterByFields.location = replaceSpecialCharacters(value.search).trim();
          break;
        case "actionDate":
          filterByFields.actionDate = value.search.trim();
          break;
        case "client":
          filterByFields.clientName = replaceSpecialCharacters(value.search).trim();
          break;
        case "contractStartDate":
          filterByFields.contractStartDate = value.search.trim()
          break;
        case "contractEndDate":
          filterByFields.contractEndDate = value.search.trim()
          break;
        case "actionBy":
          if (usersMasterData != null && usersMasterData.length > 0) {
            var users = usersMasterData.filter(u => u.name == value.search.trim());
            filterByFields.actionBy = users.length > 0 ? users[0].userId : null;
          }
          else {
            filterByFields.actionBy = value.search.trim();
          }
          break;
        case "jobTitle":
          filterByFields.onboardedJobTitle = replaceSpecialCharacters(value.search).trim();
          break;
        case "sharedByOrCreatedBy":
          filterByFields.sharedByOrCreatedBy = replaceSpecialCharacters(value.search).trim();
          break;
      }
    });
    filterByFields.pageNumber = 0;
  }
  if (data.sort.length > 0) {
    filterByFields.orderBy = data.sort[0].field;
    filterByFields.orderByReverse = data.sort[0].direction == "asc" ? false : true;
  }
  return filterByFields;
}
export function getOrgJobsFilters(isAts: boolean, isHiringManager: boolean) {
  var filtersListForOrgJobs = [
    { filterId: JobFilterEnum.JobSharedToOthers, filter: "Jobs shared to others" },
    { filterId: JobFilterEnum.FavoriteJobs, filter: "Favorite jobs" },
    isHiringManager ? {} : { filterId: JobFilterEnum.JobSharedToUs, filter: "Jobs shared to us" },
    isAts ? { filterId: JobFilterEnum.JobsOnHold, filter: "Jobs on hold" } : {},
    isHiringManager ? {} : { filterId: JobFilterEnum.OwnJobs, filter: "Own jobs" }
  ];
  return filtersListForOrgJobs;
}
export function getOrgJobsFilterId(filter) {
  switch (filter) {
    case "Favorite jobs":
      return JobFilterEnum.FavoriteJobs;
    case "Jobs shared to others":
      return JobFilterEnum.JobSharedToOthers;
    case "Jobs shared to us":
      return JobFilterEnum.JobSharedToUs;
    case "Jobs on hold":
      return JobFilterEnum.JobsOnHold;
    case "Own jobs":
      return JobFilterEnum.OwnJobs;
  }
}
export function serverSideComputingForJobs(data, filterByFields, accountManagersList, recruitersList, jobStatusesList, jobPrioritiesList): JobsGridFilterByFields {
  if (data.filter.filters.length > 0) {
    data.filter.filters.forEach((value, index) => {
      switch (value.field) {
        case "jobId":
          filterByFields.jobId = value.search.trim();
          break;
        case "requestId":
          filterByFields.requestId = replaceSpecialCharacters(value.search).trim();
          break;
        case "jobTitle":
          filterByFields.jobTitle = replaceSpecialCharacters(value.search).trim();
          break;
        case "location":
          filterByFields.location = replaceSpecialCharacters(value.search).trim();
          break;
        case "clientName":
          filterByFields.clientName = replaceSpecialCharacters(value.search).trim();
          break;
        case "rate":
          filterByFields.rate = value.search.trim();
          break;
        case "accountManager":
          var filteredAccountManager = accountManagersList.filter(am => am.name == value.search);
          filterByFields.accountManagerUserId = filteredAccountManager.length > 0 ? filteredAccountManager[0].userId : null;
          break;
        case "primaryRecruiter":
          var filteredJobPriority = recruitersList.filter(r => r.name == value.search);
          filterByFields.primaryRecruiterUserId = filteredJobPriority.length > 0 ? filteredJobPriority[0].userId : null;
          break;
        case "actionDate":
          filterByFields.actionDate = value.search.trim();
          break;
        case "jobStatusId":
          var filteredJobStatus = jobStatusesList.filter(js => js.jobStatus == value.search);
          filterByFields.jobStatusId = filteredJobStatus.length > 0 ? filteredJobStatus[0].jobStatusId : null;
          break;
        case "jobPriorityId":
          var filteredJobPriority = jobPrioritiesList.filter(jp => jp.jobPriority == value.search);
          filterByFields.jobPriorityId = filteredJobPriority.length > 0 ? filteredJobPriority[0].jobPriorityId : null;
          break;
        case "sharedBy":
          filterByFields.sharedBy = replaceSpecialCharacters(value.search).trim();
          break;
        case "sharedTo":
          filterByFields.sharedTo = replaceSpecialCharacters(value.search).trim();
          break;
        case "sharedDate":
          filterByFields.sharedDate = value.search.trim();
          break;
      }
    });
    filterByFields.pageNumber = 0;
  }
  if (data.sort.length > 0) {
    filterByFields.orderBy = data.sort[0].field;
    filterByFields.orderByReverse = data.sort[0].direction == "asc" ? false : true;
  }
  return filterByFields;
}
export function checkFilterValidations(gridSource) {
  let rateRegex: RegExp = /^[\d]*(\.\d{1,3})?(-?)[\d]*(\.\d{1,3})*$/;
  let experienceRegex: RegExp = /^[\d]+-?[\d]*$/;
  let idRegex: RegExp = /^[\d]+$/;
  let dateRegex: RegExp = /^[0-9/]+$/;
  let rateFilter = gridSource.filter.filters.filter(filter => filter.field === "rate");
  let contactNumberFilter = gridSource.filter.filters.filter(filter => filter.field === "personalContactNumber");
  let experienceFilter = gridSource.filter.filters.filter(filter => filter.field === "experienceInYears");
  let candidateIdFilter = gridSource.filter.filters.filter(filter => filter.field === "candidateId");
  let jobIdFilter = gridSource.filter.filters.filter(filter => filter.field === "jobId");
  let dateFilter = gridSource.filter.filters.filter(filter => filter.field === "sharedDate" ||
    filter.field === "actionDate" ||
    filter.field === "contractStartDate" ||
    filter.field === "contractEndDate");
  if ((rateFilter.length > 0 && rateFilter[0].search != "" && !rateRegex.test(rateFilter[0].search)) ||
    (experienceFilter.length > 0 && experienceFilter[0].search != "" && !experienceRegex.test(experienceFilter[0].search)) ||
    (candidateIdFilter.length > 0 && candidateIdFilter[0].search != "" && !idRegex.test(candidateIdFilter[0].search)) ||
    (jobIdFilter.length > 0 && jobIdFilter[0].search != "" && !idRegex.test(jobIdFilter[0].search)) ||
    (contactNumberFilter.length > 0 && contactNumberFilter[0].search != "" && !idRegex.test(contactNumberFilter[0].search)) ||
    (dateFilter.length > 0 && dateFilter[0].search != "" && !dateRegex.test(dateFilter[0].search))) {
    return false;
  }
  else {
    return true;
  }
}

export function textChanged(event, Max_Length) {
  if (event.editor.getLength() > Max_Length) {
    event.editor.deleteText(Max_Length, event.editor.getLength());
  }
}

export function trimText(event) {
  var text = event.editor.getText();
  event.editor.setText(text.trim())
}

export function getPastWeekDateInAppTimeZone() {
  let appConfigData = require("appsettings.json");
  let loggedInUserData = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser);
  if (loggedInUserData) {
    var bytes = CryptoJS.AES.decrypt(loggedInUserData, appConfigData.LoggedInUserEncryptionKey);
    loggedInUserData = bytes.toString(CryptoJS.enc.Utf8);
  }
  var loggedInUser = JSON.parse(loggedInUserData);
  var loggedInUserAppTimeZoneOffset = loggedInUser.appTimezoneOffset;
  var lastWeekDate = new Date(new Date(new Date().toUTCString()).getTime() - 60 * 60 * 24 * 6 * 1000);
  var lastWeekDatePipe = new DatePipe(lastWeekDate.toUTCString());
  var lastWeek = lastWeekDatePipe.transform(lastWeekDate, 'yyyy-MM-dd', loggedInUserAppTimeZoneOffset, window.navigator.language);
  return lastWeek;
}

export function isResumeAvailable(assignedCandidate) {
  var isCandidateResumeAvailabe = false;
  if (assignedCandidate.isNetworkSubmittedCandidate && !_.isNull(assignedCandidate.candidateAttachments) && !_.isUndefined(assignedCandidate.candidateAttachments) && assignedCandidate.candidateAttachments.length > 0) {
    assignedCandidate.candidateAttachments.forEach((document) => {
      if (document.attachmentType == "Resume")
        isCandidateResumeAvailabe = true;
    });
  }
  else
    isCandidateResumeAvailabe = false;
  return isCandidateResumeAvailabe;
}

export function getBrowserName() {
  const agent = window.navigator.userAgent.toLowerCase();
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'Edge';
    case agent.indexOf('opr') > -1 && !!(<any>window).opr:
      return 'Opera';
    case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
      return 'Chrome';
    case agent.indexOf('trident') > -1:
      return 'IE';
    case agent.indexOf('firefox') > -1:
      return 'Firefox';
    case agent.indexOf('safari') > -1:
      return 'Safari';
    default:
      return 'Other';
  }
}
export function getCandidatePoolFilters() {
  var filtersListForCandidatePool = [
    { filterId: CandidateFilterEnum.CandidateSharedToOthers, filter: "Candidates shared to others" },
    { filterId: CandidateFilterEnum.FavoriteCandidate, filter: "Favorite candidates" },
  ];
  return filtersListForCandidatePool;
}
export function getCandidatePoolFilterId(filter) {
  switch (filter) {
    case "Favorite candidates":
      return CandidateFilterEnum.FavoriteCandidate;
    case "Candidates shared to others":
      return CandidateFilterEnum.CandidateSharedToOthers;
  }
}

export function workTitleAndCompany(workTitle, workCompany) {
  if (!_.isNull(workTitle) && !_.isNull(workCompany)) {
    return workTitle + ", " + workCompany;
  }
  else if (_.isNull(workTitle) && _.isNull(workCompany)) {
    return "";
  }
  else if (!_.isNull(workTitle)) {
    return workTitle;
  }
  else if (!_.isNull(workCompany)) {
    return workCompany;
  }
}

export function getCheckedData(list) {
  return list.filter(item => item.checked);
}

export function getJobUsersByRole(usersList: JobUser[]) {
  var jobUserListByRole: JobUserByRole[] = [];
  var usersByRole = _.groupBy(usersList, function (user) { return user.role });
  var roles = _.keys(usersByRole);

  for (var i = 0; i < roles.length; i++) {
    var jobUserList = usersList.filter(u => u.role == roles[i]);
    var user = new JobUserByRole();
    user.role = roles[i].toString();
    user.jobUsers = jobUserList;
    jobUserListByRole.push(user);
  }

  return jobUserListByRole;
}

export function numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

export function replaceSpecialCharacters(str: string) {
  if (str == null || str == undefined || str == '')
    return str;
  let newString = "";
  for (let i = 0; i < str.length; i++) {
    if ("%_[".includes(str[i])) {
      newString = newString + '[' + str[i] + ']';
    } else {
      newString += str[i]
    }
  }
  return newString;
}

export function setSessionDataForProfileNavigation(profileId, isCompany, isFromNetworkTab) {
  sessionStorage.setItem("profileId", profileId);
  sessionStorage.setItem("isCompany", isCompany);
  sessionStorage.setItem("isFromNetworkTab", isFromNetworkTab);
}

export function openInNewTab(url) {
  window.open(url, "_blank");
}
export function isValidObject(value) {
  return !_.isNull(value) && !_.isEmpty(value) && !_.isUndefined(value);
}

export function isValidString(value) {
  return !_.isNull(value) && !_.isEmpty(value) && !_.isUndefined(value);
}

export function isValidValue(value) {
  return !_.isNull(value) && !_.isUndefined(value);
}

