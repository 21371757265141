import { User } from '../usermodel/user';
import { Company } from '../companymodel/company';
import { Roles } from '../rolesmodel/roles';
import { NotificationSetting } from '../notificationSettings/notification-settings';

export class CompanyUser {
  companyUserId: number;
  userId: number;
  userFirstName: string = '';
  userLastName: string = '';
  companyUser: string = '';
  companyId: number;
  companyUserEmailId: string = '';
  companyUserContactNumber: string = '';
  isActive: boolean = false;
  createdBy: number;
  createdDate: Date = new Date();
  modifiedBy: number;
  modifiedDate: Date = new Date();
  userRole: string;//PR 3302: added userrole
  user: User = new User();
  company: Company = new Company();
  companyUserRole: Roles = new Roles();
  notificationSetting: NotificationSetting = new NotificationSetting();
  loggedInEmailId: string; //PR 3303:added loggedInEmailId
  personalContactNumber: string; //PR 3303:added personalContactNumber
  appTimeZone: string;
  appTimezoneOffset: string;
  appTimezoneOffsetInMinutes: number;
  userFullName: string;
  hasAdminPrivilege: boolean;
  companyTypeId: number;
  userOnboardedDate: string;
}
