import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { JobSearchFiltersRequest } from '../models/job-search-filters-request';
import { CandidateSearchFiltersRequest } from '../models/candidate-search-filters-request';
import { LocationsRequest } from '../models/locations-request';
import * as commonFunctions from 'src/app/helpers/commonFunctions';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private apiURL: string = environment.apiURL;
  private apiController: string = "v1/search";
  locations: Location[];
  constructor(private httpClient: HttpClient) { }
  public getLocations(searchKey: string) {
    var locationsRequest = new LocationsRequest();
    locationsRequest.searchKey = searchKey;
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/locations`, locationsRequest);
  }

  public getSkills(searchKey: string) {
    return this.httpClient.get(`${this.apiURL}/${this.apiController}/skills/${searchKey}`);
  }

  public getCandidatesForCandidateFilters(candidateSearchFilters: CandidateSearchFiltersRequest) {
    var candidateFilters = JSON.parse(JSON.stringify(candidateSearchFilters));
    candidateFilters.searchKeywords = commonFunctions.replaceSpecialCharacters(candidateFilters.searchKeywords);
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/getCandidatesForFilters`, candidateFilters);
  }

  public getJobSearchFilters(jobSearchFilters: JobSearchFiltersRequest) {
    var jobsFilters = JSON.parse(JSON.stringify(jobSearchFilters));
    jobsFilters.searchKeyword = commonFunctions.replaceSpecialCharacters(jobsFilters.searchKeyword);
    jobsFilters.jobDescription = commonFunctions.replaceSpecialCharacters(jobsFilters.jobDescription);
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/advancedJobSearchFilters`, jobsFilters);
  }

  public getLocationsForAdvancedSearch(searchKey: string) {
    var locationsRequest = new LocationsRequest();
    locationsRequest.searchKey = searchKey;
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/locations-for-advanced-search`, locationsRequest);
  }
}
