import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as ChatMessage from 'src/app/components/chat-component/ng-chat/core/message'
import { Attachments } from 'src/app/models/attachments/attachments';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  apiURL: string = environment.apiURL;

  private userImageUpdated = new Subject<any>();
  userImageUpdated$ = this.userImageUpdated.asObservable();
  private updateChatMessageNotifications = new Subject<any>();
  updateChatMessageNotifications$ = this.updateChatMessageNotifications.asObservable();
  private updateCommentsCount = new Subject<any>();
  updateCommentsCount$ = this.updateCommentsCount.asObservable();
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  constructor(private httpClient: HttpClient) { }
  public getUsersConnectedConnection() {
    return this.httpClient.get(`${this.apiURL}/getUsersConnectedConnection/`);
  }
  public sendUserMessage(message: ChatMessage.Message) {
    return this.httpClient.post(`${this.apiURL}/privateChat/sendUserMessage/`, message);
  }
  public recieveUserMessage(destinataryId: number) {
    this.updateChatMessageNotifications.next(destinataryId);
    return this.httpClient.get(`${this.apiURL}/privateChat/recieveUserMessage/${destinataryId}`);
  }
  public insertUserPost(userPost: any) {
    return this.httpClient.post(`${this.apiURL}/insertUserPost/`, userPost);
  }
  public getUserPost(pageNumber: number, pageSize: number) {
    return this.httpClient.get(`${this.apiURL}/getUserPost/${pageNumber}/${pageSize}`);
  }
  public getHomePageFavouriteJobs() {
    return this.httpClient.get(`${this.apiURL}/getHomePageFavouriteJobs`);
  }
  public getHomePageFavouriteCandidates() {
    return this.httpClient.get(`${this.apiURL}/getHomePageFavouriteCandidates`);
  }
  public getHomePageSharedJobs() {
    return this.httpClient.get(`${this.apiURL}/getHomePageSharedJobs`);
  }
  public getHomePageSharedCandidates() {
    return this.httpClient.get(`${this.apiURL}/getHomePageSharedCandidates`);
  }
  public deletePost(postId: number, postImage: Attachments) {
    return this.httpClient.post(`${this.apiURL}/deletePost/${postId}`, postImage);
  }
  public getBlobConfigParameters(image: Attachments) {
    return this.httpClient.post(`${this.apiURL}/getBlobConfigParameters/`, image);
  }
  public userImageUpdateEvent(userImage: any) {
    this.userImageUpdated.next(userImage);
  }

  public getMessageNotifications() {
    return this.httpClient.get(`${this.apiURL}/privateChat/GetMessageNotifications`);
  }
  public updatePost(userPost: any) {
    return this.httpClient.post(`${this.apiURL}/updatePost/`, userPost);
  }
  public postLike(postLikeRequest: any) {
    return this.httpClient.post(`${this.apiURL}/postLike/`, postLikeRequest);
  }
  public unLikePost(unLikePostRequest: any) {
    return this.httpClient.post(`${this.apiURL}/unLikePost/`, unLikePostRequest);
  }
  public getPostLikedUsers(getPostLikedUsersRequest: any) {
    if (!getPostLikedUsersRequest.isLoadOnScroll) //To exclude main loader calling different route on scroll
      return this.httpClient.post(`${this.apiURL}/getPostLikedUsers/`, getPostLikedUsersRequest);
    else
      return this.httpClient.post(`${this.apiURL}/getPostLikedUsersOnScroll/`, getPostLikedUsersRequest);
  }

  public addPostComment(addCommentRequest: any) {
    return this.httpClient.post(`${this.apiURL}/addPostComment/`, addCommentRequest);
  } 
  public getComments(getcommentsRequest: any) {
    return this.httpClient.post(`${this.apiURL}/getPostComments/`, getcommentsRequest);
  }

  public updatePostComment(updatePostCommentRequest: any) {
    return this.httpClient.post(`${this.apiURL}/updatePostComment/`, updatePostCommentRequest);
  }
  public deleteComment(deletePostCommentRequest: any) {
    return this.httpClient.post(`${this.apiURL}/deletePostComment/`, deletePostCommentRequest);
  }

  public getPostCommentReplies(getcommentRepliesRequest: any) {
    return this.httpClient.post(`${this.apiURL}/getPostCommentReplies/`, getcommentRepliesRequest);
  }

  public updateCommentsCountFromReplies(dataObject: object) {
    this.updateCommentsCount.next(dataObject);
  }

}
