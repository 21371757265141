import { Injectable } from '@angular/core';
import { NgxImageCompressService, DOC_ORIENTATION } from 'ngx-image-compress';
import { messages } from 'src/app/constants/messages';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class ImageCompressService {

  constructor(private imageCompress: NgxImageCompressService) { }

  async compressUrl(url: string) {
    return Promise.resolve(this.imageCompress.compressFile(url, -1, 75, 50).then(
      result => {
        return result;
      }).catch(function (e) {
        Swal.fire({ title: messages.ERROR_MESSAGE, type: 'error', showConfirmButton: true });
      }));
  }
  async getNewImage(url: string, fileName: string) {
    try {
      const result = await this.compressUrl(url);
      var imageBlob = this.dataURItoBlob(result);
      const imageFile = this.convertBlobToImageFile(imageBlob, fileName);
      return imageFile;
    } catch (e) {
      console.log(e);
    }
  }

  private convertBlobToImageFile(theBlob: Blob, fileName: string): File {
    const blobFile: any = theBlob;
    blobFile.lastModifiedDate = new Date();
    blobFile.name = fileName;
    return <File>blobFile;
  }

  dataURItoBlob(dataURI) {
    var dataURI = dataURI.split(";base64,")[1];
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }
}
