import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GlobalSearchRequest } from 'src/app/models/globalSearch/global-search-request';
import { GetProfilePageDetailsRequest } from 'src/app/models/Profile/get-profile-page-details-request';
import { InsertConnectionRequest } from 'src/app/models/connection/insert-connection-request';
import { UpdateConnectionStatusRequest } from 'src/app/models/connection/update-connection-status-request';
import { ConnectionProfile } from 'src/app/models/connection/connection-profile';

@Injectable({
  providedIn: 'root'
})

export class NetworkService {
  apiURL: string = environment.apiURL;
  private updateSentConnections = new Subject<any>();
  private getMyConnectionsForSentRequest = new Subject<any>();
  private getPostsAndSharedJobsCount = new Subject<any>();
  private popChatNotifications = new Subject<any>();
  private getLatestSuggestions = new Subject<any>();
  updateSentConnections$ = this.updateSentConnections.asObservable();
  getMyConnectionsForSentRequest$ = this.getMyConnectionsForSentRequest.asObservable();
  getPostsAndSharedJobsCount$ = this.getPostsAndSharedJobsCount.asObservable();
  popChatNotifications$ = this.popChatNotifications.asObservable();
  getLatestSuggestions$ = this.getLatestSuggestions.asObservable();
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  constructor(private httpClient: HttpClient) { }

  public getGlobalSearchResults(globalSearchRequest: GlobalSearchRequest) {
    return this.httpClient.post(`${this.apiURL}/globalSearch`, globalSearchRequest);
  }

  public getProfilePageDetailsRequest(getProfilePageDetailsReq: GetProfilePageDetailsRequest) {
    return this.httpClient.post(`${this.apiURL}/getProfilePageDetails`, getProfilePageDetailsReq);
  }

  public insertConnection(insertCnnectionReq: InsertConnectionRequest) {
    return this.httpClient.post(`${this.apiURL}/insertConnection`, insertCnnectionReq);
  }

  public updateConnectionStatus(updateConnectionReq: UpdateConnectionStatusRequest) {
    return this.httpClient.post(`${this.apiURL}/updateConnectionStatus`, updateConnectionReq);
  }

  public getSentConnection() {
    return this.httpClient.get(`${this.apiURL}/getSentConnection`);
  }

  public getReciviedConnection() {
    return this.httpClient.get(`${this.apiURL}/getReceivedConnection`);
  }
  public getNetworkSuggestionsForUser(isTopFive: boolean) {
    return this.httpClient.get(`${this.apiURL}/getNetworkSuggestionsForUser/${isTopFive}`).pipe(map(this.extractData));
  }

  public getNetworkSuggestionsForCompany() {
    return this.httpClient.get(`${this.apiURL}/getNetworkSuggestionsForCompany`).pipe(map(this.extractData));
  }

  public getSentConnectionsForSuggestions() {
    this.updateSentConnections.next();
  }

  public getMyConnectionswithSentRequest(myConnections: ConnectionProfile[]) {
    this.getMyConnectionsForSentRequest.next(myConnections);
  }

  public getPostsAndSharedJobs(dataObject: object) {
    this.getPostsAndSharedJobsCount.next(dataObject);
  }
  public popChatNotificationsForDisconnectedFromUser(dataObject: object) {
    this.popChatNotifications.next(dataObject);
  }

  public getSuggestions() {
    this.getLatestSuggestions.next();
  }
}
