import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { GlobalContentService } from 'src/app/services/global/global-content.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { User } from 'src/app/models/usermodel/user';
import Swal from 'sweetalert2';
import { LocalStorageService } from 'angular-web-storage';
import { ClearsearchService } from 'src/app/services/ClearSearch/clearsearch.service';
import { UserAgentApplication } from 'msal';
import { CompanyUser } from 'src/app/models/companyusermodel/companyuser';
import { messages } from 'src/app/constants/messages';
import { Linkedinparams } from 'src/app/models/linkedinparams';
import { ManagementService } from 'src/app/services/management/management.service';
import * as CryptoJS from 'crypto-js';
import { sessionStorageKeyConstants } from 'src/app/constants/sessionStorageKeyConstants';

@Component({
  selector: 'app-sociallogin',
  templateUrl: './sociallogin.component.html',
  styleUrls: ['./sociallogin.component.css']
})

export class SocialloginComponent implements OnInit {

  userAgentApplication;
  public loggedInUser: SocialUser;
  @Output() actionPerformed = new EventEmitter();
  showTemplogin: boolean = false;
  email: string;
  isCompanyRegistration: boolean = false;
  showAccountType: boolean = false;
  isFreelancer: boolean = false;
  loginEmailId: string;
  linkedInAuthCode = '';
  MSAuthCode = '';
  regLoginEmailId: CompanyUser = new CompanyUser();
  regUserSocialData: SocialUser;
  isFromRegistration: boolean = false;
  appConfigData: any;
  activationCheck: number;
  isCompanySwitchConfirmed: boolean = false;
  firstName: string = '';
  lastName: string = '';
  isUseractivation: boolean = false;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private globalContentService: GlobalContentService,
    private router: Router,
    private localStorage: LocalStorageService,
    private clearService: ClearsearchService,
    private loginService: LoginService,
    private managementService: ManagementService) {
  }

  ngOnInit() {
    let loggedInUser = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser);
    if (loggedInUser != null && loggedInUser != undefined) {
      this.router.navigate(['/Home']);
    }
    else {
      this.linkedInAuthCode = this.localStorage.get("LinkedInAuthCode");
      this.localStorage.remove("LinkedInAuthCode");
      this.clearService.clearLogin(false);
      this.appConfigData = require("appsettings.json");
      if (this.linkedInAuthCode !== '' && this.linkedInAuthCode != null) {
        var linkedInParmas = new Linkedinparams();
        linkedInParmas.authCode = this.linkedInAuthCode;
        this.loginService.linkedInLogin(linkedInParmas).subscribe((data: any) => {
          var user = new SocialUser;
          user.firstName = data.firstName;
          user.lastName = data.lastName;
          user.email = data.emailAddress;
          var isFromAccountActivation = JSON.parse(this.localStorage.get("isFromAccountActivation"));
          if (isFromAccountActivation !== null && isFromAccountActivation) {
            this.loginEmailId = user.email;
            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.localStorage.set("dataFromActivation", JSON.stringify(user));
            var guid = this.localStorage.get("userInviteGuid");
            this.router.navigate(['/CompanyUserActivation/', guid]);
          }
          else if (JSON.parse(this.localStorage.get("isFromRegistration"))) {
            sessionStorage.setItem("isFreelancer", JSON.stringify(this.isFreelancer));
            var regUserSocialData = CryptoJS.AES.encrypt(JSON.stringify(user), this.appConfigData.regUserSocialDataEncryptionKey);
            sessionStorage.setItem(sessionStorageKeyConstants.regUserSocialData, regUserSocialData);
            this.router.navigate(['/Registration']);
          }
          else {
            this.verifyUserLogin(user);
          }
          this.localStorage.remove("isFromRegistration");
          sessionStorage.removeItem("isFromAccountActivation");
        }, (err) => {
          Swal.fire({ title: messages.LINKEDIN_LOGIN_FAILED, type: 'error', showConfirmButton: true });
        });
      }
    }
  }

  public socialSignIn(socialPlatform: string, redirectToRegistration: boolean) {
    sessionStorage.setItem("isFromExternalInvitation", JSON.stringify(false));
    this.localStorage.remove("externalInviteCode");
    let socialPlatformProvider;
    sessionStorage.setItem("socialPlatform", socialPlatform);
    if (socialPlatform === 'Microsoft') {
      const msalConfig = {
        auth: {
          clientId: this.appConfigData.MicrosoftClientId,
          redirectUri: window.location.origin
        }
      };
      this.userAgentApplication = new UserAgentApplication(msalConfig);
      var request = {
        scopes: ["user.read"],
        prompt: 'select_account'
      }
      const that = this;
      that.userAgentApplication.loginPopup(request).then((response: any) => {
        if (response != null) {
          this.loggedInUser = new SocialUser();
          this.loggedInUser.email = response.account.userName;
          this.loggedInUser.firstName = response.account.name;
          this.loggedInUser.lastName = '';
          this.userAgentApplication.clearCache();
          if (redirectToRegistration) {
            var regUserSocialData = CryptoJS.AES.encrypt(JSON.stringify(this.loggedInUser), this.appConfigData.regUserSocialDataEncryptionKey);
            sessionStorage.setItem(sessionStorageKeyConstants.regUserSocialData, regUserSocialData);
            this.router.navigate(['/Registration']);
          }
          else
            this.verifyUserLogin(this.loggedInUser);
        }
      }).catch(err => {/*don't take any action*/ });
    } else if (socialPlatform === 'Google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      this.authService.signIn(socialPlatformProvider).then(
        (userData) => {
          if (userData != null) {
            this.authService.signOut(true);
            if (redirectToRegistration) {
              this.regUserSocialData = userData;
              sessionStorage.setItem("isFreelancer", JSON.stringify(this.isFreelancer));
              var regUserSocialData = CryptoJS.AES.encrypt(JSON.stringify(userData), this.appConfigData.regUserSocialDataEncryptionKey);
              sessionStorage.setItem(sessionStorageKeyConstants.regUserSocialData, regUserSocialData);
              this.router.navigate(['/Registration']);
            }
            else
              this.verifyUserLogin(userData);
          }
        }
      ).catch(err => {/*don't take any action*/ });
    }
    else if (socialPlatform === "LinkedIn") {

      this.localStorage.set("isFromRegistration", JSON.stringify(redirectToRegistration));

      //check this https://stackoverflow.com/questions/24801124/how-to-make-window-open-pop-up-modal

      window.open(this.appConfigData.linkedInConfig.baseUrl + this.appConfigData.linkedInConfig.authorizationUrl + "?response_type=code&client_id=" + this.appConfigData.linkedInConfig.clientId + "&redirect_uri=" + this.appConfigData.linkedInConfig.redirectUri + "&scope=r_liteprofile%20r_emailaddress", "_self");
    }
  }
  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  verifyUserLogin(userData: SocialUser) {
    var user = new User();
    user.firstName = userData.firstName;
    user.lastName = userData.lastName;
    user.loginEmailId = userData.email;
    this.loginService.getUserLoginAccounts(userData.email).subscribe(async (data: any) => {
      if (data.isBlocked) {
        Swal.fire({
          title: messages.USER_IS_BLOCKED_ALERT,
          html: messages.CONTACT_ADMIN + '<a href="mailto:' + this.appConfigData.SupportEmail + '">' + this.appConfigData.SupportEmail + '</a>',
          type: 'warning'
        })
        this.globalContentService.setLoggedInUser(null);
        await sessionStorage.clear();
      }
      else if (data.isAuthorized) {
        if (data.userAccounts.length > 1) {
          sessionStorage.setItem("chooseAccountsData", JSON.stringify(data.userAccounts));
          this.router.navigate(['/ChooseAccount']);
        }
        else {
          this.loginService.getLoggedInUserByCompanyId(data.userAccounts[0].companyUserId).subscribe(async (response: any) => {
            await sessionStorage.clear();
            let loggedInUser = JSON.stringify(response.item1.companyUser)
            loggedInUser = CryptoJS.AES.encrypt(loggedInUser, this.appConfigData.LoggedInUserEncryptionKey);
            sessionStorage.setItem(sessionStorageKeyConstants.loggedInCompanyUser, loggedInUser);
            this.globalContentService.setLoggedInUser(userData);
            var accessToken = CryptoJS.AES.encrypt(response.item2.accessToken, this.appConfigData.APIAuthorizationKey);
            sessionStorage.setItem(sessionStorageKeyConstants.authToken, JSON.stringify(accessToken.toString()));
            var expiryTime = CryptoJS.AES.encrypt(response.item2.expireDateTimeUtc, this.appConfigData.sessionExpiryTimeEncryptionKey);
            sessionStorage.setItem(sessionStorageKeyConstants.expireDateTime, expiryTime);
            let companySettings = CryptoJS.AES.encrypt(JSON.stringify(response.item1.companySettings), this.appConfigData.companySettingsEncryptionKey);
            sessionStorage.setItem(sessionStorageKeyConstants.companySettings, companySettings);
            let userPrivacySettings = CryptoJS.AES.encrypt(JSON.stringify(response.item1.userPrivacySettings), this.appConfigData.userPrivacySettingsEncryptionKey);
            sessionStorage.setItem(sessionStorageKeyConstants.userPrivacySettings, userPrivacySettings);
            this.router.navigate(['/Home']);
          }, (err) => {
            Swal.fire({
              title: messages.SOMETHING_WENT_WRONG,
              html:
                messages.CONTACT_ADMIN +
                '<a href="mailto:' + this.appConfigData.SupportEmail + '">' + this.appConfigData.SupportEmail + '</a>',
              type: 'warning'
            })
          });
        }
      }
      else {
        Swal.fire({ text: messages.REGISTRATION_ALERT, type: 'warning', showConfirmButton: true });
        this.globalContentService.setLoggedInUser(null);
        await sessionStorage.clear();
      }
    }, (err) => {
      Swal.fire({
        title: messages.SOMETHING_WENT_WRONG,
        html:
          messages.CONTACT_ADMIN +
          '<a href="mailto:' + this.appConfigData.SupportEmail + '">' + this.appConfigData.SupportEmail + '</a>',
        type: 'warning'
      })
    });
  }

  tempLogin(email) {
    var user = new SocialUser();
    user.email = email;
    user.firstName = "";
    user.lastName = "";
    this.verifyUserLogin(user);
  }

  signUpASCompanpy(isFreelancer) {
    this.socialSignIn('Google', true);
    this.showAccountType = false;
    this.isFreelancer = isFreelancer;
  }

  redirectLogin() {
    this.router.navigate(['/login']);
    this.isUseractivation = false;
  }

  
}
