import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SocialUser, AuthService } from 'angularx-social-login';
import { GlobalContentService } from './services/global/global-content.service';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { ClearsearchService } from './services/ClearSearch/clearsearch.service';
import Swal from 'sweetalert2';
import { LocalStorageService } from 'angular-web-storage';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { UserAgentApplication } from 'msal/lib-commonjs/UserAgentApplication';
import { SignalrService } from './services/signalR/signalr.service';
import { IChatController } from 'src/app/components/chat-component/ng-chat/core/chat-controller';
import { ChatAdapter } from 'src/app/components/chat-component/ng-chat/core/chat-adapter';
import { IChatParticipant } from 'src/app/components/chat-component/ng-chat/core/chat-participant';
import { ChatNotificationModel } from './models/chatNotoficationModel/chat-notification-model';
import { DashboardService } from './services/dashboard/dashboard.service';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { Location, DatePipe } from '@angular/common';
import { ConnectionService } from 'ng-connection-service';
import { LoginService } from './services/login.service';
import { sessionStorageKeyConstants } from './constants/sessionStorageKeyConstants';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {

  loggedInUser: SocialUser;
  isUserLoggedIn = false;
  selectedNavigationTab: string;
  fields: any = ['id', 'address', 'img'];
  myStyle: object = {};
  width: number = 100;
  height: number = 100;
  usersConnected = [];
  @ViewChild('userChatInstance')
  protected userChatInstance: IChatController;
  chatNotifications = [];
  activeChatDialogIds = [];
  public adapter: ChatAdapter;
  title = "Chat";
  userId = 0;
  location: Location;
  private userStatusUpdateSubscription: any;
  private updatedUserConnectionsSubscription: any;
  private openChatnotificationSubscription: any;
  private receiveUserMessageSubscription: any;
  private updatedUsersListSubscription: any;
  private onGetChatnotificationSubscription: any;
  appConfigData = require("appsettings.json");

  constructor(private router: Router,
    private cdRef: ChangeDetectorRef,
    private ngxService: NgxUiLoaderService,
    private localStorage: LocalStorageService,
    private globalService: GlobalContentService,
    private clearService: ClearsearchService,
    private idle: Idle,
    private keepalive: Keepalive,
    private activatedRoute: ActivatedRoute,
    private signalRService: SignalrService,
    private dashboardService: DashboardService,
    private titleService: Title,
    private loc: Location,
    private networkConnectivityService: ConnectionService,
    private loginService: LoginService
  ) {
    const url = window.location.href;
    let paramValue;
    this.titlesChange();
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get("code");
      if (paramValue)
        this.localStorage.set("LinkedInAuthCode", paramValue);
      else {
        var isFromActivation = JSON.parse(this.localStorage.get("isFromAccountActivation"));
        this.localStorage.remove("isFromAccountActivation");
        var isFromExternalActivation = JSON.parse(this.localStorage.get("isFromExternalInvitation"));
        this.localStorage.remove("isFromExternalInvitation");
        if (isFromActivation) {
          var guid = this.localStorage.get("userInviteGuid");
          this.localStorage.remove("userInviteGuid");
          this.router.navigate(['/CompanyUserActivation/', guid]);
        }
        else if (isFromExternalActivation) {
          var guid = JSON.parse(this.localStorage.get("externalInviteCode"));
          this.localStorage.remove("externalInviteCode");
          this.router.navigate(['/Invitations/Activation/', guid]);
        }
        else {
          this.router.navigate(["/login"]);
        }
        return;
      }
    }
    else if (location.hash.includes("id_token")) {
      const msalConfig = {
        auth: {
          clientId: this.appConfigData.MicrosoftClientId,
        }
      };
      new UserAgentApplication(msalConfig);
    }

    this.adapter = new SignalrService(this.dashboardService);
    this.globalService.IsLoggedIn$.subscribe((loggedInUserData) => {
      if (loggedInUserData) {
        let loggedInUserData = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser);
        if (loggedInUserData) {
          var bytes = CryptoJS.AES.decrypt(loggedInUserData, this.appConfigData.LoggedInUserEncryptionKey);
          loggedInUserData = bytes.toString(CryptoJS.enc.Utf8);
        }
        this.userId = parseInt(JSON.parse(loggedInUserData).userId);
      }
      else {
        this.isUserLoggedIn = false;
        this.idle.stop();
      }
    });
    let loggedInUser = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser);
    let regUserSocialData = sessionStorage.getItem(sessionStorageKeyConstants.regUserSocialData);
    if (regUserSocialData) {
      var bytes = CryptoJS.AES.decrypt(regUserSocialData, this.appConfigData.regUserSocialDataEncryptionKey);
      regUserSocialData = bytes.toString(CryptoJS.enc.Utf8);
    }
    let regUser = JSON.parse(regUserSocialData);
    if ((loggedInUser != null && loggedInUser != undefined) || (regUser != null && regUser != undefined)) {
      if (loggedInUser != null && loggedInUser != undefined)
        this.globalService.setLoggedInUser(loggedInUser);
      let currentUrl = sessionStorage.getItem(sessionStorageKeyConstants.currentUrl);
      if (currentUrl === "")
        this.router.navigate(['/Home']);
      this.configureIdleTimeOut();
    }
    else {
      sessionStorage.clear();
      this.clearService.clearLogin(false);
    }
  }

  configureIdleTimeOut() {
    this.idle.setIdle(2);
    this.idle.setTimeout(3600);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => {
      this.idle.watch();
    });
    this.idle.onTimeout.subscribe(async () => {
      if (this.router.url !== "/" && !this.router.url.includes('Activation')) {
        await this.globalService.setLoggedInUser(null);
        await this.loginService.unSubscribeUserFromSignalR(this.userId).subscribe(async (data: any) => {
          await this.signalRService.stopConnection();
        });
        await sessionStorage.clear();
        this.router.navigate(["/login"]);
        Swal.fire({
          title: "Your session is expired. Please login again.",
          text: '',
          type: 'success',
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
    });
    this.keepalive.interval(60);
    this.idle.onIdleStart.subscribe(() => {
      //Do nothing as we need to show only a single alert, after session timeout.
    });
    this.reset();
  }

  handleActiveChatParticipants(event: any, isChatOpened: boolean) {
    var activeIds = JSON.parse(sessionStorage.getItem("activeChatDialogs"));
    this.activeChatDialogIds = activeIds == null ? [] : activeIds;
    if (isChatOpened && this.activeChatDialogIds.indexOf(event.id) < 0)
      this.activeChatDialogIds.push(event.id);
    else if (!isChatOpened)
      this.activeChatDialogIds = this.activeChatDialogIds.filter(dialogId => dialogId != event.id);
    sessionStorage.setItem("activeChatDialogs", JSON.stringify(this.activeChatDialogIds));
  }

  reset() {
    this.idle.watch();
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  ngOnInit() {
    this.globalService.IsLoggedIn$.subscribe(item => {
      this.isUserLoggedIn = (!!item == true);
      if (this.isUserLoggedIn) {
        this.configureIdleTimeOut();
      }
      else {
        this.idle.stop();
      }
    });
    let loggedInUserData = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser);
    if (loggedInUserData) {
      var bytes = CryptoJS.AES.decrypt(loggedInUserData, this.appConfigData.LoggedInUserEncryptionKey);
      loggedInUserData = bytes.toString(CryptoJS.enc.Utf8);
    }
    let loggedInCompanyUser = JSON.parse(loggedInUserData);
    this.isUserLoggedIn = loggedInCompanyUser == null ? false : true;
    this.clearService.getIsLogin()
      .subscribe(item => {
        this.isUserLoggedIn = item
      });
    this.userId = parseInt(loggedInCompanyUser == null ? "0" : loggedInCompanyUser.userId);
    this.userStatusUpdateSubscription = this.signalRService.userStatusUpdate$.subscribe(
      (statusUpdate: any) => {
        setTimeout(() => {
          let usersConnectedList = sessionStorage.getItem(sessionStorageKeyConstants.UsersConnectedList);
          if (usersConnectedList) {
            var bytes = CryptoJS.AES.decrypt(usersConnectedList, this.appConfigData.chatParticipantsEncryptionKey);
            usersConnectedList = bytes.toString(CryptoJS.enc.Utf8);
          }
          this.usersConnected = JSON.parse(usersConnectedList);
          if (this.usersConnected != undefined && this.usersConnected != null && this.usersConnected.length > 0) {
            let user = this.usersConnected.find(x => x.participant.id == statusUpdate.id);
            if (user != null && user != undefined) {
              var updatedUserIndex = this.usersConnected.indexOf(user);
              user.participant.status = statusUpdate.status ? 0 : 3;
              this.usersConnected[updatedUserIndex] = user;
              let participantsList = JSON.stringify(this.usersConnected)
              participantsList = CryptoJS.AES.encrypt(participantsList, this.appConfigData.chatParticipantsEncryptionKey);
              sessionStorage.setItem(sessionStorageKeyConstants.UsersConnectedList, participantsList);
              this.adapter.onFriendsListChanged(this.usersConnected);
            }
          }
        }, 500);
      });

    this.updatedUserConnectionsSubscription = this.signalRService.updatedUserConnections$.subscribe(
      (updatedConnection: any) => {
        setTimeout(() => {
          let usersConnectedList = sessionStorage.getItem(sessionStorageKeyConstants.UsersConnectedList);
          if (usersConnectedList) {
            var bytes = CryptoJS.AES.decrypt(usersConnectedList, this.appConfigData.chatParticipantsEncryptionKey);
            usersConnectedList = bytes.toString(CryptoJS.enc.Utf8);
          }
          this.usersConnected = JSON.parse(usersConnectedList)
          switch (updatedConnection.isConnected) {
            case true:
              if (updatedConnection.connectionSender.participant.id == this.userId && this.usersConnected.filter(x => x.participant.id == updatedConnection.connectionAcceptor.participant.id).length == 0) {
                this.usersConnected.push(updatedConnection.connectionAcceptor);
              }
              else if (updatedConnection.connectionAcceptor.participant.id == this.userId && this.usersConnected.filter(x => x.participant.id == updatedConnection.connectionSender.participant.id).length == 0) {
                this.usersConnected.push(updatedConnection.connectionSender)
              }
              break;
            case false:
              this.userChatInstance.triggerCloseChatWindow(updatedConnection.connectionSender.participant.id);
              this.userChatInstance.triggerCloseChatWindow(updatedConnection.connectionAcceptor.participant.id);
              this.usersConnected = this.usersConnected.filter(x => (x.participant.id != updatedConnection.connectionSender.participant.id && x.participant.id != updatedConnection.connectionAcceptor.participant.id));
              break;
          }
          let participantsList = JSON.stringify(this.usersConnected)
          participantsList = CryptoJS.AES.encrypt(participantsList, this.appConfigData.chatParticipantsEncryptionKey);
          sessionStorage.setItem(sessionStorageKeyConstants.UsersConnectedList, participantsList);
          this.adapter.onFriendsListChanged(this.usersConnected);
        }, 500);
      });

    this.openChatnotificationSubscription = this.signalRService.openChatnotification$.subscribe((participant: IChatParticipant) => {
      this.userChatInstance.triggerOpenChatWindow(participant);
    })

    this.receiveUserMessageSubscription = this.signalRService.receiveUserMessage$.subscribe(
      (message: any) => {
        setTimeout(() => {
          let usersConnectedList = sessionStorage.getItem(sessionStorageKeyConstants.UsersConnectedList);
          if (usersConnectedList) {
            var bytes = CryptoJS.AES.decrypt(usersConnectedList, this.appConfigData.chatParticipantsEncryptionKey);
            usersConnectedList = bytes.toString(CryptoJS.enc.Utf8);
          }
          this.usersConnected = JSON.parse(usersConnectedList)
          var datePipe = new DatePipe(message.dateSent.toString());
          let loggedInUserData = sessionStorage.getItem(sessionStorageKeyConstants.loggedInCompanyUser);
          if (loggedInUserData) {
            var bytes = CryptoJS.AES.decrypt(loggedInUserData, this.appConfigData.LoggedInUserEncryptionKey);
            loggedInUserData = bytes.toString(CryptoJS.enc.Utf8);
          }
          var currentLoggedInUser = JSON.parse(loggedInUserData);
          message.dateSent = datePipe.transform(message.dateSent.toString(), 'M/d/yy h:mm a', currentLoggedInUser.appTimezoneOffset, window.navigator.language);
          if (this.usersConnected != undefined && this.usersConnected != null && this.usersConnected.length > 0) {
            let user = this.usersConnected.find(x => x.participant.id == message.fromId);
            if (user != null && user != undefined) {
              if (user.participant.status == 3) {
                var updatedUserIndex = this.usersConnected.indexOf(user);
                user.participant.status = 0;
                this.usersConnected[updatedUserIndex] = user;
                let participantsList = JSON.stringify(this.usersConnected)
                participantsList = CryptoJS.AES.encrypt(participantsList, this.appConfigData.chatParticipantsEncryptionKey);
                sessionStorage.setItem(sessionStorageKeyConstants.UsersConnectedList, participantsList);
                this.adapter.onFriendsListChanged(this.usersConnected);
              }
              this.adapter.onMessageReceived(user.participant, message, this.activeChatDialogIds.indexOf(message.fromId.toString()) > -1);
              if (this.activeChatDialogIds.indexOf(message.fromId.toString()) == -1) {
                this.signalRService.newMessageReceivedAction(message);
              }
            }
          }
        }, 500);
      });

    this.updatedUsersListSubscription = this.signalRService.updatedUsersList$.subscribe(
      (participantsData: any) => {
        setTimeout(() => {
          let participantsList = JSON.stringify(participantsData)
          participantsList = CryptoJS.AES.encrypt(participantsList, this.appConfigData.chatParticipantsEncryptionKey);
          sessionStorage.setItem(sessionStorageKeyConstants.UsersConnectedList, participantsList);
          this.adapter.onFriendsListChanged(participantsData);
          this.dashboardService.getMessageNotifications().subscribe((notifications: ChatNotificationModel[]) => {
            this.adapter.onChatNotificationsGet(notifications);
          });
        }, 500);
      });

    this.onGetChatnotificationSubscription = this.signalRService.onGetChatnotification$.subscribe((chatNotifications: any) => {
      setTimeout(() => {
        this.adapter.onChatNotificationsGet(chatNotifications);
      }, 500);
    });

    this.networkConnectivityService.monitor().subscribe((isConnected: any) => {
      if (isConnected) {
      }
      else {
        if (this.isUserLoggedIn) {
          Swal.fire({
            title: "Your internet connection is interrupted. You will be logged out from Graph5.",
            text: '',
            type: 'warning',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async (result) => {
            await sessionStorage.clear();
            this.globalService.setLoggedInUser(null);
            this.signalRService.stopConnection();
            this.router.navigate(["/login"]);
          });
        }
      }
    });

    this.myStyle = {
      'position': 'fixed',
      'width': '100%',
      'height': '100%',
      'z-index': -1,
      'top': 0,
      'left': 0,
      'right': 0,
      'bottom': 0,
      'background-color': '#000000',
    };

  }

  ngOnDestroy(): void {
    if (!this.isUserLoggedIn)
      this.idle.stop();
    this.userStatusUpdateSubscription.unsubscribe();
    this.updatedUserConnectionsSubscription.unsubscribe();
    this.openChatnotificationSubscription.unsubscribe();
    this.receiveUserMessageSubscription.unsubscribe();
    this.updatedUsersListSubscription.unsubscribe();
    this.onGetChatnotificationSubscription.unsubscribe();
  }

  titlesChange() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.router))
      .subscribe((event) => {
        let loc = this.loc.path().split('/');
        this.selectedNavigationTab = this.loc.path().substring(1);
        let title = loc[1].includes('Activation') ? 'Activation' : loc[1].includes('Invitation') ? 'Invitation' : loc.length == 3 ? loc[2] : loc.length > 3 ? loc[3] : loc[1];
        if (title != undefined) {
          let splitTitle = title.split(/(?=[A-Z])/);
          title = splitTitle.length > 1 ? splitTitle[0] + " " + splitTitle[1] : splitTitle[0];
        }
        title = title == undefined ? `Graph5` : `Graph5 - ${title}`;
        if (title.includes('login')) {
          this.titleService.setTitle('Graph5');
        }
        setTimeout(() => {
          this.titleService.setTitle(title);
        }, 50);
      });
  }
}
