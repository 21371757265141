import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'checkbox-component',
  template: `<input class="cursor-pointer" type="checkbox" [(ngModel)]="rowData.isPreFinance" (ngModelChange)="onModelChange()"/>`//PR 3369 : for hand symbol.
})
export class GridCheckBoxComponent {
  rowData: any;
  value: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  onModelChange() {
    this.save.emit(this.rowData);
  }
}
