import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Candidatemodel } from 'src/app/models/candidatemodel/candidatemodel';
import { Attachments } from 'src/app/models/attachments/attachments';
import { CandidateNotesRequest } from '../../models/candidateNotes/candidate-notes';
import { OnboardedCandidateUpdate } from 'src/app/models/candidate/onboarded-candidate-update';
import { UpdateCandidateStatus } from 'src/app/models/candidate/update-candidate-status';
import { CandidateGridFilterByFields } from 'src/app/models/candidate/Grid/candidate-grid-filter-by-fields';
import { BlacklistCandidateRequest } from 'src/app/models/candidate/blacklist-candidate-request';
import { ShareUnshareRequest } from 'src/app/models/ShareUnshare/share-unshare-request';
import { PotentialCandidate } from 'src/app/models/potentialCandidate/PotentialCandidate';


@Injectable({
  providedIn: 'root'
})
export class CandidateService {
  apiURL: string = environment.apiURL;
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  constructor(private httpClient: HttpClient) { }

  public getCandidateGridMasterData(isArchived: boolean) {
    return this.httpClient.get(`${this.apiURL}/getCandidateGridMasterData/${isArchived}`);
  }

  public getCandiates(isPool: boolean, filterByFields: CandidateGridFilterByFields) {
    return this.httpClient.post(`${this.apiURL}/getCandiates/${isPool}`, filterByFields);
  }

  public getCandidatesNetwork(filterByFields: CandidateGridFilterByFields) {
    return this.httpClient.post(`${this.apiURL}/getCandidatesNetwork/`, filterByFields);
  }
  

  public getCandidatesArchived(filterByFields: CandidateGridFilterByFields) {
    return this.httpClient.post(`${this.apiURL}/getCandidatesArchived/`, filterByFields);
  }

  public getCandidatesOnboarded(filterByFields: CandidateGridFilterByFields) {
    return this.httpClient.post(`${this.apiURL}/getCandidatesOnboarded/`, filterByFields);
  }

  public archiveCandidate(candidate: UpdateCandidateStatus) {
    return this.httpClient.post(`${this.apiURL}/archiveCandidate/`, candidate);
  }

  public updateOnboardedCandidate(onboardedCandidate: OnboardedCandidateUpdate) {
    return this.httpClient.post(`${this.apiURL}/updateOnboardedCandidate/`, onboardedCandidate);
  }

  public getCandidateMasterData() {
    return this.httpClient.get(`${this.apiURL}/masterData/getCandidateMasterData`);
  }

  public insertCandidate(formData: any) {
    return this.httpClient.post(`${this.apiURL}/insertCandidate/`, formData);
  }
  public getCandidateDetails(candidateId: number, isNetworkCandidate: boolean, isNetworkSubmittedCandidte: boolean) {
    return this.httpClient.get(`${this.apiURL}/getCandidateDetails/${candidateId}/${isNetworkCandidate}/${isNetworkSubmittedCandidte}`);
  }

  public updateCandidate(candidate: Candidatemodel) {
    return this.httpClient.post(`${this.apiURL}/updateCandidate/`, candidate);
  }

  public markCandidateAsFavOrUnfav(isNetworkCandidate: boolean, candidateId: number, networkCandidateId: number) {
    return this.httpClient.get(`${this.apiURL}/markCandidateAsFavOrUnfav/${isNetworkCandidate}/${candidateId}/${networkCandidateId}`);
  }

  public addCandidateNotes(candidateNotes: CandidateNotesRequest) {
    return this.httpClient.post(`${this.apiURL}/addCandidateNotes`, candidateNotes);
  }

  public uploadFile(fileName: string, fileType: string, candidateId: number) {
    return this.httpClient.post(`${this.apiURL}/uploadFile/${fileName}/${fileType}/${candidateId}`, {});
  }

  public getFileUrl(file: Attachments) {
    return this.httpClient.post(`${this.apiURL}/getFileUrl/`, file);
  }

  public deleteFile(files: Attachments[], isResumeUpdated: boolean) {
    return this.httpClient.post(`${this.apiURL}/deleteFile/${isResumeUpdated}`, files);
  }

  public updateAttachments(formdata: any) {
    return this.httpClient.post(`${this.apiURL}/updateAttachments/`, formdata);
  }

  public getCandidateConnectionsForShare(shareType: number, candidateId: number) {
    return this.httpClient.get(`${this.apiURL}/getCandidateConnectionsForShare/${shareType}/${candidateId}`);
  }

  public shareOrUnshareCandidate(shareUnshareReq: ShareUnshareRequest) {
    return this.httpClient.post(`${this.apiURL}/shareUnshareCandidate/`, shareUnshareReq);
  }

  public importNetworkCandidate(candidateId: number, networkCandidateId: number) {
    return this.httpClient.post(`${this.apiURL}/importNetworkCandidate/${candidateId}/${networkCandidateId}`, {});
  }

  //bulk insert
  public uploadBulkCandidatesAts(formData: any) {
    return this.httpClient.post(`${this.apiURL}/uploadBulkCandidates/`, formData);
  }

  public blacklistCandidate(candidate: BlacklistCandidateRequest) {
    return this.httpClient.post(`${this.apiURL}/blacklistCandidate/`, candidate);
  }
  public getPotentialCandidates(filterByFields: PotentialCandidate) {
    return this.httpClient.post(`${this.apiURL}/getPotentialCandidates/`, filterByFields);
  }
  public insertPotentialCandidates(formData: any) {
    return this.httpClient.post(`${this.apiURL}/insertPotentialCandidates/`, formData);
  }
  public archivePotentialCandidate(candidateId: number) {
    return this.httpClient.post(`${this.apiURL }/archivePotentialCandidate/${candidateId}`, {});
  }
}
