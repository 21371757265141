<div *ngIf="options && options.length > 0" class="ng-chat-options">
  <button class="ng-chat-options-activator">
    <span class="primary-text">...</span>
  </button>
  <div class="ng-chat-options-content primary-background shadowed">
    <a *ngFor="let option of options; let i = index" [ngClass]="'primary-text'" (click)="onOptionClicked(option)">
      {{option.displayLabel}}
    </a>
  </div>      
</div>
