import { Injectable, EventEmitter, Output } from '@angular/core';
import { CompanyUser } from 'src/app/models/companyusermodel/companyuser';

@Injectable({
  providedIn: 'root'
})
export class ClearsearchService {
  @Output() fire: EventEmitter<any> = new EventEmitter();
  @Output() isLogin: EventEmitter<any> = new EventEmitter();
  @Output() isNavigate: EventEmitter<any> = new EventEmitter();
  @Output() loggedInUser: CompanyUser;
  constructor() {

  }

  change() {
    this.fire.emit("");
  }




  getEmittedValue() {
    return this.fire;
  }

  clearLogin(isLogined: any) {
    // this.isLogin = isLogined;
    this.isLogin.emit(isLogined);
  }

  getIsLogin() {
    return this.isLogin;
  }
  navigationChange() {
    return this.isNavigate;
  }

}
