import { Component, OnInit, EventEmitter, Input, Output, ElementRef, ViewChild } from '@angular/core';
import { messages } from 'src/app/constants/messages';
import Swal from 'sweetalert2';
import { MasterService } from 'src/app/services/master/master.service';
import { FeedbackCategory } from 'src/app/models/master/master';
import { AppFeedback } from 'src/app/models/appFeedback/app-feedback';
import * as commonFunctions from 'src/app/helpers/commonFunctions';

@Component({
  selector: 'app-application-feedback',
  templateUrl: './application-feedback.component.html',
  styleUrls: ['./application-feedback.component.css']
})
export class ApplicationFeedbackComponent implements OnInit {
  @Output() closeFeedback = new EventEmitter();
  constructor(private masterService: MasterService) { }
  feedbackCategories: FeedbackCategory[] = [];
  feedbackRating: number = 0;
  feedbackComment: string = "";
  feedback = new AppFeedback();
  feedbackSubmitted: boolean = false;
  commonFunction: any = commonFunctions;
  currentFile: any;
  filesToUpload: any = [];
  @Input() viewFeedback: any;
  permittedFiles: string = ".jpeg,.png,.jpg";

  @ViewChild('quillFile') quillFileRef: ElementRef;
  quillFile: any;
  meQuillRef: any;
  ngOnInit() {
    this.getFeedback();
  }

  getFeedback() {
    this.masterService.getAppFeedbackCategories().subscribe((response: any) => {
      if (response) {
        this.feedbackCategories = response;
        this.feedback.feedbackCategoryId = 0;
        this.viewFeedback = true;
      }
    }, (err) => {
      Swal.fire({ title: messages.FEEDBACK_CATEGORIES_ERROR, type: 'error', showConfirmButton: true, confirmButtonText: messages.TEXT_OK });
    });
  }

  onFeedbackRating(event) {
    this.feedbackRating = event.newValue;
  }

  insertFeedback() {
    this.feedbackSubmitted = true;
    if (this.feedback.feedbackCategoryId == 0) {
      Swal.fire({ title: messages.SELECT_CATEGORY, type: 'warning', showConfirmButton: true, confirmButtonText: messages.TEXT_OK });
    }
    else {
      if (this.feedbackRating == 0) {
        Swal.fire({ title: messages.RATE_EXPERIENCE, type: 'warning', showConfirmButton: true, confirmButtonText: messages.TEXT_OK });
      }
      else {
        if (this.feedbackRating < 3 && this.feedbackComment.trim() == '') {
          Swal.fire({ title: messages.DESCRIBE_EXPERIENCE, type: 'warning', showConfirmButton: true, confirmButtonText: messages.TEXT_OK });
        }
        else {
          this.feedback.feedbackRating = this.feedbackRating;
          this.feedback.feedback = this.feedbackComment;
          this.feedback.feedbackCategory = this.feedbackCategories.filter(x => x.categoryId == this.feedback.feedbackCategoryId)[0].category;
          let formData = new FormData();
          for (var i = 0; i < this.filesToUpload.length; i++) {
            formData.append("files", this.filesToUpload[i]);
          }
          formData.append("info", JSON.stringify(this.feedback));
          this.masterService.applicationFeedback(formData).subscribe((response: any) => {
            setTimeout(() => {
              if (response) {
                Swal.fire({ title: messages.THANKYOU_FOR_FEEDBACK, type: 'success', showConfirmButton: false, timer: 3000 });
                this.closeTab();
                this.feedbackSubmitted = false;
              }
            }, 1000);
          }, (err) => {
            Swal.fire({ title: messages.FEEDBACK_ERROR, type: 'error', showConfirmButton: true, confirmButtonText: messages.TEXT_OK });
          });
        }
      }
    }
  }

  closeTab() {
    this.viewFeedback = false;
    this.feedbackComment = "";
    this.feedbackRating = 0;
    this.feedback = new AppFeedback();
    this.closeFeedback.emit();
  }

  removeFile(index) {
    this.filesToUpload.splice(index, 1);
  }

  uploadfiles($event, files) {
    //TO Do:Minimize the Code
    var maxSizeOfFile = require("appsettings.json").sizeOfThe2MbFile;
    var extension = files[0].name.substring(files[0].name.lastIndexOf("."));
    var permittedFilesArray = this.permittedFiles.toLowerCase().split(",");
    var isValidFiles = true;
    if ($event.target.files.length > 2 || this.filesToUpload.length >= 2 || (this.filesToUpload.length == 1 && $event.target.files.length > 1)) {
      Swal.fire({ title: messages.MAX_LIMIT_REACHED, type: 'warning', showConfirmButton: true, confirmButtonText: messages.TEXT_OK });
      return false;
    }
    else if ($event.target.files.length == 2) {
      var extensionof2ndFile = files[1].name.substring(files[1].name.lastIndexOf("."));
      if ((permittedFilesArray.indexOf("*.*") === -1) && (permittedFilesArray.indexOf(extension.toLowerCase()) === -1 || permittedFilesArray.indexOf(extensionof2ndFile.toLowerCase()) === -1)) {
        Swal.fire({ title: messages.UNSUPPORTED_FILE, text: messages.APPFEEDBACK_FORMAT, type: 'warning' });
        isValidFiles = false;
      }
      else if (files[0].size > maxSizeOfFile || files[1].size > maxSizeOfFile) {
        Swal.fire({ title: messages.FILE_SIZE_EXCEEDED, type: 'warning' });
        isValidFiles = false;
      }
    }
    else {
      if ((permittedFilesArray.indexOf("*.*") === -1) && permittedFilesArray.indexOf(extension.toLowerCase()) === -1) {
        Swal.fire({ title: messages.UNSUPPORTED_FILE, text: messages.APPFEEDBACK_FORMAT, type: 'warning' });
        isValidFiles = false;
      }
      else if (files[0].size > maxSizeOfFile) {
        Swal.fire({ title: messages.FILE_SIZE_EXCEEDED, type: 'warning' });
        isValidFiles = false;
      }
    }
    if (isValidFiles) {
      for (var i = 0; i < files.length; i++) {
        this.filesToUpload.push(files[i]);
      }
    }
  }

}
