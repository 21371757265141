export const messages =
{
  //Registration
  SUCCESS_ALERT: "Registered successfully.",
  ENTER_CORRECT_ZIPCODE: "Invalid Zip Code",
  EMAIL_ACTIVATE: "Please activate your account using the link sent to your registered email.",
  EMAIL_ALERT: "We have sent an email to you. Please activate your account using the link provided in the email.",
  INVALID_VERIFICATION_CODE: "Incorrect verification code. Please try again.",
  RECRUITER_DUPLICATE_ACCOUNT_ALERT: "You already have a Recruiter account!",
  HIRING_MANAGER_DUPLICATE_ACCOUNT_ALERT: "You already have a Hiring Manager account!",
  RECRUITER_FAILED_HAS_HIRING_MANAGER_ACCOUNT_ALERT: "Sorry, you can\'t register as a Recruiter as you have Hiring Manager account.",
  RECRUITER_FAILED_HAS_COMPANY_ACCOUNT_ALERT: "Sorry, you can\'t register as a Recruiter as you are already associated with a company.",
  HM_FAILED_HAS_FL_ACCOUNT_ALERT: "Sorry, you can\'t register as a Hiring Manager as you have a Recruiter account.",
  HM_FAILED_HAS_COMPANY_ACCOUNT_ALERT: "Sorry, you can\'t register as a Hiring Manager as you are already associated with a company.",
  COMPANY_EXISTS: "The company name already exists!",
  WEBSITE_EXISTS: "The company website already exists!",
  COM_FAILED_HAS_HIRING_MANAGER_ACCOUNT_ALERT: "Sorry, you can\'t register as a company as you have Hiring Manager account.",
  COM_FAILED_HAS_ALREADY_COM_ACCOUNT_ALERT: "Sorry, you can\'t register as a company as you are already associated with another company.",
  FETCHING_OF_TIMEZONES_FAILED: "Fetching of timezones failed.",
  ACTIVATION_OF_COMPANY_ACCOUNT_FAILED: "Activation of the company account failed.",
  ACTIVATION_OF_USER_ACCOUNT_FAILED: "Activation of user account failed.",
  FETCHING_OF_LOGGED_IN_USER_FAILED: "Fetching of logged in user failed.",
  LOCATION_NOT_AVAILABLE: "Location data not available",
  USER_ALREADY_EXISTS_WITH_BUSINESSOWNER_ROLE: "User already exists with same role.",
  UNABLE_TO_UPDATE_USER_ERROR: "Unable to update.",
  USER_HAVE_TEAM_HIERARCHY_DEPEDENCY: "User have dependency in Team Management, please resolve and try again.",
  //User Management

  //Company User
  MIGRATION_ALERT: "Migration under progress.",
  MIGRATIONFAILED_ALERT: "Failed to migrate.",
  OPTION_UNAVIALBLE: "Sorry, this option is currently not available.",
  REGISTRATION_ALERT: "You are not registered, please sign up to continue.",
  USER_IS_BLOCKED_ALERT: "Oops, looks like some issues with your account.",
  ENTER_CORRECT_FORMAT: "Invalid Company Website.",
  COMPANY_USER_UPDATE_SUCESS: 'User updated.',
  COMPANY_USER_UPDATE_FAILED: 'The user update failed.',
  COMPANY_USER_EMAILID_ALREADY_EXISTS: 'Company user email id already exists.',
  COMPANY_USER_REMOVING_ROLES_HAS_DEPENDECIES_TITLE: 'Removing Role(s) has dependencies.',
  COMPANY_USER_REMOVING_ROLES_HAS_DEPENDECIES_TEXT: 'Please remove the dependencies and try again.',
  USER_HAS_DEPENDENT_JOBS: 'The user you are deleting has dependent job(s).',
  CLEAR_DEPENDENT_USER_JOBS: 'Please clear the dependency.',
  MULTIPLE_COMAPNY_TEXT: 'It cannot be a part of multiple companies!',
  MULTIPLE_COMAPNY_WARNING_TEXT:
    'If you click on continue, the account access to the previous company will be suspended. Click cancel, to redirect to the login page.',
  FREELANCER_AND_COMPANY_TEXT:
    'If you click on continue, your personal account will be read-only. Click cancel, to redirect to the login page.',
  FREELANCER_AND_COMPANY: 'Recruiter account exists!',

  //Profit rate
  WANT_TO_DELETE_CALCULATOR: 'Once deleted, this calculator will be removed!',
  DELETE_CALCULATOR: 'Calculator deleted.',
  CALCULATOR_UPDATED: 'Calculator updated.',
  CALCULATOR_VARIABLE_EXIST: 'Calculator variable already exists.',
  ENTER_CALCULATOR_NAME: 'Please enter calculator name.',
  ENTER_ONE_VARIABLE: 'Please enter at least one  variable.',
  CALCULATOR_ADDED: 'Calculator added.',
  ENTER_VALID_VARIABLE_VALUE: 'Enter valid variable value.',
  CALCULATOR_ALREADY_EXISTS: 'Calculator name already exists.',
  VARIABLE_ALREADY_EXISTS: 'Calculator variable already exists.',
  ENTER_VARIABLE_NAME: 'Please enter Variable Name.',
  DATA_EXISTING_VARIABLE: 'Please enter data for the existing variable(s).',
  CALCULATOR_ATLEAST_VARIABLE_EXIST: 'Please ensure to have at least one variable.',

  //Contact Management
  CONTACT_PERSON_EMAILID_EXISTS: 'The existing contact person already has this email.',
  ENTER_VALID_DATA: 'Please enter valid data.',
  CLIENT_ALREADY_EXIST: 'Client already exists.',
  INACTIVE_CLIENT_ALREADY_EXISTS: 'An inactive client exists with this client name.',
  CLIENT_UPDATE_SUCESS: 'Client updated.',
  CONTACT_PERSON_EXIST: 'Contact person already exists with same email id. Please refresh your page.',
  CONTACT_PERSON_HAS_DEPENDENT_JOBS: 'The contact(s) you are deleting has dependent job(s).',
  CLEAR_DEPENDENT_JOBS: 'Please clear the dependency on highlighted contact(s).',
  CLIENT_ADDED_SUCESS: 'Client added.',
  ENTER_ALL_DETAILS: 'Please enter all mandatory fields.',
  ENTER_CLIENT_NAME: 'Please enter client.',
  DUPLICATE_NAME: 'The Contact Name already exists.',
  DO_YOU_WANT_TO_CONTINUE: 'Do you want to continue?',
  ARE_YOU_SURE: 'Are you sure?',
  WANT_TO_DELETE: 'Once deleted, this contact will be removed!',
  CONTACT_PERSON_DELETE: 'Contact person deleted.',
  DEACTIVATE_CLIENT: 'Once deactivated, you cannot work further!',
  ACTIVATE_CLIENT: 'Do you want to activate this client?',
  DO_YOU_WANT_TO: 'Do you want to ',
  CONNECTION_REQUEST_ERROR_MESSAGE: "Sorry, request failed as the connection is already initiated from the other end.",
  INGORE_USER_MESSSAGE: "Once ignored, this request will be declined!",
  DISCONNECT_USER_MESSAGE: "Once disconnected, all shared job(s)/candidate(s) will be unshared!",
  DUPLICATE_EMAIL_EXISTS: "Duplicate email exists.",
  CONNECTION_REQUEST_CANCELLED_ERROR_MESSAGE: "Sorry, cancel action failed as recipient performed some action.",
  CONNECTION_ACCEPT_OR_IGNORE_ERROR_MESSAGE: "Sorry, the request is no more active.",
  CONNECTION_ALREADY_ACCEPTED: "Sorry, the request is already accepted",
  CONNECTION_ALREADY_IGNORED: "Sorry, the request is already ignored",
  CONNECTION_DISCONNECT_ERROR_MESSAGE: "Sorry, Disconnect action failed as the connection is no more active.",
  PLEASE_TRY_AGAIN_BY_PAGE_REFRESH_MESSAGE: "Please refresh and try again.",

  //Candidate
  POTENTIAL_CANDIDATE_DELETE: "This candidate will be deleted permanently.",
  CANDIDATE_DELETED:"Candidate deleted",
  DO_YOU_WANT_TO_DELETE: 'Do you want to delete?',
  DO_YOU_WANT_TO_ARCHIVE: 'Do you want to archive?',
  ONBOARDED_TO_JOB: 'This candidate is on-boarded for a job.',
  INVALID_CONTACT_WARNING_MESSAGE: "Please enter a valid Contact Number.",
  INVALID_EMAIL_WARNING_MESSAGE: "Please enter a valid Email.",
  INVALID_DOB_WARNING_MESSAGE: "Please enter a valid Date of Birth.",
  DOB_GREATER_WARNING_MESSAGE: "Candidate should be 18 years or older.",
  FUTURE_VISADATE_WARNING_MESSAGE: "Visa Expiration Date should be greater than today's date.",
  INVALID_VISADATE_WARNING_MESSAGE: "Please enter a valid Visa Expiry Date.",
  INVALID_EMPLOYER_EMAIL_WARNING_MESSAGE: "Please enter a valid Employer Email.",
  ALREADY_EXISTS_WORK_AUTHORIZATION: "The entered Work Authorization already exists.",
  ENTER_NEW_WORK_AUTHORIZATION: "Please enter a new Work Authorization.",
  INVALID_SHAREABLE_CANDIDATE_CONTACT_WARNING_MESSAGE: "Please enter a valid shareable candidate contact number.",
  INVALID_SHAREABLE_CANDIDATE_EMAIL_WARNING_MESSAGE: "Please enter a valid shareable candidate email.",
  INVALID_SHAREABLE_CANDIDATE_WARNING_MESSAGE: "Shareable details are mandatory for candidate share.",
  INVALID_EXPERIENCE_WARNING_MESSAGE: "Please enter valid experience.",
  INVALID_CONTACTPERSON: 'Please enter the Contact Person.',
  INVALID_EMPLOYER: 'Please enter the Current Employer.',
  CANDIDATE_INSERTED: 'Candidate added.',
  CANDIDATE_DUPLICATE_NAME_WARNING_MESSAGE:
    "Candidate with the same name already exists in Organization Candidate Pool. Do you want to continue?",
  ISPERSONALACCOUNT_CANDIDATE_DUPLICATE_NAME_WARNING_MESSAGE:
    "Candidate with the same name already exists in My Candidate Pool. Do you want to continue?",
  CANDIDATE_DUPLICATE_NAME_WARNING_MESSAGE_BLACKLIST:
    "Candidate with the same name already exists in Blacklisted Candidates. Do you want to continue?",
  ERROR_MESSAGE: "Something went wrong, please try again later.",
  CANDIDATE_EXISTS_MESSAGE: "Candidate already exists in Organization Candidate Pool.",
  ISPERSONALACCOUNT_CANDIDATE_EXISTS_MESSAGE: "Candidate already exists in My Candidate Pool.",
  CANDIDATE_EXISTS_IN_BLACKLIST_MESSAGE: "Candidate already exists in Blacklisted Candidates.",
  ONBOARDED_DATE_WARNING_MESSAGE: "Contract start date should be less than contract end date.",
  ONBOARDED_DATE_UPDATE_MESSAGE: "Contract date(s) updated.",
  CANDIDATE_UPDATE_SUCCESS_MESSAGE: "Candidate updated.",
  CANDIDATE_NOTES_SUCESS_MESSAGE: "Candidate notes updated.",
  SHARE_CANDIDATE_SUCCESS_MESSAGE: "Candidate shared/unshared.",
  BLOCK_CANDIDATE: "Do you want to blacklist this candidate?",
  ALL_SHARES_WILL_UNSHARE: "This candidate will be unshared from your shared connections.",
  IMPORT_CANDIDATE_WARING_MESSAGE: "Do you want to import this candidate?",
  IMPORT_CANDIDATE_SUCESS_MESSAGE: "Candidate imported.",
  CANDIDATE_NOTES_EMPTY_MESSAGE: "Please add notes.",
  UNBLOCK_CANDIDATE: "Do you want to un-blacklist this candidate?",
  SHARE_FAILED: "Share failed,Please try again.",
  CANDIDATE_IMPORTED_SUCCESS: "Candidate imported successfully into your Organization Candidate Pool with id.",
  ISPERSONALACCOUNT_CANDIDATE_IMPORTED_SUCCESS: "Candidate imported successfully into My Candidate Pool with id.",
  CANDIDATE_IMPORTED: "Candidate already  exists in your Organization Candidate Pool with id ",
  ISPERSONALACCOUNT_CANDIDATE_IMPORTED: "Candidate already  exists in My Candidate Pool with id ",
  UNMARK_AS_FAVOURITE: "Unmarked as favorite.",
  MARK_AS_FAVOURITE: "Marked as favorite.",
  LEAVE_CONFIRMATION_MESSAGE: "You have unsaved changes here. Do you want to leave?",
  UNSAVED_CHANGES_WARNING_MESSAGE:
    "You have not saved the changes made to this candidate. Do you want to close without saving?",
  UNSAVED_CHANGES_TITLE: 'You have modified this form.',
  UNSAVED_CHANGES_TEXT: 'Close and lose changes?',
  EXCEL_WARNING_MESSAGE: "Please ensure that the excel file is in correct format.",
  FILE_UPLOADED: "File uploaded.",
  FILE_DELETED: "File deleted.",
  FILE_UPLOADED_SIZE_EXCEEDED: "File size should be less than 5Mb.",
  SELECT_USER_VALIDATION: "Select atleast one Company/User.",
  SELECT_USER_VALIDATION_FOR_FREELANCER: "Select atleast one User.",
  CANDIDATE_ALREADY_SHARED: "This candidate has already shared",
  CANDIDATE_ALREADY_UNSHARED: "This candidate has already unshared",
  INVALID_EIN_WARNING_MESSAGE: "Please enter a valid EIN.",
  ENTER_VALID_RATE: "Please enter valid Compensation.",
  CANDIDATE_UN_BLACKLISTED: "Candidate un-blacklisted.",
  CANDIDATE_BLACKLISTED: "Candidate blacklisted.",
  CANDIDATE_FILE_UPLOAD_ERROR: "Unable to upload {filename} file(s). Please try again from details page.",
  FILE_UPLOAD_ERROR: "Unable to upload {filename} file(s). Please try again from details page.",
  FILE_UPLOAD_ERROR1: "Unable to upload file. Please try again.",
  CANDIDATE_INSERT_ERROR: "Unable to add candidate record. Please try again.",
  BULK_UPLOAD_FAILED: "Bulk upload failed",
  CANDIDATES_GRID_DATA_FAILED: "Fetching of data failed.",
  BULK_UPLOAD_ATTACHMENTS_FAILED: "Bulk upload of attachments failed.",
  THIS_IS_IRREVERSABLE_PROCESS: "Bulk upload process is irreversible.",
  CANDIDATE_ALREADY_ARCHIVED_WARNING_MESSAGE: "This candidate is already archived",
  CANDIDATE_ALREADY_BLACKLISTED_WARNING_MESSAGE: "This candidate is already blacklisted",
  CANDIDATE_ALREADY_UNBLACKLISTED_WARNING_MESSAGE: "This candidate is already unblacklisted",
  CANDIDATE_NOT_AVAILABLE: "This candidate is no more available.",
  //Job
  INSERT_JOB: "Job inserted",
  ARCHIVE_JOB_WARNING: "This job will be unshared from your shared connections.",
  ACCEPTED_AT_COMPANY_SHARE: "Candidate(s) already accepted through company share.",
  ACCEPTED_AT_USER_SHARE: "Candidate(s) already accepted through user share.",
  CANDIDATES_ATTACH_ALREADY: "Candidate(s) already attached.",
  NETWORK_CANDIDATES_UNSHARED: "Selected network candidate(s) unshared.",
  CLONE_JOB_CONFIRMATION_MESSAGE: "Job will be cloned!",
  JOB_CLONED_MESSAGE: "Job cloned.",
  INVALID_RATE_WARNING_MESSAGE: "Please enter a valid Rate.",
  SHARE_JOB_RATE_VALIDATION: "Select atleast one Company/User.",
  JOB_ALREADY_SHARED: "This job has already shared.",
  JOB_ALREADY_UNSHARED: "This job has already unshared.",
  ACCEPT_JOB_SUCCESS: "Job accepted.",
  ACCEPT_JOB_UNSHARED_JOB: "This job was unshared.",
  ACCEPT_JOB_ALREADY_ACCEPTED: "This Job has already been accepted by one of the users in your organization.",
  ACCEPT_JOB_ALREADY_ACCEPTED_WITH_COMPANY:
    "This job is already accepted through company share, Please find it in Organization Jobs.",
  ACCEPT_JOB_ALREADY_ACCEPTED_WITH_USER: "This job is already accepted through user share, Please find it in Organization Jobs.",
  RATE_UPDATED_SUCCESSFULLY: "Rate/Notes updated successfully.",
  UNSAVED_JOB_CHANGES_WARNING_MESSAGE:
    "You have not saved the changes made to this job. Do you want to close without saving?",
  ANNUAL_RATE_PLACEHOLDER_TEXT: "Annual Salary ($)",
  HOURLY_RATE_PLACEHOLDER_TEXT: "Hourly Rate ($)",
  HOURLY_MAX_RATE_VLUE_MESSAGE: "Range ($) : 1 - 500.",
  ANNUAL_MAX_RATE_VALUE_MESSSAGE: "Range ($) : 1 - 250k.",
  JOB_ARCHIVE_SUCCESS_MESSAGE: "Job archived successfully.",
  JOB_ARCHIVE_WARNING_MESSAGE: "This job has onboarded candidate(s). You can archive this job by fulfilling it.",
  INSERT_JOB_SUCCESS_MESSAGE: "Job added.",
  REQUEST_ID_ALREADY_EXISTS_MESSAGE: "Request Id already exists.",
  ACCOUNT_MANAGER_NOT_ACTIVE: "Selected Account Manager no more available in the company.",
  ACCOUNT_MANAGER_ROLE_CHANGED: "Selected Account Manager role got changed, try another.",
  PRIMARY_RECRUITER_NOT_ACTIVE: "Selected Primary Recruiter no more available in the company.",
  PRIMARY_RECRUITER_ROLE_CHANGED: "Selected Primary Recruiter role got changed, try another.",
  SHARE_JOB_SUCCESS_MESSAGE: "Job shared/unshared.",
  UPDATE_JOB_SUCCESS_MESSAGE: "Job updated.",
  EMAILID_REGEX:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  REDUCE_SEMICOLON_REGEX: /;+(?=;)/g,
  DOB_REGEX:
    /(?=\d)^(?:(?!(?:10\D(?:0?[5-9]|1[0-4])\D(?:1582))|(?:0?9\D(?:0?[3-9]|1[0-3])\D(?:1752)))((?:0?[13578]|1[02])|(?:0?[469]|11)(?!\/31)(?!-31)(?!\.31)|(?:0?2(?=.?(?:(?:29.(?!000[04]|(?:(?:1[^0-6]|[2468][^048]|[3579][^26])00))(?:(?:(?:\d\d)(?:[02468][048]|[13579][26])(?!\x20BC))|(?:00(?:42|3[0369]|2[147]|1[258]|09)\x20BC))))))|(?:0?2(?=.(?:(?:\d\D)|(?:[01]\d)|(?:2[0-8])))))([-.\/])(0?[1-9]|[12]\d|3[01])\2(?!0000)((?=(?:00(?:4[0-5]|[0-3]?\d)\x20BC)|(?:\d{4}(?!\x20BC)))\d{4}(?:\x20BC)?)(?:$|(?=\x20\d)\x20))?((?:(?:0?[1-9]|1[012])(?::[0-5]\d){0,2}(?:\x20[aApP][mM]))|(?:[01]\d|2[0-3])(?::[0-5]\d){1,2})?$/,
  ATTACH_CANDIDATES_WARNING_SELECT_ATLEAST_ONE_CANDIDATE: "Please select atleast one candidate.",
  ATTACH_CANDIDATES_SUCCESS: "Candidate(s) attached.",
  DETACH_ASSIGNED_CANDIDATE_SUCCESS_MESSAGE: "Candidate detached.",
  DETACH_CANDIDATE_WARNING_MESSAGE: "Do you want to detach this candidate?",
  CANDIDATES_BY_MULTIPLE_SHARES: "Multiple shares of a same candidate are selected which can't be accepted.",
  JOB_INSERT_ERROR: "Unable to add job record. Please try again.",
  LOCATION_FETCH_FAILED: "Fetching of location failed.",
  JOB_MASTER_DATA_FAILED: "Unable to fetch data, please try again.",
  FETCHING_OF_CLIENTS_FAILED: "Fetchiing of clients failed.",
  DELETING_OF_ATTACHMENTS_FAILED: "Deleting of attachment failed.",
  RETRIEVING_OF_ARCHIVED_JOBS_FAILED: "Retrieving of archived jobs failed.",
  ROUTER_NAVIGATION_FAILED: "Error occurred, please try again.",
  RETRIEVING_OF_JOB_DETAILS_FAILED: "Retrieving of job details failed.",
  RETRIEVING_OF_NETWORK_JOB_DETAILS_FAILED: "Retrieving of network job details failed.",
  FETCHING_OF_LOCATION_FAILED: "Fetching of location failed.",
  UPDATING_JOB_STATUS_FAILED: "Updating job status failed.",
  UPDATING_JOB_STATUS_FAILED_ConcurrentAction: "Updating job status failed as the the current status is not the accurate one. Please try again later!",
  NETWORK_JOB_UPDATE_FAILED: "Network job update failed.",
  JOB_UPDATE_FAILED: "Job update failed.",
  RETRIEVING_OF_CONNECTIONS_FAILED: "Retrieving of connections failed",
  CLONE_JOB_FAILED: "Clone job failed.",
  JOB_SHARE_OR_UNSHARE_FAILED: "Job share or unshare failed.",
  CONTACT_PERSON_EMAILID_DUPLICATE: "The contact person mailId already exists.",



  FAVORITE_OR_UNFAVORITE_JOB_FAILED: "Favorite or unfavorite job failed.",
  ACCEPTING_NETWORK_JOB_FAILED: "Accepting network job failed.",
  RETRIEVING_OF_NETWORK_JOBS_FAILED: "Retrieving of network jobs failed.",
  RETRIEVING_OF_JOBS_FAILED: "Retrieving of jobs failed.",
  ARCHIVING_OF_JOB_FAILED: "Archiving of job failed.",
  RETRIEVING_OF_UNASSIGNED_CANDIDATES_FAILED: "Retrieving of unassigned candidates failed.",
  ATTACHING_CANDIDATES_FAILED: "Attaching candidate(s) failed.",
  RETRIEVING_OF_SKILLS_FAILED: "Retrieving of skills failed.",
  VALID_JOB_START_DATE: "Please enter valid Job Start Date",
  JOB_DESCRIPTION_VALIDATION: "Job Description have exceeded the maximum character limit",
  JOB_ALREADY_ARCHIVED_WARNING_MESSAGE: "This job is already archived.",
  JOB_NOT_AVAILABLE: "This job is no more available.",
  //AssignedCandidate
  ASSIGNED_CANDIDATE_IS_NOT_ACTIVE: "The Assigned Candidate is not active (Detached).",
  CLOSE_ATTACHMENT_PREVIEW: "Please close the present attachment preview.",
  DETACH_CANDIDATE_FAILED: "Detach candidate failed.",
  ASSIGNED_CANDIDATE_UPDATE_FAILED: "Assigned candidate update failed.",
  REVERT_OF_ASSIGNED_CANDIDATE_FAILED: "Revert of assigned candidate failed.",
  UPDATING_OF_ASSIGNED_CANDIDATE_STATUS_FAILED: "Updating of assigned candidate status failed.",
  ATTACH_OF_ASSIGNED_CANDIDATE_FAILED_AS_JOB_INACTIVE: "Attaching of assigned candidate failed, as the job is inactive.",
  UPDATE_OF_ASSIGNED_CANDIDATE_FAILED_AS_JOB_INACTIVE: "Updating status of assigned candidate failed, as the job is inactive.",
  SCHEDULE_INTERVIEW_OF_ASSIGNED_CANDIDATE_FAILED_AS_JOB_INACTIVE: "Interview schedule of assigned candidate failed, as the job is inactive.",
  REVERT_OF_ASSIGNED_CANDIDATE_FAILED_AS_JOB_INACTIVE: "Reverting status of assigned candidate failed, as the job is inactive.",
  UPDATING_OF_ASSIGNED_CANDIDATE_CLIENT_SUBMISSION_DETAILS_FAILED_AS_JOB_INACTIVE: "Reverting status of assigned candidate failed, as the job is inactive.",


  UPDATING_OF_ASSIGNED_CANDIDATE_STATUS_FAILED_AS_DETACHED: "Updating of assigned candidate status failed, as the assigned candidate is detached.",
  UPDATING_OF_ASSIGNED_CANDIDATE_STATUS_FAILED_AS_DISABLED: "Updating of assigned candidate status failed, as the assigned candidate is disabled.",
  UPDATING_OF_ASSIGNED_CANDIDATE_STATUS_FAILED_AS_STATUS_NOT_ACCURATE: "Updating of assigned candidate status failed, as the assigned candidate status is not accurate.",
  UPDATING_OF_NETWORK_SUBMITTED_CANDIDATE_STATUS_FAILED_AS_DETACHED: "Updating of network submitted candidate status failed, as the network submitted candidate is detached.",
  UPDATING_OF_NETWORK_SUBMITTED_CANDIDATE_STATUS_FAILED_AS_DISABLED: "Updating of network submitted candidate status failed, as the network submitted candidate is disabled.",
  UPDATING_OF_NETWORK_SUBMITTED_CANDIDATE_STATUS_FAILED_AS_STATUS_NOT_ACCURATE: "Updating of network submitted candidate status failed, as the network submitted candidate  status is not accurate.",

  SCHEDULE_INTERVIEW_OF_ASSIGNED_CANDIDATE_STATUS_FAILED_AS_DETACHED: "Interview schedule of assigned candidate failed, as the assigned candidate is detached.",
  SCHEDULE_INTERVIEW_OF_ASSIGNED_CANDIDATE_STATUS_FAILED_AS_DISABLED: "Interview schedule of assigned candidate failed, as the assigned candidate is disabled.",
  SCHEDULE_INTERVIEW_OF_ASSIGNED_CANDIDATE_STATUS_FAILED_AS_STATUS_NOT_ACCURATE: "Interview schedule of assigned candidate failed, as the assigned candidate status is not accurate.",
  SCHEDULE_INTERVIEW_OF_NETWORK_SUBMITTED_CANDIDATE_STATUS_FAILED_AS_DETACHED: "Interview schedule of network submitted candidate failed, as the network submitted candidate is detached.",
  SCHEDULE_INTERVIEW_OF_NETWORK_SUBMITTED_CANDIDATE_STATUS_FAILED_AS_DISABLED: "Interview schedule of network submitted candidate failed, as the network submitted candidate is disabled.",
  SCHEDULE_INTERVIEW_OF_NETWORK_SUBMITTED_CANDIDATE_STATUS_FAILED_AS_STATUS_NOT_ACCURATE: "Interview schedule of network submitted candidate failed, as the network submitted candidate  status is not accurate.",

  REVERT_OF_ASSIGNED_CANDIDATE_STATUS_FAILED_AS_DETACHED: "Revert of assigned candidate status failed, as the assigned candidate is detached.",
  REVERT_OF_ASSIGNED_CANDIDATE_STATUS_FAILED_AS_DISABLED: "Revert of assigned candidate status failed, as the assigned candidate is disabled.",
  REVERT_OF_ASSIGNED_CANDIDATE_STATUS_FAILED_AS_STATUS_NOT_ACCURATE: "Revert of assigned candidate status failed, as the assigned candidate status is not accurate.",
  REVERT_OF_NETWORK_SUBMITTED_CANDIDATE_STATUS_FAILED_AS_DETACHED: "Revert of network submitted candidate status failed, as the network submitted candidate is detached.",
  REVERT_OF_NETWORK_SUBMITTED_CANDIDATE_STATUS_FAILED_AS_DISABLED: "Revert of network submitted candidate status failed, as the network submitted candidate is disabled.",
  REVERT_OF_NETWORK_SUBMITTED_CANDIDATE_STATUS_FAILED_AS_STATUS_NOT_ACCURATE: "Revert of network submitted candidate status failed, as the network submitted candidate  status is not accurate.",
  DETACH_OF_ASSIGNED_CANDIDATE_FAILED_AS_JOB_INACTIVE: "Detach of assigned candidate failed, as the job is inactive.",
  DETACH_OF_ASSIGNED_CANDIDATE_STATUS_FAILED_AS_ALREADY_DETACHED: "Detach of assigned candidate failed, as the assigned candidate is already detached.",
  DETACH_OF_ASSIGNED_CANDIDATE_STATUS_FAILED_AS_STATUS_NOT_ACCURATE: "Detach of assigned candidate failed, as the assigned candidate status is not accurate.",
  UPDATING_OF_ASSIGNED_CANDIDATE_CLIENT_SUBMISSION_DETAILS_FAILED_AS_DETACHED: "Updating of assigned candidate Client Submission details failed, as the assigned candidate is detached.",
  UPDATING_OF_ASSIGNED_CANDIDATE_CLIENT_SUBMISSION_DETAILS_FAILED_AS_DISABLED: "Updating of assigned candidate Client Submission details failed, as the assigned candidate is disabled.",
  UPDATING_OF_ASSIGNED_CANDIDATE_CLIENT_SUBMISSION_DETAILS_FAILED_AS_STATUS_NOT_ACCURATE: "Updating of assigned candidate Client Submission details failed, as the assigned candidate status is not accurate.",
  UPDATING_OF_NETWORK_SUBMITTED_CANDIDATE_CLIENT_SUBMISSION_DETAILS_FAILED_AS_DETACHED: "Updating of network submitted candidate Client Submission details failed, as the network submitted candidate is detached.",
  UPDATING_OF_NETWORK_SUBMITTED_CANDIDATE_CLIENT_SUBMISSION_DETAILS_FAILED_AS_DISABLED: "Updating of network submitted candidate Client Submission details  failed, as the network submitted candidate is disabled.",
  UPDATING_OF_NETWORK_SUBMITTED_CANDIDATE_CLIENT_SUBMISSION_DETAILS_FAILED_AS_STATUS_NOT_ACCURATE: "Updating of network submitted candidate Client Submission details failed, as the network submitted candidate  status is not accurate.",

  ASSIGNED_CANDIDATE_FEEDBACK_SUCCESS: "Added feedback Successfully.",
  ASSIGNED_CANDIDATE_FEEDBACK_FAILED_AS_JOB_INACTIVE: "Assigned Candidate feedback failed as job is inactive.",
  ASSIGNED_CANDIDATE_FEEDBACK_FAILED_AS_DETACHED: "Assigned Candidate feedback failed, as the assigned candidate is detached.",
  ASSIGNED_CANDIDATE_FEEDBACK_FAILED_AS_STATUS_NOT_ACCURATE: "Assigned Candidate feedback failed, as the assigned candidate status is not accurate.",
  ASSIGNED_CANDIDATE_FEEDBACK_FAILED_AS_DISABLED: "Assigned Candidate feedback failed, as the assigned candidate is disabled.",
  NETWORK_SUBMITTED_CANDIDATE_FEEDBACK_FAILED_AS_JOB_INACTIVE: "Assigned network submitted candidate feedback failed as job is inactive.",
  NETWORK_SUBMITTED_CANDIDATE_FEEDBACK_FAILED_AS_DETACHED: "Assigned network submitted candidate feedback failed, as the network submitted candidate is detached.",
  NETWORK_SUBMITTED_CANDIDATE_FEEDBACK_FAILED_AS_STATUS_NOT_ACCURATE: "Assigned network submitted candidate feedback failed, as the network submitted candidate status is not accurate.",
  NETWORK_SUBMITTED_CANDIDATE_FEEDBACK_FAILED_AS_DISABLED: "Assigned network submitted candidate feedback failed, as the network submitted candidate is disabled.",

  PLEASE_REFRESH_THE_PAGE_MESSAGE: "Please refresh the page.",
  ASSIGNED_CANDIDATE_HELP: "For more information, please go to Profile & Settings -> Help -> Organization jobs -> Assigning candidates for a job",
  ASSIGNED_CANDIDATE_FOR_NETJOB_HELP: "For more information, please go to Profile & Settings -> Help -> Network jobs -> Assigning candidates for a network job",

  //Reports
  START_DATE_GREATER_THAN_END_DATE_WARNING: "The starting date cannot be greater than the ending date.",
  DATE_WARNING: "Warning date",
  ENTER_VALID_START_DATE_AND_END_DATES: "Please select valid start & end dates.",
  DEFAULT_DEPLOY_DATE: "01-03-2019",

  ADD_FEEDBACK_VALIDATION_MESSAGE: "Enter feedback.",
  SKILL_ALREADY_RATED: "Skill already rated.",
  SCHEDULE_INTERVIEW_SUCCESS_MESSAGE: "Interview scheduled.",
  SKILL_DELETE_SUCCESS_MESSAGE: "Skill deleted.",
  INTERVIEW_ALREADY_SCHEDULE_WARNING_MESSAGE: "An interview is already scheduled for this time.",
  INTERVIEW_INVALID_DATE: "Interview date and time should not be older than current date and time.",

  //Home
  DO_YOU_WANT_TO_DELETE_POST: 'This post will be permanently removed from wall.',
  POSTED_SUCCESSSFULLY: 'Posted Successfully.',
  UPDATED_SUCCESSSFULLY: 'Post updated.',
  ERROR: "Error please try again.",
  UPDATE_ASSIGNED_CANDIDATE_STATUS_RESTRICT_MESSAGE: "Cannot change to ",
  DELETE_MESSAGE: "Do you want to delete this comment?",
  CLEAR_ALL_NOTIFICATIONS_WARNING_MESSAGE: "Do you want to clear all notification(s)?",
  CLEAR_ALL_DISCUSSIONS_WARNING_MESSAGE: "Do you want to clear all discussion(s)?",
  READ_ALL_MESSSAGES_WARNING_MESSAGE: "Do you want to mark all the notification(s) as read?",
  READ_ALL_DISCUSSIONS_WARNING_MESSAGE: "Do you want to mark all the discussion(s) as read?",
  CLEAR_NOTIFICATION_WARNING_MESSAGE: "Do you want to clear the notification?",
  CLEAR_DISCUSSIONS_WARNING_MESSAGE: "Do you want to clear the discussion?",
  FULLFILL_WARNING_MESSAGE: "You want to fulfill/archive this job!",
  SOMETHING_WENT_WRONG: "Something went wrong.",
  CHARACTER_LIMIT: "Characters shouldn't be more than 100.",
  EMPLOYER_CHARACTER_LIMIT: "Characters shouldn't be more than 60.",
  SELECT_CATEGORY: "Please select Category.",
  RATE_EXPERIENCE: "Please rate your experience.",
  DESCRIBE_EXPERIENCE: "Please describe your experience.",
  THANKYOU_FOR_FEEDBACK: "Thank you for your feedback.",
  FETCHING_OF_FAVORITE_JOBS_FAILED: "Fetching of favorite jobs failed.",
  FETCHING_OF_FAVORITE_CANDIDATES_FAILED: "Fetching of favorite candidates failed.",
  FETCHING_SHARED_JOBS_FAILED: "Fetching of shared jobs failed.",
  FETCHING_SHARED_CANDIDATES_FAILED: "Fetching of shared candidates failed.",
  POST_FAILED: "Post failed.",
  FETCHING_OF_POSTS_FAILED: "Fetching of new posts failed.",
  FETCHING_OF_NEW_POSTS_FAILED: "Fetching of posts failed.",
  POST_DELETE_FAILED: "Post delete failed.",
  GLOBALSEARCH_RESULTS_ERROR: "Failed to fetch global search results.",
  ENTER_VALID_BOOLEAN_STRING: "Please enter a valid boolean string.",
  FEEDBACK_ERROR: "Application feedback failed.",
  MAX_LIMIT_REACHED: "Oops, only two attachments are allowed at present.",
  FEEDBACK_CATEGORIES_ERROR: "Failed to fetch feedback categories.",
  LOGGEDIN_USER_BYID__ERROR: "Failed to logIn.",
  LOCATION_ERROR: "Failed to fetch location.",
  PROFILE_UPDATE_ERROR: "Failed to update profile.",
  PROFILE_DETAILS_ERROR: "Failed to fetch profile details.",
  NOTIFICATION_ERROR: "Notification settings failed.",
  APPFEEDBACK_FORMAT: "Please select a file in PNG, JPG or JPEG  format.",
  //Comments
  DO_YOU_WANT_TO_DELETE_COMMENT: "Are you sure you want to delete your comment?",
  DELETEING_THE_COMMENT_WILL_DELETE_REPLIES: "All replies on this comment will also be removed.",
  DO_YOU_WANT_TO_DELETE_REPLY: "Are you sure you want to delete your reply?",
  POST_HAS_BEEN_DELETED: "Post has been deleted.", //Post cannot be found.
  COMMENT_HAS_BEEN_DELETED: "Comment has been deleted.",//Comment cannot be found.
  REPLY_HAS_BEEN_DELETED: "Reply has been deleted.",//Comment cannot be found.

  //Management
  FREELANCER_ACCOUNT_ACCOUNT_EXITS: "Your Personal account will be read only on activating this company's account.",
  FREELANCER_ACCOUNT_REGISTRATION_ACCOUNT_EXITS: "Your Personal account will be read only on registering and activation of a company.",
  CANNOT_REMOVE_ADMIN_ACCESS: "You cannot remove your admin role.",
  USER_UPDATE_FAILED: "User update failed.",
  INVITE_ALREADY_SENT: "An invite is already sent to this Company User Email.",
  ALREADY_COMPANYUSER: "A user already exists with this company's user email.",
  USER_INVITED: "User invited.",
  WANT_TO_DELETE_USER: "Once deleted, this user will be removed permanently!. The job(s)/candidate(s) shared by this user in user level will be unshared.",
  USER_DELETED: "User Deleted.",
  UNABLE_TO_DELETE_USER_ERROR: "Unable to delete.",
  USER_INVITE_DELETED: "Company user invite deleted.",
  DELETE_INVITE: "You want to delete this invite!",
  INVITE_RESENT: "Company user invite resent.",
  RESEND_INVITATION: "You want to resend the invitation.",
  DELETE_ATTACHMENT_WARNING_MESSAGE: "Do you want to delete this attachment?",
  DELETE_ATTACHMENT_SUCCESS_MESSAGE: "File deleted.",
  COMPANY_USERS_ERROR: "Unable to fetch company user.",
  GETCOMPANY_USER_INVITES_ERROR: "Unable to fetch company user invitations.",
  RESEND_COMPANYINVITES_ERROR: "Failed to resend company user invitation.",
  COMPANYUSER_INVITE_DELETE_ERROR: "Failed to delete company user invitation.",
  COMPANYUSER_DELETE_ERROR: "Failed to delete.",
  COMPANYUSER_INVITE_ERROR: "Unable to invite.",
  COMPANYUSER_UPDATE_ERROR: "Failed to update.",
  COMPANYUSER_RESOLVE_DEPENDENCY_ERROR: "Unable to update the user job dependencies. Please try again later.",
  COMPANYUSER_LOAD_JOBS_ERROR: "Unable to fetch user dependent jobs. Please try again later.",
  ROLE_BASED_USERS_ERROR: "Unable to fetch role based users. Please try again later.",
  SELECT_USER: "Select user from dropdown list",
  USER_INVITE_STATUS_ERROR: "Failed to fetch invitation active status.",
  COMPANYUSER_INVITE_STATUS_ERROR: "Failed to fetch invitation active status.",
  GET_CLIENTS_ERROR: "Failed to fetch clients.",
  UPDATE_CLIENT_ERROR: "Unable to update client.",
  CLIENT_ADD_ERROR: "Unable to add client.",
  DEACTIVATE_CLIENT_ERROR: "Failed to deactivate a client.",
  ACTIVATE_CLIENT_ERROR: "Failed to activate a client.",
  CLIENT_DETAILS_ERROR: "Failed to fetch client details.",
  DOWNLOAD_FILE_ERROR: "File download failed.",
  DELETE_FILE_ERROR: "Failed to delete file.",
  PREVIEW_FILE_ERROR: "File preview failed.",
  CONNECTION_DISCUSSION_ERROR: "Failed to fetch connection(s) for discussion.",
  DISCUSSION_FAILED: "Failed to fetch discussion(s).",
  MESSAGE_FAILED: "Failed to send message.",
  MESSAGE_DELETE_FAILED: "Failed to delete message.",
  MESSAGE_UPDATE_FAILED: "Failed to update message.",

  //BulkUpload
  ATTACHMENTS_MISSING: "One or more attachments are missing, would you like to continue without attachments ?",
  ATTACH_EXCEL_SHEET: "Please attach the excel file",
  INVALID_CONTACT_NUMBER: "Contact Number is missing or incorrect at row",
  MISSING_EMPLOYER_FEILD: "Employer and Employer Contact Person missing at row",
  INVALID_EMPLOYER_EMAIL: "Employer contact Email is invalid at row",
  UPLOAD_FAILED: "Upload failed, please try again",
  ENTER_MANDATORY_FEILDS: "Please fill all mandatory fields at row",
  DUPLICATE_RECORDS: "Candidate record is duplicated at row",
  INVALID_EMAIL: "Candidate Email is invalid at row",
  MISSING_CONTACT_PERSON: "Employment Contact Person missing at row",
  MISSING_EMPLOYER: "Employer missing at row",
  INVALID_EMPLOYER_CONTACT: "Employer Contact Number is missing or incorrect at row",
  DUPLICATE_EMPLOYER_CONTACT: "Candidate Employer Email is duplicated",
  RESUME_FORMAT: "Only doc, docx and pdf file formats are allowed.",

  //network
  RETRIEVING_OF_RECIEVED_CONNECTIONS_FAILED: "Retrieving of received connections failed.",
  CONNECTION_STATUS_UPDATE_FAILED: "Connection status update failed.",
  CONNECTION_REQUEST_FAILED: "Connection request failed.",
  NETWORK_SUGGESTIONS_FOR_USER_FAILED: "Network suggestions for user failed.",
  NETWORK_SUGGESTIONS_FOR_COMPANY_FAILED: "Network suggestions for company failed.",
  CONNECTIONS_FOR_SENDING_REQUEST_FAILED: "Retrieving connections for sending request failed.",
  INSERT_CONNECTION_FAILED: "Insert connection failed.",
  CONNECTIONS_STATUS_UPDATE_FAILED: "Connection status update failed.",
  RETRIEVING_OF_SEARCH_CONNECTION_PROFILE_FAILED: "Retrieving of search connection profile failed.",

  //social-login
  LINKEDIN_LOGIN_FAILED: "LinkedIn login failed.",
  FETCHING_CANDIDATES_FAILED: "Fetching candidates failed.",

  //search-result
  SEARCH_RESULTS_FAILED: "Search results failed.",

  //File-Upload
  FILE_SIZE_EXCEEDED: "File size exceeded 2MB",
  UNSUPPORTED_FILE: "Unsupported file.",

  //Contact Us
  CONTACTUS_SUCCESS: "Submitted successfully.",
  CONTACTUS_FAILED: "Unable to send feedback, please try again.",
  RE_CAPTCHA_CHECK_MESSAGE: "Please verify that you are a Human.",

  //Settings
  UPDATE_THE_EMAIL_FROM_THE_SETTINGS_FOR_ADMIN: "Please update the Email Distribution List from the settings.",
  UPDATE_THE_EMAIL_FROM_THE_SETTINGS: "Please contact admin to update the Email Distribution List from the settings.",
  ENTER_THE_EMAILDISTRIBUTION: "Please enter Email Distribution List",
  SOME_THING_WENT_WRONG: "Something went wrong",
  CONTACT_ADMIN: "Please contact admin at ",

  //External Invites
  ALREADY_INVITED: "The invitation is activated.",
  RESENT_INVITE: "Invitation resent successfully.",
  INVITE_EXPIRED: "Activation code expired.",
  INVITE_DELETED: "Invitation deleted.",
  INVITE_ACTIVATED: "Invitation activated.",
  INVALID_INVATATION: "Invalid verification code.",
  ACTIVE_INVITATION: "The invitation is already sent.",
  ACCOUNT_ACTIVATED: "The invitation is already sent and activated.",
  EXPIRED_INVITATION: "The invitation is already sent and expired.",
  RESEND_INVITE: "Please resend invitation.",
  CONTACT_ADMIN_FOR_HELP: "Please contact support@graph5.com for any help",
  TEXT_OK: "OK",

  //Team Hierarchy
  USERS_ALL_FETCH_ERROR: "Unable to fetch all active team members, Please try again later.",
  TEAM_HIERARCHY_LOAD_ERROR: "Unable to fetch all active team members, Please try again later.",
  USER_NO_ROLE_AVAILABLE_ERROR: "No {role}(s) are available to add!",
  USER_FETCH_ERROR: "Unable to fetch {name}, Please try again later.",
  ADD_BUSINESS_OWNER_ERROR: "Please add user role (Business Owner) from user management to build Team Hierarchy.",
  SAVED_SUCCESSFULLY: "Saved successfully.",
  CLIENTS_ARE_NOT_INTENDED_TO_THE_USER: "The clients of this user doesn't exist on the parent node and these will be removed.",

  //Add checkes
  ADD_USER_TEAM_HIERARCHY_ERROR: "Unable to add user, please contact admin.",
  USER_ALREADY_SOME_HIERARCHY: "User already exists in the Team hierarchy.",
  PARENT_NOT_ACTIVE: "Parent node is no more available.",
  PARENT_USER_NOT_ACCURATE: "Parent user in the Hierarchy has been changed.",

  //edit checks
  EDIT_USER_TEAM_HIERARCHY_ERROR: "Unable to edit user, please contact admin.",
  NODE_IS_REMOVED_FROM_HIERARCHY: "The node you are trying to update is not active.",
  NODE_IS_UPDATED_FROM_HIERARCHY: "The user in the node you are trying to modify is not accurate.",

  //remove check
  REMOVE_USER_TEAM_HIERARCHY_ERROR: "Error in removing User, please contact admin.",
  NODE_ALREADY_REMOVED: "The node you are trying to remove is already removed.",


  //update position
  UPDATE_POSITION_FAILED_NODE_IS_REMOVED: "The node you are trying to update the position is already removed.",

  //Potential Candidates
  POTENTIAL_CANDIDATES_ATTACHMENTS_MISSING: "One or more attachments are missing",
  DUPLICATE_CANDIDATES_ALERT: "If you continue with duplicate(s) remaining candidate(s) will be saved, if not reinsert the candidate(s) from excel."

};
