import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CandidateService } from 'src/app/services/candidates/candidate.service';
import { Attachments } from 'src/app/models/attachments/attachments';
import Swal from 'sweetalert2';
import { messages } from 'src/app/constants/messages';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { ImageCompressService } from 'src/app/services/image-compress.service';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.css']
})

export class FileuploadComponent implements OnInit {
  percent: number;
  currentFile: any;
  config: any;
  fileResponse: any;
  fileUrl: any;
  showFrame: boolean = false;
  isResumeUpdated: boolean = false;
  file: Attachments = new Attachments();
  @Input() options: any;
  @Input() isUpdatePage: any;
  @Output() showFileFrame = new EventEmitter<object>();
  @Output() deleteAttachment = new EventEmitter<Attachments>();
  @Output() updateAttachments = new EventEmitter();
  @Output() refreshAttachment = new EventEmitter<object>();
  @Input() isDisabled: boolean = false;
  @Input() isImageCropping: boolean = false;
  blobName: string;
  maxImageSize: number;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper: boolean = false;
  base64: any;
  url: string = "";
  @Output() getAttachment = new EventEmitter<object>();
  @Output() removeAttachment = new EventEmitter<object>();
  @Output() updateAttachment = new EventEmitter<object>();
  public isResumeOpened = false;
  constructor(private candidateService: CandidateService, private fileUploadService: FileUploadService, private imageCompressService: ImageCompressService) { }


  ngOnInit() {
    const data = require("appsettings.json");
    this.maxImageSize = data.sizeOfThe2MbFile;
    this.isResumeUpdated = false;
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function (callback, type, quality) {
          var dataURL = this.toDataURL(type, quality).split(',')[1];
          setTimeout(function () {

            var binStr = atob(dataURL),
              len = binStr.length,
              arr = new Uint8Array(len);

            for (var i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }

            callback(new Blob([arr], { type: type || 'image/png' }));

          });
        }
      });
    };
  }


  updateFiles(event, files) {
    this.currentFile = files[0];
    var error = '';
    var permittedFilesArray = this.options.permittedFiles.toLowerCase().split(",");
    if (this.currentFile) {
      var extension = this.currentFile.name.substring(this.currentFile.name.lastIndexOf("."));
      if ((permittedFilesArray.indexOf("*.*") === -1) && permittedFilesArray.indexOf(extension.toLowerCase()) === -1) {
        Swal.fire({ title: messages.UNSUPPORTED_FILE, type: 'warning' });
      } else if (this.currentFile.size > this.maxImageSize) {
        Swal.fire({ title: messages.FILE_SIZE_EXCEEDED, type: 'warning' });
        error = messages.FILE_SIZE_EXCEEDED;
      } else if (error === '') {
        this.options.extension = extension;
        if (!this.isUpdatePage && !this.isImageCropping) {
          this.options.originalName = this.currentFile.name;
          this.options.blobName = '  ';
          this.getAttachment.emit({ file: this.currentFile, details: this.options });
        }
        else if (this.isUpdatePage && !this.isImageCropping) {
          this.options.originalName = this.currentFile.name;
          this.options.blobName = '  ';
          this.updateAttachment.emit({ file: this.currentFile, details: this.options });
        }
        else {
          this.cropImage(this.currentFile, event);
        }
      }
    }

  }

  

  downloadFile() {
    this.file.originalName = this.options.originalName;
    this.file.blobName = this.options.blobName;
    this.file.attachmentType = this.options.attachmentType;
    this.candidateService.getFileUrl(this.file).subscribe((data: any) => {
      window.open(data.containerWithSasUrl);
    }, (err) => {
      Swal.fire({ title: messages.DOWNLOAD_FILE_ERROR, type: 'error', showConfirmButton: true });
    });
  }


  removeFile() {
    if (this.isUpdatePage) {
      this.deleteFile();
    }
    else {
      this.options.originalName = "";
      this.options.blobName = '';
      this.options.extension = '';
      this.removeAttachment.emit({ attachmentType: this.options.attachmentType });
    }
  }

  deleteFile() {
    this.file.originalName = this.options.originalName;
    this.file.blobName = this.options.blobName;
    this.file.attachmentType = this.options.attachmentType;
    this.file.categoryId = this.options.categoryId;
    if (this.options.category == 'User' || this.options.category == 'Company' || this.options.category == 'Job') {
      Swal.fire({
        title: messages.DELETE_ATTACHMENT_WARNING_MESSAGE,
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          formData.append("AttachmentToBeDeleted", JSON.stringify(this.file));
          this.fileUploadService.deleteFile(formData).subscribe((response: any) => {
            setTimeout(() => {
              Swal.fire({ title: messages.DELETE_ATTACHMENT_SUCCESS_MESSAGE, type: 'success', timer: 3000, showConfirmButton: false });
              var elem = <HTMLInputElement>document.getElementById('fileUpload' + this.options.attachmentType);
              elem.value = '';
              this.options.blobName = this.file.blobName = '';
              this.options.originalName = this.file.originalName = '';
              this.options.extension = '';
              if (this.file.attachmentType == "UserProfilePhoto" || this.file.attachmentType == "CompanyProfilePhoto" || this.options.category == 'Job') {
                this.refreshAttachment.emit({ attachment: this.file, profilePercentage: response.item2 });
              }
              this.options.placeHolder = this.options.attachmentType === "ProfilePhoto" ? "Candidate Profile Photo" : this.options.placeHolder;
            }, 1000);
          }, (err) => {
            Swal.fire({ title: messages.DELETE_FILE_ERROR, type: 'error', showConfirmButton: true });
          });
        }
      });
    }
    else {
      this.deleteAttachment.emit(this.file);
    }
  }

  viewFile() {
    this.isResumeOpened = false;
    this.file.originalName = this.options.originalName;
    this.file.blobName = this.options.blobName;
    this.file.attachmentType = this.options.attachmentType;
    this.file.container = this.options.container;
    this.viewDocument(this.file);
  }


  viewDocument(file) {
    this.candidateService.getFileUrl(file).subscribe((data: any) => {
      var documentUrl = encodeURIComponent(data.containerWithSasUrl);
      var extension = file.originalName.substring(file.originalName.lastIndexOf(".")).toLowerCase();
      if ((file.attachmentType === 'Resume') || (file.attachmentType === "document")) {
        var contentUrl = '';
        if (extension === '.doc' || extension === '.docx') {
          contentUrl = "https://view.officeapps.live.com/op/embed.aspx?src=" + documentUrl;
        }
        else {
          contentUrl = "https://docs.google.com/gview?url=" + documentUrl + "&embedded=true";
        }
        this.showFileFrame.emit({ contentUrl: contentUrl, extension: extension, attachmentType: file.attachmentType });
      }
      if (file.attachmentType === 'Visa' || file.attachmentType === 'GovtId' || file.attachmentType === 'ProfilePhoto') {
        if (extension === '.jpg' || extension === '.jpeg' || extension === '.png') {
          this.showFileFrame.emit({ contentUrl: data.containerWithSasUrl, extension: extension, attachmentType: file.attachmentType });
        }
        if (extension === '.pdf')
          this.showFileFrame.emit({ contentUrl: "https://docs.google.com/gview?url=" + documentUrl + "&embedded=true", extension: extension, attachmentType: file.attachmentType });
      }
    }, (err) => {
      Swal.fire({ title: messages.PREVIEW_FILE_ERROR, type: 'error', showConfirmButton: true });
    });
  }
  viewResume(attachmentObject) {
    this.file.originalName = attachmentObject.originalName;
    this.file.attachmentType = attachmentObject.attachmentType;
    this.file.blobName = attachmentObject.blobName;
    this.viewDocument(this.file);
  }

  cropImage(file, event) {
    this.showCropper = true;
    this.file.originalName = file.name;
    this.file.attachmentType = this.options.attachmentType;
    this.croppedImage = "";
    this.imageChangedEvent = event;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.base64 = event.base64;
    this.croppedImage = this.base64;
  }

  async uploadCroppedImage() {
    await this.imageCompressService.getNewImage(this.base64, this.file.originalName).then((result => {
      var imageFile = result;
      const reader = new FileReader();
      reader.onload = e => this.url = reader.result.toString();
      reader.readAsDataURL(imageFile);
      if (!this.isUpdatePage && this.isImageCropping) {
        this.getAttachment.emit({ file: imageFile, details: this.options });
        this.options.originalName = this.currentFile.name;
        this.options.blobName = '  ';
        this.showCropper = false;
      }
      else if (this.isUpdatePage && this.isImageCropping && this.options.attachmentType == "ProfilePhoto") {
        this.updateAttachment.emit({ file: imageFile, details: this.options });
        this.options.originalName = this.currentFile.name;
        this.options.blobName = '  ';
        this.showCropper = false;
      }
      else {
        const formData = new FormData();
        formData.append('file', imageFile);
        formData.append('attachmentType', this.options.attachmentType);
        formData.append('categoryId', this.options.categoryId);
        formData.append('existingBlob', this.options.blobName);
        this.fileUploadService.uploadImage(formData).subscribe((data: any) => {
          if (data.item1.blobName !== "") {
            this.showCropper = false;
            this.file.blobName = data.item1.blobName;
            this.refreshAttachment.emit({ attachment: this.file, profilePercentage: data.item2 });
          }
        }, (err) => {
          Swal.fire({ title: messages.UPLOAD_FAILED, type: 'error', showConfirmButton: true });
        });
      }
    }));
  }

  imageSource(extension) {
    var source = "";
    if (extension !== '') {
      switch (extension) {
        case '.jpg':
        case '.jpeg':
          source = "../../../assets/IMG/jpeg.png";
          break;
        case '.doc':
        case '.docx':
          source = "../../../assets/IMG/doc.png";
          break;
        case '.rtf':
          source = "../../../assets/IMG/rtf.png";
          break;
        case '.png':
          source = "../../../assets/IMG/png.png";
          break;
        case '.pdf':
          source = "../../../assets/IMG/pdf.png";
          break;
      }
    }
    else {
      source = "../../../assets/IMG/blank-page.png";
    }
    return source;
  }


}
